import { number, shape, string } from 'prop-types';

export const parcelViewerUrl = 'https://stormwater.phila.gov/parcelviewer/';

export const creditsExplorerUrl = 'https://stormwater.phila.gov/explore/';

export const retrofitMapUrl = 'https://water.phila.gov/stormwater/connect';

export const vendorMapUrl = 'https://water.phila.gov/stormwater/connect/vendor';

export const garpMarketingUrl =
    'https://water.phila.gov/stormwater/connect/customer';

export const phlAerialBasemap = 'https://tiles.arcgis.com/tiles/fLeGjb7u4uXqeF9q/arcgis/rest/services/CityImagery_2023/MapServer';
export const phlBasemapAttribution = '&copy; City of Philadelphia';
export const maxAerialBasemapZoom = 22;

export const parcelPropType = shape({
    Parcel: shape({
        ParcelID: number,
        BRTAcct: string,
        Address: string,
        TenCode: string,
        Owner1: string,
        Owner2: string,
        GrossArea: number,
        ImpervArea: number,
        GrossCredit: number,
        ImpervCredit: number,
        FinalGross: number,
        FinalImperv: number,
        NPDESCredit: number,
        MeterCharge: number,
        ParcelCharge: number,
        WeightedCharge: number,
        BldgType: string,
        BldgCode: string,
        BldgDesc: string,
        BrtWebsite: string,
        Shape: string,
        SimpleShape: string,
        ParcelBuildingStatus: number,
    }),
    X: number,
    Y: number,
});

export const preferredContactTypes = {
    email: 'email',
    telephone: 'telephone',
    both: 'both',
};
