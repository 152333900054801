import isEmail from 'validator/lib/isEmail';

import csrfRequest from '../../../common/csrfRequest';

import { validationFailed } from '../../utils';

import {
    onboardingFormUrl,
    onboardingFormFields,
    updateProfileUrl,
} from './constants';

import { validate, formatFieldsToCamelCase } from './utils';

import { resetDeveloperPasswordUrl, ROUTES } from '../../constants';
import { preferredContactTypes } from '../../../common/constants';

export const SHOW_SIGN_IN = 'SHOW_SIGN_IN';
export const SHOW_SIGN_UP = 'SHOW_SIGN_UP';
export const START_SUBMITTING_ONBOARDING_FORM =
    'START_SUBMITTING_ONBOARDING_FORM';
export const FAIL_SUBMITTING_ONBOARDING_FORM =
    'FAIL_SUBMITTING_ONBOARDING_FORM';
export const COMPLETE_SUBMITTING_ONBOARDING_FORM =
    'COMPLETE_SUBMITTING_ONBOARDING_FORM';
export const UPDATE_ONBOARDING_FORM_FIELD = 'UPDATE_ONBOARDING_FORM_FIELD';
export const SET_ONBOARDING_FORM_ERRORS = 'SET_ONBOARDING_FORM_ERRORS';
export const SET_ONBOARDING_SIGN_UP_FORM_ERRORS =
    'SET_ONBOARDING_SIGN_UP_FORM_ERRORS';
export const TOGGLE_ONBOARDING_FORM_FIELD = 'TOGGLE_ONBOARDING_FORM_FIELD';
export const TOGGLE_SHOW_PASSWORD = 'TOGGLE_SHOW_PASSWORD';
export const TOGGLE_SHOW_CONFIRM_PASSWORD = 'TOGGLE_SHOW_CONFIRM_PASSWORD';
export const SHOW_TERMS_OF_USE_MODAL = 'SHOW_TERMS_OF_USE_MODAL';
export const HIDE_TERMS_OF_USE_MODAL = 'HIDE_TERMS_OF_USE_MODAL';
export const UPDATE_DEVELOPER_AUTH_EMAIL = 'UPDATE_DEVELOPER_AUTH_EMAIL';
export const UPDATE_DEVELOPER_AUTH_PASSWORD = 'UPDATE_DEVELOPER_AUTH_PASSWORD';
export const START_SEND_RESET_PASSWORD_EMAIL =
    'START_SEND_RESET_PASSWORD_EMAIL';
export const FAIL_SEND_RESET_PASSWORD_EMAIL = 'FAIL_SEND_RESET_PASSWORD_EMAIL';
export const COMPLETE_SEND_RESET_PASSWORD_EMAIL =
    'COMPLETE_SEND_RESET_PASSWORD_EMAIL';
export const SHOW_FORGOT_PASSWORD = 'SHOW_FORGOT_PASSWORD';
export const UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS =
    'UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS';
export const DISPLAY_FORGOT_PASSWORD_EMAIL_VALIDATION_ERROR =
    'DISPLAY_FORGOT_PASSWORD_EMAIL_VALIDATION_ERROR';
export const SET_TOS_ERROR = 'SET_TOS_ERROR';
export const COMPLETE_VENDOR_SIGN_IN = 'COMPLETE_VENDOR_SIGN_IN';
export const CREATE_UPDATE_FIELDS_SNAPSHOT = 'CREATE_UPDATE_FIELDS_SNAPSHOT';
export const RESET_FIELDS_TO_FIELDS_SNAPSHOT =
    'RESET_FIELDS_TO_FIELDS_SNAPSHOT';
export const CLEAR_FIELDS_SNAPSHOT = 'CLEAR_FIELDS_SNAPSHOT';
export const RESET_UPDATE_PROFILE = 'RESET_UPDATE_PROFILE';

export function toggleSignIn() {
    return {
        type: SHOW_SIGN_IN,
    };
}

export function toggleSignUp() {
    return {
        type: SHOW_SIGN_UP,
    };
}

function startSubmittingOnboardingForm() {
    return {
        type: START_SUBMITTING_ONBOARDING_FORM,
    };
}

function failSubmittingOnboardingForm({
    company_email,
    company_website,
    email,
}) {
    return (dispatch, getState) => {
        const {
            onboardingPage: {
                validation: { companyEmail, companyWebsite, email: userEmail },
            },
        } = getState();

        const concatDefined = (state, update) =>
            state.concat(update).filter(id => id);

        return dispatch({
            type: FAIL_SUBMITTING_ONBOARDING_FORM,
            payload: {
                companyEmail: concatDefined(companyEmail, company_email),
                companyWebsite: concatDefined(companyWebsite, company_website),
                email: concatDefined(userEmail, email),
            },
        });
    };
}

function completeSubmittingOnboardingForm(payload) {
    return {
        type: COMPLETE_SUBMITTING_ONBOARDING_FORM,
        payload,
    };
}

function setOnboardingFormErrors(payload) {
    return {
        type: SET_ONBOARDING_FORM_ERRORS,
        payload,
    };
}

export function submitOnboardingForm(history) {
    return (dispatch, getState) => {
        const {
            auth: { id: vendorId },
            onboardingPage: { fields },
        } = getState();
        const errors = validate(
            fields,
            Object.values(onboardingFormFields),
            vendorId,
        );

        dispatch(setOnboardingFormErrors(errors));

        if (validationFailed(errors)) {
            window.scrollTo(0, 0);
        } else {
            dispatch(startSubmittingOnboardingForm());

            const requestBody = {
                email: fields[onboardingFormFields.EMAIL],
                name: fields[onboardingFormFields.NAME],
                phone: fields[onboardingFormFields.PHONE],
                company_name: fields[onboardingFormFields.COMPANY_NAME],
                company_email: fields[onboardingFormFields.COMPANY_EMAIL],
                company_phone: fields[onboardingFormFields.COMPANY_PHONE],
                company_website: fields[onboardingFormFields.COMPANY_WEBSITE],
                prefer_email:
                    fields[onboardingFormFields.CONTACT_PREFERENCE] ===
                    preferredContactTypes.email,
                prefer_phone:
                    fields[onboardingFormFields.CONTACT_PREFERENCE] ===
                    preferredContactTypes.telephone,
                rain_garden: fields[onboardingFormFields.RAIN_GARDEN],
                permeable_pavement:
                    fields[onboardingFormFields.PERMEABLE_PAVEMENT],
                subsurface_storage:
                    fields[onboardingFormFields.SUBSURFACE_STORAGE],
                green_roof: fields[onboardingFormFields.GREEN_ROOF],
                cistern: fields[onboardingFormFields.CISTERN],
                other_retrofit:
                    fields[onboardingFormFields.OTHER_RETROFIT_TEXT],
                ein: fields[onboardingFormFields.EIN],
                commercial_activity_license:
                    fields[onboardingFormFields.COMMERCIAL_ACTIVITY_LICENSE],
                should_email_parcel_updates_preference:
                    fields[onboardingFormFields.SHOULD_EMAIL_PARCEL_UPDATES],
                has_worked_on_grant:
                    fields[onboardingFormFields.HAS_WORKED_ON_GRANT],
                has_been_subgrantee:
                    fields[onboardingFormFields.HAS_BEEN_SUBGRANTEE],
                is_design_firm: fields[onboardingFormFields.IS_DESIGN_FIRM],
                is_construction_firm:
                    fields[onboardingFormFields.IS_CONSTRUCTION_FIRM],
                is_maintenance_firm:
                    fields[onboardingFormFields.IS_MAINTENANCE_FIRM],
                is_management_firm:
                    fields[onboardingFormFields.IS_MANAGEMENT_FIRM],
            };

            if (!vendorId) {
                requestBody.password = fields[onboardingFormFields.PASSWORD];
            }

            const formRequest = vendorId
                ? csrfRequest.patch(
                      `${updateProfileUrl}${vendorId}/`,
                      requestBody,
                  )
                : csrfRequest.post(onboardingFormUrl, requestBody);

            formRequest
                .then(() => {
                    dispatch(completeSubmittingOnboardingForm(fields));
                    if (!vendorId) {
                        history.push(ROUTES.staytuned);
                    }
                })
                .catch(({ response: { data } }) => {
                    dispatch(failSubmittingOnboardingForm(data));
                });
        }
    };
}

function setOnboardingSignUpFormErrors(payload) {
    return {
        type: SET_ONBOARDING_SIGN_UP_FORM_ERRORS,
        payload,
    };
}

export function signUp(history) {
    return (dispatch, getState) => {
        const errors = validate(getState().onboardingPage.fields, [
            onboardingFormFields.EMAIL,
            onboardingFormFields.PASSWORD,
            onboardingFormFields.CONFIRM_PASSWORD,
            onboardingFormFields.TOS,
        ]);

        dispatch(setOnboardingSignUpFormErrors(errors));

        if (!validationFailed(errors)) {
            history.push(ROUTES.profile);
        }
    };
}

export function updateOnboardingField({ field, value }) {
    if (!Object.values(onboardingFormFields).some(f => f === field)) {
        throw new Error(`Unsupported field: ${field}`);
    }

    return {
        type: UPDATE_ONBOARDING_FORM_FIELD,
        payload: {
            field,
            value,
        },
    };
}

export function toggleOnboardingField(field) {
    if (!Object.values(onboardingFormFields).some(f => f === field)) {
        throw new Error(`Unsupported field: ${field}`);
    }
    return {
        type: TOGGLE_ONBOARDING_FORM_FIELD,
        payload: {
            field,
        },
    };
}

export function toggleShowPassword() {
    return {
        type: TOGGLE_SHOW_PASSWORD,
    };
}

export function toggleShowConfirmPassword() {
    return {
        type: TOGGLE_SHOW_CONFIRM_PASSWORD,
    };
}

export function showTermsOfUseModal() {
    return {
        type: SHOW_TERMS_OF_USE_MODAL,
    };
}

export function hideTermsOfUseModal() {
    return {
        type: HIDE_TERMS_OF_USE_MODAL,
    };
}

function startSendResetPasswordEmail() {
    return {
        type: START_SEND_RESET_PASSWORD_EMAIL,
    };
}

function failSendResetPasswordEmail(error) {
    window.console.warn(error);

    return {
        type: FAIL_SEND_RESET_PASSWORD_EMAIL,
    };
}

function completeSendResetPasswordEmail() {
    return {
        type: COMPLETE_SEND_RESET_PASSWORD_EMAIL,
    };
}

function displayForgotPasswordEmailValidationError() {
    return {
        type: DISPLAY_FORGOT_PASSWORD_EMAIL_VALIDATION_ERROR,
    };
}

export function sendResetPasswordEmail() {
    return (dispatch, getState) => {
        dispatch(startSendResetPasswordEmail());

        const {
            onboardingPage: {
                page: {
                    resetPassword: { email },
                },
            },
        } = getState();

        return !email.length || !isEmail(email)
            ? dispatch(displayForgotPasswordEmailValidationError())
            : csrfRequest
                  .post(resetDeveloperPasswordUrl, { email })
                  .then(() => dispatch(completeSendResetPasswordEmail()))
                  .catch(e => dispatch(failSendResetPasswordEmail(e)));
    };
}

export function showForgotPassword() {
    return {
        type: SHOW_FORGOT_PASSWORD,
    };
}

export function updateForgotPasswordEmailAddress(payload) {
    return {
        type: UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS,
        payload,
    };
}

export function setTOSError() {
    return { type: SET_TOS_ERROR };
}

export function completeVendorSignIn(payload) {
    return dispatch => {
        const formattedPayload = formatFieldsToCamelCase(payload);
        // otherRetrofit field is saved as a string in db,
        // so turn back to boolean for checkbox ui and reallocate text
        if (formattedPayload[onboardingFormFields.OTHER_RETROFIT]) {
            formattedPayload[onboardingFormFields.OTHER_RETROFIT_TEXT] =
                formattedPayload[onboardingFormFields.OTHER_RETROFIT];
            formattedPayload[onboardingFormFields.OTHER_RETROFIT] =
                !!formattedPayload[onboardingFormFields.OTHER_RETROFIT];
        } else {
            formattedPayload[onboardingFormFields.OTHER_RETROFIT] = false;
        }
        dispatch({ type: COMPLETE_VENDOR_SIGN_IN, payload: formattedPayload });
    };
}

export function createUpdateFieldsSnapshot() {
    return (dispatch, getState) => {
        const {
            onboardingPage: {
                fields,
                updateProfile: { fieldsSnapshot },
            },
        } = getState();

        if (!fieldsSnapshot) {
            dispatch({ type: CREATE_UPDATE_FIELDS_SNAPSHOT, payload: fields });
        }
    };
}

export function resetFieldsToFieldsSnapshot() {
    return (dispatch, getState) => {
        const {
            onboardingPage: {
                updateProfile: { fieldsSnapshot },
            },
        } = getState();

        if (fieldsSnapshot) {
            dispatch({
                type: RESET_FIELDS_TO_FIELDS_SNAPSHOT,
                payload: fieldsSnapshot,
            });
        }
    };
}

export function resetUpdateProfile() {
    return {
        type: RESET_UPDATE_PROFILE,
    };
}
