import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { vendorMapUrl, garpMarketingUrl } from '../../common/constants';

const styles = {
    main: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '0 1.6rem',
        paddingBottom: '8rem',
    },
    heading: {
        maxWidth: '600px',
        margin: '4rem 0',
        font: '3.6rem/5.4rem "Open Sans", sans-serif',
        fontWeight: 700,
        textAlign: 'center',
    },
    aside: {
        maxWidth: '600px',
        marginTop: '4rem',
        padding: '2.4rem',
        backgroundColor: '#c6dae7',
    },
    list: {
        margin: 0,
        padding: 0,
        font: '1.6rem/2.4rem "Open Sans", sans-serif',
        fontWeight: 400,
        listStyle: 'none',
    },
    listItem: {
        marginBottom: '2.4rem',
    },
    link: {
        color: '#0078c8',
        textDecoration: 'none',
        fontWeight: 700,
    },
};

const NotFound = ({ location: { pathname } }) => {
    if (!pathname.startsWith('/stormwater/connect')) {
        return <Redirect to={`/stormwater/connect${pathname}`} />;
    }
    return (
        <div>
            <div style={styles.main}>
                <h1 style={styles.heading}>404 - page not found</h1>
                <aside style={styles.aside}>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>
                            The route {pathname} was not found. Try one of these
                            instead:
                        </li>
                        <li style={styles.listItem}>
                            Learn more about our{' '}
                            <a
                                style={styles.link}
                                href="https://water.phila.gov/stormwater/incentives/grants/"
                            >
                                Stormwater Grants Program
                            </a>
                            .
                        </li>
                        <li style={styles.listItem}>
                            Sign up for Stormwater Connect{' '}
                            <a style={styles.link} href={garpMarketingUrl}>
                                as a property owner.
                            </a>{' '}
                        </li>
                        <li style={styles.listItem}>
                            Sign up for Stormwater Connect{' '}
                            <a style={styles.link} href={vendorMapUrl}>
                                as a vendor.
                            </a>{' '}
                        </li>
                    </ul>
                </aside>
            </div>
        </div>
    );
};

NotFound.defaultProps = {
    location: {},
};

NotFound.propTypes = {
    location: object, // eslint-disable-line react/forbid-prop-types
};

export default connect(null)(NotFound);
