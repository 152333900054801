import { API_BASE_URL } from '../../constants';

export const onboardingFormUrl = `${API_BASE_URL}/signup/`;
export const updateProfileUrl = `${API_BASE_URL}/users/`;
export const otherRetrofitTextFieldId = 'profile-specialty-other-text';

export const signInError = {
    signInError: ['Unable to sign in with that email address and password.'],
};

export const onboardingFormFields = {
    TOS: 'tos',
    HAS_READ_TOS: 'has_read_tos',
    EMAIL: 'email',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirm_password',
    NAME: 'name',
    PHONE: 'phone',
    USE_COMPANY_CONTACT: 'useCompanyContact',
    COMPANY_NAME: 'companyName',
    COMPANY_EMAIL: 'companyEmail',
    COMPANY_PHONE: 'companyPhone',
    COMPANY_WEBSITE: 'companyWebsite',
    RAIN_GARDEN: 'rainGarden',
    SUBSURFACE_STORAGE: 'subsurfaceStorage',
    GREEN_ROOF: 'greenRoof',
    PERMEABLE_PAVEMENT: 'permeablePavement',
    CISTERN: 'cistern',
    OTHER_RETROFIT: 'otherRetrofit',
    OTHER_RETROFIT_TEXT: 'otherRetrofitText',
    EIN: 'ein',
    COMMERCIAL_ACTIVITY_LICENSE: 'commercialActivityLicense',
    SHOULD_EMAIL_PARCEL_UPDATES: 'shouldEmailParcelUpdatesPreference',
    CONTACT_PREFERENCE: 'contactPreference',
    HAS_WORKED_ON_GRANT: 'hasWorkedOnGrant',
    HAS_BEEN_SUBGRANTEE: 'hasBeenSubgrantee',
    IS_DESIGN_FIRM: 'isDesignFirm',
    IS_CONSTRUCTION_FIRM: 'isConstructionFirm',
    IS_MAINTENANCE_FIRM: 'isMaintenanceFirm',
    IS_MANAGEMENT_FIRM: 'isManagementFirm',
    HAS_SELECTED_AT_LEAST_ONE_FIRM_TYPE: 'hasSelectedAtLeastOneFirmType',
};

export const signInBoxStatesEnum = {
    showSignUp: 'showSignUp',
    showSignIn: 'showSignIn',
    showForgotPassword: 'showForgotPassword',
};
