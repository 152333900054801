import {
    STORE_UUID,
    START_FETCH_PREFERENCES_DATA,
    START_UNSUBSCRIBE_PARCEL,
    FAIL_FETCH_PREFERENCES_DATA,
    FAIL_UNSUBSCRIBE_PARCEL,
    COMPLETE_FETCH_PREFERENCES_DATA,
    COMPLETE_UNSUBSCRIBE_PARCEL,
    START_UPDATE_EDITED_PARCEL,
    FAIL_UPDATE_EDITED_PARCEL,
    COMPLETE_UPDATE_EDITED_PARCEL,
    SELECT_GEOCODER_RESULT,
    SHOW_CONFIRM_UNSUBSCRIBE_MODAL,
    HIDE_CONFIRM_UNSUBSCRIBE_MODAL,
    SHOW_EDIT_PREFERENCES_MODAL,
    HIDE_EDIT_PREFERENCES_MODAL,
    TOGGLE_RETROFIT_PREFERENCE,
    TOGGLE_RETROFIT_PREFERENCE_AND_UPDATE_DEVELOPERS,
    CHANGE_SELECTED_DEVELOPERS,
    TOGGLE_ACCEPTS_CONTACTS,
    HIDE_SUCCESS_TOAST,
} from './actions';

import { successToastMessages } from './constants';

const initialState = {
    uuid: null,
    editPreferencesModal: {
        visible: false,
        editableParcel: null,
        fetching: false,
        error: false,
        errorMessage: null,
    },
    confirmUnsubscribeModal: {
        visible: false,
        parcel: null,
        fetching: false,
        error: false,
    },
    parcels: {
        data: null,
        fetching: false,
        error: false,
    },
    successToast: {
        message: null,
        visible: false,
    },
};

export default function preferencesReducer(
    state = initialState,
    { type, payload },
) {
    switch (type) {
        case HIDE_SUCCESS_TOAST:
            return Object.assign({}, state, {
                successToast: initialState.successToast,
            });
        case STORE_UUID:
            return Object.assign({}, state, {
                uuid: payload,
            });
        case START_FETCH_PREFERENCES_DATA:
            return Object.assign({}, state, {
                parcels: Object.assign({}, state.parcels, {
                    fetching: true,
                    error: false,
                }),
            });
        case START_UNSUBSCRIBE_PARCEL:
            return Object.assign({}, state, {
                confirmUnsubscribeModal: Object.assign(
                    {},
                    state.confirmUnsubscribeModal,
                    {
                        fetching: true,
                        error: false,
                    },
                ),
            });
        case FAIL_FETCH_PREFERENCES_DATA:
            return Object.assign({}, state, {
                parcels: Object.assign({}, state.parcels, {
                    fetching: false,
                    error: true,
                }),
            });
        case FAIL_UNSUBSCRIBE_PARCEL:
            return Object.assign({}, state, {
                confirmUnsubscribeModal: Object.assign(
                    {},
                    state.confirmUnsubscribeModal,
                    {
                        fetching: false,
                        error: true,
                    },
                ),
            });
        case COMPLETE_FETCH_PREFERENCES_DATA:
            return Object.assign({}, state, {
                parcels: Object.assign({}, state.parcels, {
                    fetching: false,
                    error: false,
                    data: payload,
                }),
            });
        case COMPLETE_UNSUBSCRIBE_PARCEL:
            return Object.assign({}, state, {
                confirmUnsubscribeModal: initialState.confirmUnsubscribeModal,
                parcels: Object.assign({}, state.parcels, {
                    data: payload,
                }),
                successToast: Object.assign({}, state.successToast, {
                    visible: true,
                    message: successToastMessages.remove,
                }),
            });
        case SHOW_CONFIRM_UNSUBSCRIBE_MODAL:
            return Object.assign({}, state, {
                confirmUnsubscribeModal: Object.assign(
                    {},
                    state.confirmUnsubscribeModal,
                    {
                        visible: true,
                        parcel: payload,
                    },
                ),
                editPreferencesModal: initialState.editPreferencesModal,
            });
        case HIDE_CONFIRM_UNSUBSCRIBE_MODAL:
            return Object.assign({}, state, {
                confirmUnsubscribeModal: initialState.confirmUnsubscribeModal,
            });
        case SELECT_GEOCODER_RESULT:
            if (
                !state.parcels.data ||
                state.parcels.data.filter(
                    parcel => parcel.parcel_id === payload.Parcel.ParcelID,
                ).length > 0
            ) {
                return state;
            }
            return Object.assign({}, state, {
                parcels: Object.assign({}, state.parcels, {
                    data: state.parcels.data
                        .filter(parcel => !!parcel.id)
                        .concat({
                            id: null,
                            parcel_id: payload.Parcel.ParcelID,
                            property_manager:
                                state.parcels.data &&
                                state.parcels.data.length > 0
                                    ? state.parcels.data[0].property_manager
                                    : null,
                            accepts_contacts: false,
                            address: payload.Parcel.Address,
                            building_type: payload.Parcel.BldgType,
                            note: '',
                            cistern: false,
                            green_roof: false,
                            permeable_pavement: false,
                            rain_garden: false,
                            subsurface_storage: false,
                            selected_developers: [],
                        }),
                }),
            });
        case SHOW_EDIT_PREFERENCES_MODAL:
            return Object.assign({}, state, {
                confirmUnsubscribeModal: initialState.confirmUnsubscribeModal,
                editPreferencesModal: Object.assign(
                    {},
                    state.editPreferencesModal,
                    {
                        visible: true,
                        editableParcel: payload,
                    },
                ),
            });
        case HIDE_EDIT_PREFERENCES_MODAL:
            return Object.assign({}, state, {
                editPreferencesModal: initialState.editPreferencesModal,
            });
        case TOGGLE_ACCEPTS_CONTACTS:
        case TOGGLE_RETROFIT_PREFERENCE:
        case TOGGLE_RETROFIT_PREFERENCE_AND_UPDATE_DEVELOPERS:
        case CHANGE_SELECTED_DEVELOPERS:
            return Object.assign({}, state, {
                editPreferencesModal: Object.assign(
                    {},
                    state.editPreferencesModal,
                    {
                        editableParcel: payload,
                    },
                ),
            });
        case START_UPDATE_EDITED_PARCEL:
            return Object.assign({}, state, {
                editPreferencesModal: Object.assign(
                    {},
                    state.editPreferencesModal,
                    {
                        fetching: true,
                        error: false,
                    },
                ),
            });
        case FAIL_UPDATE_EDITED_PARCEL:
            return Object.assign({}, state, {
                editPreferencesModal: Object.assign(
                    {},
                    state.editPreferencesModal,
                    {
                        fetching: false,
                        error: true,
                        errorMessage: payload.response.data.parcels[0],
                    },
                ),
            });
        case COMPLETE_UPDATE_EDITED_PARCEL:
            return Object.assign({}, state, {
                editPreferencesModal: initialState.editPreferencesModal,
                parcels: Object.assign({}, state.parcels, {
                    data: payload,
                }),
                successToast: Object.assign({}, state.successToast, {
                    visible: true,
                    message: successToastMessages.edit,
                }),
            });
        default:
            return state;
    }
}
