import sortBy from 'lodash/sortBy';
import {
    dashboardSortOrderEnum,
    developerStatusTypes,
    sortableManagerTableColumns,
    sortableDeveloperTableColumns,
} from './constants';

export function normalizeAddParcelData({ Parcel, X, Y }) {
    return {
        Parcel,
        X,
        Y,
    };
}

const sortByFields = (data, fields, direction) => {
    if (direction === dashboardSortOrderEnum.ascending) {
        return sortBy(data, fields);
    }

    return sortBy(data, fields).reverse();
};

const getStatusString = ({ status }) => {
    const [statusString] = Object.entries(developerStatusTypes)
        .filter(([, statusId]) => status === statusId)
        .reduce((acc, [h]) => acc.concat(h), []);
    return statusString;
};

const getSignUpDate = ({ signUpDate }) => new Date(signUpDate).getTime();

export function sortManagerData(managerData, [sort, direction] = []) {
    if (!sort) {
        return managerData;
    }

    switch (sort) {
        case sortableManagerTableColumns.name:
            return sortByFields(
                managerData,
                ['propertyManager', getSignUpDate],
                direction,
            );
        case sortableManagerTableColumns.email:
            return sortByFields(
                managerData,
                ['contactEmail', getSignUpDate],
                direction,
            );
        case sortableManagerTableColumns.signupDate:
            return sortByFields(managerData, [getSignUpDate], direction);
        case sortableManagerTableColumns.interestedCount:
            return sortByFields(
                managerData,
                [({ developersOfInterest: di }) => di.length, getSignUpDate],
                direction,
            );
        case sortableManagerTableColumns.propertyCount:
            return sortByFields(
                managerData,
                [({ parcels }) => parcels.length, getSignUpDate],
                direction,
            );
        default:
            return managerData;
    }
}

export function sortDeveloperData(
    developerData,
    managerData,
    [sort, direction] = [],
) {
    if (!sort) {
        return developerData;
    }

    switch (sort) {
        case sortableDeveloperTableColumns.company:
            return sortByFields(
                developerData,
                ['companyName', getSignUpDate],
                direction,
            );
        case sortableDeveloperTableColumns.website:
            return sortByFields(
                developerData,
                ['website', getSignUpDate],
                direction,
            );
        case sortableDeveloperTableColumns.status:
            return sortByFields(
                developerData,
                [getStatusString, getSignUpDate],
                direction,
            );
        case sortableDeveloperTableColumns.signupDate:
            return sortByFields(developerData, [getSignUpDate], direction);
        case sortableDeveloperTableColumns.interestedCount:
            return sortByFields(
                developerData,
                [
                    ({ id }) =>
                        managerData.filter(({ developersOfInterest: di }) =>
                            di.includes(id),
                        ).length,
                    getSignUpDate,
                ],
                direction,
            );
        default:
            return developerData;
    }
}

export const createConfirmationModalStyle = (content = Object.freeze({})) =>
    Object.freeze({
        overlay: Object.freeze({
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(62, 69, 77, 0.65)',
            zIndex: 11000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }),
        content: Object.assign(
            {},
            Object.freeze({
                top: 'auto',
                right: 'auto',
                bottom: 'auto',
                left: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(255, 255, 255) none repeat scroll 0% 0%',
            }),
            content,
        ),
    });
