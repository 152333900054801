import { round } from 'lodash';
import parseWkt from 'wellknown';
import { convert } from 'terraformer-arcgis-parser';
import L from 'leaflet';
import { dynamicMapLayer, tiledMapLayer } from 'esri-leaflet';

import {
    basemapsEnum,
    cityCartoBasemap,
    cityCartoLabelsBasemap,
    parcelsAndImperviousLayersURL,
    searchMapCityLimitsExtent,
    VENDOR_PROPERTY_URL,
} from './constants';

import {
    phlAerialBasemap,
    phlBasemapAttribution,
    maxAerialBasemapZoom,
} from '../common/constants';

export function feetToAcres(sqft) {
    return round(sqft / 43560, 2);
}

export function parseGeom(wkt) {
    return parseWkt(wkt);
}

export function convertGeoJsonToAgsObject(geoJson) {
    return convert(geoJson).geometry;
}

export function validationFailed(errors) {
    return Object.values(errors).some(e => e.length > 0);
}

export function searchString(str1, str2) {
    const isStr = str => typeof str === 'string' || str instanceof String;
    if (!isStr(str1) || !isStr(str2)) {
        return false;
    }
    return str1.toLowerCase().trim().includes(str2.toLowerCase().trim());
}

export function parcelIdFromHistory({ location: { pathname } }) {
    return pathname.startsWith(VENDOR_PROPERTY_URL)
        ? pathname.slice(VENDOR_PROPERTY_URL.length + 1)
        : null;
}

export function selectEligibleParcels(data) {
    const eligibleBldgTypes = ['NonRes', 'Condo', 'Apt'];

    return data.filter(
        ({ Parcel }) =>
            Parcel &&
            Parcel.BldgType &&
            eligibleBldgTypes.includes(Parcel.BldgType),
    );
}

/** Given a list and an unformatted item that may or may not be in
 *  the list, return a new list with the item updated or added after
 *  applying a formatting function.
 *  @param formatItem - a function that formats the newOrEdited
 *  @param newOrEditedItem - an object with an id to format and add to the items
 *  @param items - a list of formatted objects
 */
export function shallowUpdateData(formatItem, newOrEditedItem, items) {
    const index = items.findIndex(({ id }) => id === newOrEditedItem.id);
    const itemsUpToEdited = index >= 0 ? items.slice(0, index) : items.slice();
    const itemsAfterEdited = index >= 0 ? items.slice(index + 1) : [];

    return itemsUpToEdited.concat(
        formatItem(newOrEditedItem),
        itemsAfterEdited,
    );
}

export function createParcelsOverlay() {
    const parcelsOverlay = dynamicMapLayer({
        url: parcelsAndImperviousLayersURL,
        f: 'image',
        layers: [0],
    });

    const dynamicLayerRequest = [
        {
            id: 12345,
            source: {
                type: 'mapLayer',
                mapLayerId: 0,
            },
            drawingInfo: {
                renderer: {
                    type: 'simple',
                    symbol: {
                        type: 'esriSFS',
                        style: 'esriSFSSolid',
                        color: [0, 0, 0, 0],
                        outline: {
                            type: 'esriSLS',
                            style: 'esriSLSSolid',
                            color: [198, 218, 231, 116],
                            width: 1,
                        },
                    },
                },
            },
        },
    ];

    parcelsOverlay.setDynamicLayers(JSON.stringify(dynamicLayerRequest));

    return parcelsOverlay;
}

export const calculateRequestedParcelIDs = ({
    aisGeocoderResult,
    savedParcels,
    interestedParcels,
    enrolledParcels,
    interestedFilter,
    savedPropertiesFilter,
    enrolledFilter,
}) => {
    const getParcelId = ({ parcelId }) => parcelId;
    const geocodedParcelIDs =
        aisGeocoderResult && aisGeocoderResult.parcels
            ? aisGeocoderResult.parcels.map(p => parseInt(p, 10))
            : null;

    const savedParcelIDs = savedParcels.filter(
        p => !geocodedParcelIDs || geocodedParcelIDs.includes(p),
    );
    const interestedParcelIDs = interestedParcels
        .map(getParcelId)
        .filter(p => !geocodedParcelIDs || geocodedParcelIDs.includes(p));
    const enrolledParcelIDs = enrolledParcels
        .map(getParcelId)
        .filter(p => !geocodedParcelIDs || geocodedParcelIDs.includes(p));

    if (interestedFilter && savedPropertiesFilter) {
        const interestedAndSavedParcels = [
            ...savedParcelIDs.filter(p => new Set(interestedParcelIDs).has(p)),
        ];

        return {
            requestedParcels: interestedAndSavedParcels.length
                ? interestedAndSavedParcels
                : null,
        };
    }
    if (enrolledFilter && savedPropertiesFilter) {
        const enrolledAndSavedParcels = [
            ...savedParcelIDs.filter(p => new Set(enrolledParcelIDs).has(p)),
        ];
        return {
            requestedParcels: enrolledAndSavedParcels.length
                ? enrolledAndSavedParcels
                : null,
        };
    }
    if (interestedFilter) {
        return {
            requestedParcels: interestedParcelIDs.length
                ? interestedParcelIDs
                : null,
        };
    }
    if (enrolledFilter) {
        return {
            requestedParcels: enrolledParcelIDs.length
                ? enrolledParcelIDs
                : null,
        };
    }
    if (savedPropertiesFilter) {
        return {
            requestedParcels: savedParcelIDs.length ? savedParcelIDs : null,
        };
    }
    if (geocodedParcelIDs) {
        return {
            requestedParcels: geocodedParcelIDs.length
                ? geocodedParcelIDs
                : null,
        };
    }

    return null;
};

export function createCityCartoBasemapAndAerialLayers(leafletMap, visibleBasemap) {
    const cityCartoBasemapMap = tiledMapLayer({
        url: cityCartoBasemap,
        attribution: phlBasemapAttribution,
    });

    const cityCartoBasemapLabelsMap = tiledMapLayer({
        url: cityCartoLabelsBasemap,
        attribution: phlBasemapAttribution,
    });

    const aerialMap = tiledMapLayer({
        url: phlAerialBasemap,
        attribution: phlBasemapAttribution,
        maxZoom: maxAerialBasemapZoom,
    });

    switch (visibleBasemap) {
        case basemapsEnum.cityCartoBasemap:
            cityCartoBasemapMap.addTo(leafletMap);
            cityCartoBasemapLabelsMap.addTo(leafletMap);
            cityCartoBasemapLabelsMap.bringToFront();
            break;
        case basemapsEnum.aerial:
            aerialMap.addTo(leafletMap);
            break;
        default:
            window.console.warn('Invalid basemap setting');
    }

    return [cityCartoBasemapMap, cityCartoBasemapLabelsMap, aerialMap];
}

export function getCityLimitsBbox() {
    return L.latLngBounds(
        L.latLng(
            searchMapCityLimitsExtent[0][1],
            searchMapCityLimitsExtent[0][0],
        ),
        L.latLng(
            searchMapCityLimitsExtent[1][1],
            searchMapCityLimitsExtent[1][0],
        ),
    );
}

function formatParcelsWKT(parcels) {
    if (parcels && !parcels.length) {
        return parcels.Parcel.Shape;
    }
    if (parcels.length === 1) {
        return parcels[0].Parcel.Shape;
    }
    const multipartParcelsWKT = parcels.map(p => p.Parcel.Shape).join(',');
    return `GEOMETRYCOLLECTION(${multipartParcelsWKT})`;
}

export function getParcelOrParcelsBounds(parcels) {
    const geojson = parseWkt(formatParcelsWKT(parcels));
    return L.geoJson(geojson).getBounds();
}

export function formatOwner(owner1, owner2) {
    return owner2 ? `${owner1} ${owner2}` : owner1;
}
