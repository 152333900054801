import { map } from 'lodash';

import { API_BASE_URL } from '../developer/constants';

import imgRainGarden from './img/rain-garden.png';
import imgGreenRoof from './img/green-roof.png';
import imgPermeablePavement from './img/porous-pavement.png';
import imgSubsurfaceStorage from './img/subsurface-storage.png';
import imgCistern from './img/cistern.png';

export function parcelAPIUrl(parcelId) {
    return `${API_BASE_URL}/pwd/parcel/${parcelId}`;
}

export function makeResendManagerEmailUrl(parcelId) {
    return `${API_BASE_URL}/parcels/${parcelId}/resend_customer_email/`;
}

export const resendCustomerEmail = `${API_BASE_URL}/customers/resend_customer_email/`;

export const regulatoryAPIUrl = `${API_BASE_URL}/pwd/regulation/current_rates`;

export const retrofitDevelopersUrl = `${API_BASE_URL}/retrofitvendors/`;

// API connection error formatted to match Django REST Framework sent errors
export const apiError = {
    apiError: ['An unknown error occurred!'],
};

export const defaultUnitCharge = 4.75;

export const defaultParcelGrossArea = 43560;
export const defaultParcelImperviousArea = 21780;

export const uiSections = {
    Hero: 'hero',
    ParcelForm: 'parcel-form',
    Benefits: 'benefits',
    PreferencesForm: 'deactivate-landing-page',
};

export const benefitFields = {
    RetrofitScope: 'retrofit-scope',
    TimeHorizon: 'time-horizon',
    Maintenance: 'maintenance',
    WaterBillCredits: 'water-bill-credits',
    AnnualSavings: 'annual-savings',
    AggregatedSavings: 'aggregated-savings',
};

export const mapFills = {
    [uiSections.Hero]: '#036791',
    [uiSections.ParcelForm]: '#b8dde1',
};

// Snake case to match API response
export const RAIN_GARDEN = 'rain_garden';
export const GREEN_ROOF = 'green_roof';
export const PERMEABLE_PAVEMENT = 'permeable_pavement';
export const SUBSURFACE_STORAGE = 'subsurface_storage';
export const CISTERN = 'cistern';

export const retrofits = [
    {
        name: 'Rain garden',
        title: 'Learn more about rain gardens',
        image: imgRainGarden,
        description: `A rain garden is a landscaped low area in the
            ground designed to capture, store, and soak up
            stormwater. Rain gardens are usually planted with a
            variety of grasses, flowers, and shrubs, and may
            have special soil that helps water soak into the
            ground.`,
        type: RAIN_GARDEN,
    },
    {
        name: 'Green roof',
        title: 'Learn more about green roofs',
        image: imgGreenRoof,
        description: `Green roofs, also referred to as vegetated roofs,
            consist of a layer of vegetation that covers an
            otherwise conventional flat or moderately pitched
            roof.`,
        type: GREEN_ROOF,
    },
    {
        name: 'Permeable pavement',
        title: 'Learn more about permeable pavement',
        image: imgPermeablePavement,
        description: `Porous pavement is a type of permeable hardscape
            that allows water to filter through the surface
            into the underlying soil or gravel. Porous pavement
            can be made of concrete, asphalt, or pavers, and
            usually looks very similar to traditional types of
            pavement.`,
        type: PERMEABLE_PAVEMENT,
    },
    {
        name: 'Subsurface storage',
        title: 'Learn more about subsurface storage',
        image: imgSubsurfaceStorage,
        description: `Subsurface basins are located underneath sidewalks,
            parking, or vegetated areas and are designed to
            capture, store, and sometimes infiltrate stormwater
            underground.`,
        type: SUBSURFACE_STORAGE,
    },
    {
        name: 'Cistern',
        title: 'Learn more about cisterns',
        image: imgCistern,
        description: `Cisterns are storage tanks, located either above or
            below ground, that hold rainwater for beneficial
            reuse or detention and slow-release back to the
            sewer system.`,
        type: CISTERN,
    },
];

export const NO_IDEA = 'no-idea';
export const retrofitTypes = map(retrofits, 'type');

export const SUBMIT_PROPERTY_MANAGER_FORM_URL = `${API_BASE_URL}/submitcustomerform/`;

export const mosaicSize = {
    // uom: px
    [uiSections.Hero]: 200,
    [uiSections.ParcelForm]: 120,
};

export const developerCompanyTypesEnum = {
    showAll: 'Show all',
    design: 'Design specialists',
    construction: 'Construction specialists',
    maintenance: 'Maintenance specialists',
    management: 'Project management specialists',
};
