import {
    START_SIGN_IN,
    FAIL_SIGN_IN,
    COMPLETE_SIGN_IN,
    COMPLETE_SIGN_OUT,
    START_FETCH_DEVELOPER_DATA,
    COMPLETE_FETCH_DEVELOPER_DATA,
    FAIL_FETCH_DEVELOPER_DATA,
    START_FETCH_MANAGER_DATA,
    COMPLETE_FETCH_MANAGER_DATA,
    FAIL_FETCH_MANAGER_DATA,
    START_UPDATE_DEVELOPER_STATUS,
    FAIL_UPDATE_DEVELOPER_STATUS,
    COMPLETE_UPDATE_DEVELOPER_STATUS,
    TOGGLE_DEVELOPER_FILTER_OPTION,
    TOGGLE_MANAGER_FILTER_OPTION,
    UPDATE_DEVELOPER_SEARCH_TEXT,
    UPDATE_MANAGER_SEARCH_TEXT,
    START_EDIT_MANAGER,
    EDIT_MANAGER_NAME,
    EDIT_MANAGER_EMAIL,
    EDIT_MANAGER_PHONE,
    EDIT_MANAGER_PREFERRED_CONTACT,
    EDIT_MANAGER_NOTE,
    START_SAVE_MANAGER_EDITS,
    FAIL_SAVE_MANAGER_EDITS,
    COMPLETE_SAVE_MANAGER_EDITS,
    STOP_EDIT_MANAGER,
    START_EDIT_DEVELOPER,
    EDIT_DEVELOPER_REASON_FOR_DENIAL,
    EDIT_DEVELOPER_COMPANY_NAME,
    EDIT_DEVELOPER_WEBSITE,
    EDIT_DEVELOPER_PHONE,
    EDIT_DEVELOPER_EMAIL,
    EDIT_DEVELOPER_PREFERRED_CONTACT,
    EDIT_DEVELOPER_CONTACT_NAME,
    EDIT_DEVELOPER_CONTACT_EMAIL,
    EDIT_DEVELOPER_CONTACT_PHONE,
    EDIT_DEVELOPER_CAL,
    EDIT_DEVELOPER_OTHER_RETROFIT,
    EDIT_DEVELOPER_EIN,
    EDIT_DEVELOPER_GRANT_APPROVAL,
    EDIT_DEVELOPER_SUBGRANT_APPROVAL,
    EDIT_DEVELOPER_DESIGN_SERVICES,
    EDIT_DEVELOPER_CONSTRUCTION_SERVICES,
    EDIT_DEVELOPER_MAINTENANCE_SERVICES,
    EDIT_DEVELOPER_MANAGEMENT_SERVICES,
    EDIT_DEVELOPER_SPECIALTIES,
    START_SAVE_DEVELOPER_EDITS,
    FAIL_SAVE_DEVELOPER_EDITS,
    COMPLETE_SAVE_DEVELOPER_EDITS,
    STOP_EDIT_DEVELOPER,
    START_REMOVE_DEVELOPER_OF_INTEREST,
    FAIL_REMOVE_DEVELOPER_OF_INTEREST,
    COMPLETE_REMOVE_DEVELOPER_OF_INTEREST,
    START_RESEND_EMAIL_VERIFICATION,
    FAIL_RESEND_EMAIL_VERIFICATION,
    COMPLETE_RESEND_EMAIL_VERIFICATION,
    START_REMOVE_INTERESTED_MANAGER,
    FAIL_REMOVE_INTERESTED_MANAGER,
    COMPLETE_REMOVE_INTERESTED_MANAGER,
    START_REMOVE_MANAGER_PARCEL,
    FAIL_REMOVE_MANAGER_PARCEL,
    COMPLETE_REMOVE_MANAGER_PARCEL,
    HIDE_ERROR_MODAL,
    SORT_MANAGER_DASHBOARD_EMAIL,
    SORT_MANAGER_DASHBOARD_NAME,
    SORT_MANAGER_DASHBOARD_SIGNUP_DATE,
    SORT_MANAGER_DASHBOARD_INTERESTED_COUNT,
    SORT_MANAGER_DASHBOARD_PROPERTY_COUNT,
    SORT_DEVELOPER_DASHBOARD_COMPANY,
    SORT_DEVELOPER_DASHBOARD_WEBSITE,
    SORT_DEVELOPER_DASHBOARD_SIGNUP_DATE,
    SORT_DEVELOPER_DASHBOARD_INTERESTED_COUNT,
    SORT_DEVELOPER_DASHBOARD_STATUS,
    RESET_MANAGER_TABLE_FILTERS_AND_SORTS,
    RESET_DEVELOPER_TABLE_FILTERS_AND_SORTS,
    START_SEND_DEVELOPER_PASSWORD_RESET_EMAIL,
    FAIL_SEND_DEVELOPER_PASSWORD_RESET_EMAIL,
    COMPLETE_SEND_DEVELOPER_PASSWORD_RESET_EMAIL,
    SHOW_ADD_PARCEL_MODAL,
    HIDE_ADD_PARCEL_MODAL,
    UPDATE_GEOCODER_SEARCH_TEXT,
    UPDATE_PARCEL_RETROFITS,
    UPDATE_PARCEL_DEVELOPERS,
    START_SAVE_ADDED_PARCEL,
    FAIL_SAVE_ADDED_PARCEL,
    COMPLETE_SAVE_ADDED_PARCEL,
    RESET_ADMIN_DASHBOARD,
    START_DELETE_MANAGER,
    FAIL_DELETE_MANAGER,
    COMPLETE_DELETE_MANAGER,
} from './actions';

import {
    START_FETCH_SINGLE_PARCEL,
    FAIL_FETCH_SINGLE_PARCEL,
    COMPLETE_FETCH_SINGLE_PARCEL,
} from '../../actions';

import {
    START_GEOCODER_SEARCH,
    FAIL_GEOCODER_SEARCH,
    SELECT_GEOCODER_RESULT,
} from '../../../geocoder/actions';

import {
    errorMessages,
    developerStatusTypes,
    newUserId,
    initialDeveloperTableSortOrder,
    initialManagerTableSortOrder,
    dashboardSortOrderEnum,
} from './constants';

import { preferredContactTypes } from '../../../common/constants';

import { normalizeAddParcelData } from './utils';

function updateDeveloper(developers, id, newData) {
    const developer = developers.find(d => d.id === id);
    const updatedDeveloper = Object.assign({}, developer, newData);
    return developers.filter(d => d.id !== id).concat(updatedDeveloper);
}

function toggleFilter(filters, filter) {
    if (filters.some(f => f === filter)) {
        return filters.filter(f => f !== filter);
    }

    return filters.concat(filter);
}

const initDeveloperData = {
    id: newUserId,
    companyName: '',
    website: '',
    email: '',
    phone: '',
    preferredContact: preferredContactTypes.email,
    ein: '',
    commercialActivityLicense: '',
    signUpDate: '',
    approvedDate: '',
    deactivatedDate: '',
    deniedDate: '',
    reasonForDenial: '',
    isEmailVerified: true,
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    retrofits: [],
    otherRetrofit: '',
    hasWorkedOnGrant: false,
    hasBeenSubgrantee: false,
    specialties: '',
    status: developerStatusTypes.active,
    password: 'temporary_password',
    isDesignFirm: false,
    isConstructionFirm: false,
    isMaintenanceFirm: false,
    isManagementFirm: false,
};

const initManagerData = {
    id: newUserId,
    propertyManager: '',
    contactEmail: '',
    contactPhone: '',
    preferredContact: preferredContactTypes.email,
    noteToDevelopers: '',
    parcels: [],
    developersOfInterest: [],
};

const initialState = {
    signIn: {
        signedIn: false,
        fetching: false,
        error: false,
    },
    errorMessage: null,
    developers: {
        data: [],
        fetching: false,
        error: false,
        filters: [],
        searchText: '',
        sortOrder: initialDeveloperTableSortOrder,
    },
    managers: {
        data: [],
        fetching: false,
        error: false,
        filters: [],
        searchText: '',
        sortOrder: initialManagerTableSortOrder,
    },
    managerEdit: {
        editing: false,
        data: initManagerData,
        saving: false,
        errors: null,
        error: false,
    },
    developerEdit: {
        editing: false,
        data: initDeveloperData,
        saving: false,
        errors: null,
        error: false,
    },
    emailVerification: {
        fetching: false,
        error: false,
    },
    addParcel: {
        visible: false,
        searchText: '',
        fetching: false,
        error: false,
        data: null,
        retrofits: [],
        developers: [],
    },
    resetPasswordStatus: {
        fetching: false,
        error: false,
    },
};

export default function adminDashboardPage(
    state = initialState,
    { type, payload },
) {
    switch (type) {
        case RESET_ADMIN_DASHBOARD:
            return initialState;
        case START_DELETE_MANAGER:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: true,
                    error: false,
                }),
            });
        case FAIL_DELETE_MANAGER:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: false,
                    error: true,
                }),
            });
        case COMPLETE_DELETE_MANAGER:
            return Object.assign({}, state, {
                managerEdit: initialState.managerEdit,
            });
        case START_SAVE_ADDED_PARCEL:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    fetching: true,
                    error: false,
                }),
            });
        case FAIL_SAVE_ADDED_PARCEL:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    fetching: false,
                    error: true,
                }),
            });
        case COMPLETE_SAVE_ADDED_PARCEL:
            return Object.assign({}, state, {
                addParcel: initialState.addParcel,
                managers: Object.assign({}, state.managers, {
                    data: payload,
                }),
            });
        case UPDATE_PARCEL_RETROFITS:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    retrofits: payload.updatedRetrofits,
                    developers: payload.updatedDevelopers,
                }),
            });
        case UPDATE_PARCEL_DEVELOPERS:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    developers: payload,
                }),
            });
        case SHOW_ADD_PARCEL_MODAL:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    visible: true,
                }),
            });
        case HIDE_ADD_PARCEL_MODAL:
            return Object.assign({}, state, {
                addParcel: initialState.addParcel,
            });
        case UPDATE_GEOCODER_SEARCH_TEXT:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    searchText: payload,
                    data: null,
                    error: false,
                }),
            });
        case START_FETCH_SINGLE_PARCEL:
        case START_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    fetching: true,
                    error: false,
                    data: null,
                }),
            });
        case FAIL_FETCH_SINGLE_PARCEL:
        case FAIL_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    fetching: false,
                    error: true,
                }),
            });
        case COMPLETE_FETCH_SINGLE_PARCEL:
        case SELECT_GEOCODER_RESULT:
            return Object.assign({}, state, {
                addParcel: Object.assign({}, state.addParcel, {
                    fetching: false,
                    error: false,
                    data: normalizeAddParcelData(payload),
                }),
            });
        case RESET_MANAGER_TABLE_FILTERS_AND_SORTS:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    sortOrder: initialState.managers.sortOrder,
                    filters: initialState.managers.filters,
                    searchText: initialState.managers.searchText,
                }),
            });
        case RESET_DEVELOPER_TABLE_FILTERS_AND_SORTS:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    sortOrder: initialState.developers.sortOrder,
                    filters: initialState.developers.filters,
                    searchText: initialState.developers.searchText,
                }),
            });
        case SORT_MANAGER_DASHBOARD_NAME:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.managers.sortOrder,
                        {
                            name: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_MANAGER_DASHBOARD_EMAIL:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.managers.sortOrder,
                        {
                            email: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_MANAGER_DASHBOARD_SIGNUP_DATE:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.managers.sortOrder,
                        {
                            signupDate: payload,
                        },
                    ),
                }),
            });
        case SORT_MANAGER_DASHBOARD_INTERESTED_COUNT:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.managers.sortOrder,
                        {
                            interestedCount: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_MANAGER_DASHBOARD_PROPERTY_COUNT:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.managers.sortOrder,
                        {
                            propertyCount: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_DEVELOPER_DASHBOARD_COMPANY:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.developers.sortOrder,
                        {
                            company: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_DEVELOPER_DASHBOARD_WEBSITE:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.developers.sortOrder,
                        {
                            website: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_DEVELOPER_DASHBOARD_INTERESTED_COUNT:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.developers.sortOrder,
                        {
                            interestedCount: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case SORT_DEVELOPER_DASHBOARD_SIGNUP_DATE:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.developers.sortOrder,
                        {
                            signupDate: payload,
                        },
                    ),
                }),
            });
        case SORT_DEVELOPER_DASHBOARD_STATUS:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    sortOrder: Object.assign(
                        {},
                        initialState.developers.sortOrder,
                        {
                            status: payload,
                            signupDate: dashboardSortOrderEnum.none,
                        },
                    ),
                }),
            });
        case START_SEND_DEVELOPER_PASSWORD_RESET_EMAIL:
            return Object.assign({}, state, {
                resetPasswordStatus: Object.assign(
                    {},
                    state.resetPasswordStatus,
                    {
                        fetching: true,
                        error: false,
                    },
                ),
            });
        case FAIL_SEND_DEVELOPER_PASSWORD_RESET_EMAIL:
            return Object.assign({}, state, {
                resetPasswordStatus: Object.assign(
                    {},
                    state.resetPasswordStatus,
                    {
                        fetching: false,
                        error: true,
                    },
                ),
            });
        case COMPLETE_SEND_DEVELOPER_PASSWORD_RESET_EMAIL:
            return Object.assign({}, state, {
                resetPasswordStatus: Object.assign(
                    {},
                    state.resetPasswordStatus,
                    {
                        fetching: false,
                        error: false,
                    },
                ),
            });
        case HIDE_ERROR_MODAL:
            return Object.assign({}, state, {
                errorMessage: null,
                signIn: Object.assign({}, state.signIn, {
                    error: false,
                }),
                developers: Object.assign({}, state.developers, {
                    error: false,
                }),
                managers: Object.assign({}, state.managers, {
                    error: false,
                }),
                managerEdit: Object.assign({}, state.managerEdit, {
                    error: false,
                }),
                developerEdit: Object.assign({}, state.developerEdit, {
                    error: false,
                }),
                emailVerification: Object.assign({}, state.emailVerification, {
                    error: false,
                }),
                resetPasswordStatus: Object.assign(
                    {},
                    state.resetPasswordStatus,
                    {
                        error: false,
                    },
                ),
            });
        case START_SIGN_IN:
            return Object.assign({}, state, {
                errorMessage: null,
                signIn: Object.assign({}, state.signIn, {
                    fetching: true,
                    error: false,
                }),
            });
        case FAIL_SIGN_IN:
            return Object.assign({}, state, {
                errorMessage: payload,
                signIn: Object.assign({}, state.signIn, {
                    fetching: false,
                    error: true,
                }),
            });
        case COMPLETE_SIGN_IN:
            return Object.assign({}, state, {
                errorMessage: null,
                signIn: Object.assign({}, state.signIn, {
                    signedIn: true,
                    fetching: false,
                    error: false,
                }),
            });
        case COMPLETE_SIGN_OUT:
            return Object.assign({}, state, {
                signIn: initialState.signIn,
            });
        case START_FETCH_DEVELOPER_DATA:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    data: [],
                    fetching: true,
                    error: false,
                }),
            });
        case COMPLETE_FETCH_DEVELOPER_DATA:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    fetching: false,
                    data: payload,
                    error: false,
                }),
            });
        case FAIL_FETCH_DEVELOPER_DATA:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failLoadDevelopersMessage,
                developers: Object.assign({}, state.developers, {
                    fetching: false,
                    error: true,
                }),
            });
        case START_FETCH_MANAGER_DATA:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    data: [],
                    fetching: true,
                    error: false,
                }),
            });
        case COMPLETE_FETCH_MANAGER_DATA:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    fetching: false,
                    data: payload,
                    error: false,
                }),
            });
        case FAIL_FETCH_MANAGER_DATA:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failLoadManagersMessage,
                managers: Object.assign({}, state.managers, {
                    fetching: false,
                    error: true,
                }),
            });
        case START_UPDATE_DEVELOPER_STATUS:
            return Object.assign({}, state, {
                errorMessage: initialState.errorMessage,
                developerEdit: Object.assign({}, state.developerEdit, {
                    saving: true,
                    error: false,
                }),
            });
        case COMPLETE_UPDATE_DEVELOPER_STATUS:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    data: updateDeveloper(
                        state.developers.data,
                        payload.id,
                        payload,
                    ),
                }),
                developerEdit: initialState.developerEdit,
            });
        case FAIL_UPDATE_DEVELOPER_STATUS:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failEditDeveloperMessage,
                developerEdit: Object.assign({}, state.developerEdit, {
                    saving: false,
                    error: true,
                }),
            });
        case TOGGLE_DEVELOPER_FILTER_OPTION:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    filters: toggleFilter(state.developers.filters, payload),
                }),
            });
        case TOGGLE_MANAGER_FILTER_OPTION:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    filters: toggleFilter(state.managers.filters, payload),
                }),
            });
        case UPDATE_DEVELOPER_SEARCH_TEXT:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    searchText: payload,
                }),
            });
        case UPDATE_MANAGER_SEARCH_TEXT:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    searchText: payload,
                }),
            });
        case START_EDIT_MANAGER:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    editing: true,
                    data: payload,
                }),
            });
        case EDIT_MANAGER_NAME:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    data: Object.assign({}, state.managerEdit.data, {
                        propertyManager: payload,
                    }),
                }),
            });
        case EDIT_MANAGER_EMAIL:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    data: Object.assign({}, state.managerEdit.data, {
                        contactEmail: payload,
                    }),
                }),
            });
        case EDIT_MANAGER_PHONE:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    data: Object.assign({}, state.managerEdit.data, {
                        contactPhone: payload,
                    }),
                }),
            });
        case EDIT_MANAGER_PREFERRED_CONTACT:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    data: Object.assign({}, state.managerEdit.data, {
                        preferredContact: payload,
                    }),
                }),
            });
        case EDIT_MANAGER_NOTE:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    data: Object.assign({}, state.managerEdit.data, {
                        noteToDevelopers: payload,
                    }),
                }),
            });
        case START_SAVE_MANAGER_EDITS:
            return Object.assign({}, state, {
                errorMessage: null,
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: true,
                    errors: null,
                    error: false,
                }),
            });
        case FAIL_SAVE_MANAGER_EDITS:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failEditManagerMessage,
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: false,
                    errors: payload,
                    error: true,
                }),
            });
        case COMPLETE_SAVE_MANAGER_EDITS:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: false,
                    errors: null,
                    editing: false,
                    data: initManagerData,
                }),
                managers: Object.assign({}, state.managers, {
                    data: payload,
                }),
            });
        case STOP_EDIT_MANAGER:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    editing: false,
                    data: initManagerData,
                    errors: null,
                }),
            });
        case START_EDIT_DEVELOPER:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    editing: true,
                    data: payload,
                }),
            });
        case EDIT_DEVELOPER_REASON_FOR_DENIAL:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        reasonForDenial: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_COMPANY_NAME:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        companyName: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_WEBSITE:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        website: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_EMAIL:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        email: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_PHONE:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        phone: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_PREFERRED_CONTACT:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        preferredContact: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_EIN:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        ein: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_CAL:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        commercialActivityLicense: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_OTHER_RETROFIT:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        otherRetrofit: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_GRANT_APPROVAL:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        hasWorkedOnGrant:
                            !state.developerEdit.data.hasWorkedOnGrant,
                    }),
                }),
            });
        case EDIT_DEVELOPER_SUBGRANT_APPROVAL:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        hasBeenSubgrantee:
                            !state.developerEdit.data.hasBeenSubgrantee,
                    }),
                }),
            });
        case EDIT_DEVELOPER_DESIGN_SERVICES:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        isDesignFirm: !state.developerEdit.data.isDesignFirm,
                    }),
                }),
            });
        case EDIT_DEVELOPER_CONSTRUCTION_SERVICES:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        isConstructionFirm:
                            !state.developerEdit.data.isConstructionFirm,
                    }),
                }),
            });
        case EDIT_DEVELOPER_MAINTENANCE_SERVICES:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        isMaintenanceFirm:
                            !state.developerEdit.data.isMaintenanceFirm,
                    }),
                }),
            });
        case EDIT_DEVELOPER_MANAGEMENT_SERVICES:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        isManagementFirm:
                            !state.developerEdit.data.isManagementFirm,
                    }),
                }),
            });
        case EDIT_DEVELOPER_SPECIALTIES:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        retrofits: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_CONTACT_NAME:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        contactName: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_CONTACT_EMAIL:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        contactEmail: payload,
                    }),
                }),
            });
        case EDIT_DEVELOPER_CONTACT_PHONE:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    data: Object.assign({}, state.developerEdit.data, {
                        contactPhone: payload,
                    }),
                }),
            });
        case START_REMOVE_INTERESTED_MANAGER:
        case START_SAVE_DEVELOPER_EDITS:
            return Object.assign({}, state, {
                errorMessage: null,
                developerEdit: Object.assign({}, state.developerEdit, {
                    saving: true,
                    errors: null,
                    error: false,
                }),
            });
        case FAIL_SAVE_DEVELOPER_EDITS:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failEditDeveloperMessage,
                developerEdit: Object.assign({}, state.developerEdit, {
                    saving: false,
                    errors: payload,
                    error: true,
                }),
            });
        case FAIL_REMOVE_INTERESTED_MANAGER:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failEditDeveloperMessage,
                developerEdit: Object.assign({}, state.developerEdit, {
                    saving: false,
                    error: true,
                }),
            });
        case COMPLETE_SAVE_DEVELOPER_EDITS:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    saving: false,
                    errors: null,
                    editing: false,
                    data: initDeveloperData,
                }),
                developers: Object.assign({}, state.developers, {
                    data: payload,
                }),
            });
        case STOP_EDIT_DEVELOPER:
            return Object.assign({}, state, {
                developerEdit: Object.assign({}, state.developerEdit, {
                    editing: false,
                    data: initDeveloperData,
                    errors: null,
                }),
            });
        case START_RESEND_EMAIL_VERIFICATION:
            return Object.assign({}, state, {
                emailVerification: Object.assign({}, state.emailVerification, {
                    fetching: true,
                    error: false,
                }),
            });
        case FAIL_RESEND_EMAIL_VERIFICATION:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failResendEmailMessage,
                emailVerification: Object.assign({}, state.emailVerification, {
                    fetching: false,
                    error: true,
                }),
            });
        case COMPLETE_RESEND_EMAIL_VERIFICATION:
            return Object.assign({}, state, {
                emailVerification: Object.assign({}, state.emailVerification, {
                    fetching: false,
                }),
            });
        case COMPLETE_REMOVE_MANAGER_PARCEL:
        case COMPLETE_REMOVE_INTERESTED_MANAGER:
        case COMPLETE_REMOVE_DEVELOPER_OF_INTEREST:
            return Object.assign({}, state, {
                managers: Object.assign({}, state.managers, {
                    data: payload,
                }),
            });
        case START_REMOVE_DEVELOPER_OF_INTEREST:
        case START_REMOVE_MANAGER_PARCEL:
            return Object.assign({}, state, {
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: true,
                }),
            });
        case FAIL_REMOVE_DEVELOPER_OF_INTEREST:
        case FAIL_REMOVE_MANAGER_PARCEL:
            return Object.assign({}, state, {
                errorMessage: errorMessages.failEditManagerMessage,
                managerEdit: Object.assign({}, state.managerEdit, {
                    saving: false,
                    error: true,
                }),
            });
        default:
            return state;
    }
}
