import {
    arrayOf,
    number,
    objectOf,
    oneOf,
    shape,
    string,
    bool,
} from 'prop-types';

import {
    developerStatusTypes as globalDeveloperStatusTypes,
    DASHBOARD_BASE_URL,
    API_BASE_URL,
} from '../../constants';

import { preferredContactTypes } from '../../../common/constants';

export const DASHBOARD_ROUTES = {
    signin: `${DASHBOARD_BASE_URL}/signin`,
    vendor: `${DASHBOARD_BASE_URL}/vendor`,
    addVendor: `${DASHBOARD_BASE_URL}/vendor/add`,
    vendors: `${DASHBOARD_BASE_URL}/vendors`,
    customer: `${DASHBOARD_BASE_URL}/customer`,
    customerAdd: `${DASHBOARD_BASE_URL}/customer/add`,
    customers: `${DASHBOARD_BASE_URL}/customers`,
    home: `${DASHBOARD_BASE_URL}/home`,
};

export const loginUrl = `${API_BASE_URL}/dashboard-login/`;
export const logoutUrl = `${API_BASE_URL}/dashboard-logout/`;
export const propertyManagersUrl = `${API_BASE_URL}/customers/`;
export const retrofitDevelopersUrl = `${API_BASE_URL}/dashboard-retrofitvendors/`;
export const userUrl = `${API_BASE_URL}/users/`;
export const resendEmailUrl = `${API_BASE_URL}/dashboard-retrofitvendors/email-verification/`;
export const addRetrofitDeveloperUrl = `${API_BASE_URL}/dashboard-retrofitvendors/new-vendor/`;
export const addPropertyManagerUrl = `${API_BASE_URL}/submitcustomerform/`;
export const addManagerParcelUrl = `${API_BASE_URL}/parcels/`;

export function makePropertyManagerEditUrl(id) {
    return `${propertyManagersUrl}${id}/`;
}

export function makeRetrofitDeveloperEditUrl(id) {
    return `${userUrl}${id}/`;
}

export function makeRemoveDeveloperOfInterestUrl(
    parcelId,
    managerId,
    developerId,
) {
    return `${propertyManagersUrl}${managerId}/parcels/${parcelId}/vendors/${developerId}/`;
}

export function makeRemoveInterestedManagerUrl(developerId, managerId) {
    return `${userUrl}${developerId}/customers/${managerId}/`;
}

export function makeRemoveParcelUrl(parcelId) {
    return `${API_BASE_URL}/parcels/${parcelId}/`;
}

export const networkError = 'Network Error';
export const forbiddenError = 'Unable to login in with those credentials';
export const unknownError = 'An unknown error occurred!';

// API connection error formatted to match Django REST Framework sent errors
export const apiError = {
    apiError: [unknownError],
};

export const validationErrorPropType = arrayOf(string);
export const validationErrorsPropType = objectOf(validationErrorPropType);

export const editableFieldTypes = {
    string: 'string',
    dropdown: 'dropdown',
    textbox: 'textbox',
    checkbox: 'checkbox',
    retrofitSelection: 'retrofitSelection',
    companyTypeSelection: 'companyTypeSelection',
};

export const developerStatusTypes = globalDeveloperStatusTypes;

export const factListLinkTypes = {
    email: 'email',
    website: 'website',
    telephone: 'telephone',
};

export const newUserId = 0;

export const developerDataPropType = shape({
    id: number.isRequired,
    companyName: string.isRequired,
    website: string.isRequired,
    email: string.isRequired,
    phone: string.isRequired,
    preferredContact: oneOf(Object.values(preferredContactTypes)),
    ein: string.isRequired,
    commercialActivityLicense: string.isRequired,
    signUpDate: string.isRequired,
    approvedDate: string,
    deactivatedDate: string,
    deniedDate: string,
    reasonForDenial: string,
    status: oneOf(Object.values(developerStatusTypes)).isRequired,
    isEmailVerified: bool.isRequired,
    contactName: string.isRequired,
    contactEmail: string.isRequired,
    contactPhone: string,
    retrofits: arrayOf(string),
    otherRetrofit: string,
    isDesignFirm: bool.isRequired,
    isConstructionFirm: bool.isRequired,
    isMaintenanceFirm: bool.isRequired,
    isManagementFirm: bool.isRequired,
});

export const parcelDataPropType = shape({
    id: number.isRequired,
    managerId: number.isRequired,
    address: string.isRequired,
});

export const managerDataPropType = shape({
    id: number.isRequired,
    propertyManager: string.isRequired,
    contactEmail: string,
    contactPhone: string,
    preferredContact: oneOf(Object.values(preferredContactTypes)),
    noteToDevelopers: string,
    parcels: arrayOf(parcelDataPropType),
    developersOfInterest: arrayOf(number),
});

export const retrofitTypes = {
    rainGarden: 'Rain garden',
    greenRoof: 'Green roof',
    permeablePavement: 'Permeable pavement',
    subsurfaceStorage: 'Subsurface storage',
    cistern: 'Cistern',
};

export const propertyTypes = {
    industrialAndCommercial: 'Non-residential',
    multifamily: 'Condo',
};

export const parcelBuildingTypes = {
    NonRes: propertyTypes.industrialAndCommercial,
    Condo: propertyTypes.multifamily,
};

export const errorMessages = {
    failLoadDevelopersMessage: `Unable to load vendors, due to a problem
        with our server or the network. Try again later.`,
    failLoadManagersMessage: `Unable to load customers, due to a problem with
        our server or the network. Try again later.`,
    failEditDeveloperMessage: 'Unable to save vendor edits.',
    failEditManagerMessage: 'Unable to save customer edits.',
    failResendEmailMessage: 'Unable to resend email verification email.',
    defaultErrorMessage: 'An error occurred.',
};

export const dashboardSortOrderEnum = {
    ascending: 'ascending',
    descending: 'descending',
    none: 'none',
};

// Used to set the initial reducer sort order
// and to indicate the sortable columns in the tables
export const initialManagerTableSortOrder = {
    name: dashboardSortOrderEnum.none,
    email: dashboardSortOrderEnum.none,
    signupDate: dashboardSortOrderEnum.ascending,
    propertyCount: dashboardSortOrderEnum.none,
    interestedCount: dashboardSortOrderEnum.none,
};

export const sortableManagerTableColumns = Object.entries(
    initialManagerTableSortOrder,
).reduce((acc, [key]) => Object.assign({}, acc, { [key]: key }), {});

export const initialDeveloperTableSortOrder = {
    company: dashboardSortOrderEnum.none,
    website: dashboardSortOrderEnum.none,
    signupDate: dashboardSortOrderEnum.ascending,
    interestedCount: dashboardSortOrderEnum.none,
    status: dashboardSortOrderEnum.none,
};

export const sortableDeveloperTableColumns = Object.entries(
    initialDeveloperTableSortOrder,
).reduce((acc, [key]) => Object.assign({}, acc, { [key]: key }), {});
