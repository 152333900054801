import { at, head, upperFirst } from 'lodash';

export function formatCostRange({ costMin, costMax }) {
    return `$ ${costMin.toLocaleString()}–${costMax.toLocaleString()}`;
}

function capitalizeWord(word) {
    return upperFirst(word.toLowerCase());
}

export function formatParcelAddress(address, withoutCity = false) {
    // Zip code isn't available in the parcel endpoint, but
    // we may be able to get it from the Geocoder

    const capitalizedAddress = address.split(' ').map(capitalizeWord).join(' ');

    if (withoutCity) {
        return capitalizedAddress;
    }

    return `${capitalizedAddress}, Philadelphia, PA`;
}

export function maybeGet(obj, path) {
    return head(at(obj, path)) || null;
}

const normalizeText = s => (s ? s.toLowerCase().trim() : '');
const normalizePhoneNumber = s =>
    s ? normalizeText(s).replace(/[^+\d]+/g, '') : '';
const includesQuery = (s, query) =>
    normalizeText(s).includes(normalizeText(query));

export const developerMatchesQuery = (developer, developersQuery) =>
    includesQuery(developer.name, developersQuery) ||
    includesQuery(developer.phoneNumber, developersQuery) ||
    normalizePhoneNumber(developer.phoneNumber).includes(
        normalizeText(developersQuery),
    ) ||
    includesQuery(developer.url, developersQuery) ||
    developer.retrofits.some(retrofit =>
        includesQuery(retrofit, developersQuery),
    );
