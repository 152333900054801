import {
    SET_UID,
    SET_TOKEN,
    SET_NEW_PASSWORD_ONE,
    SET_NEW_PASSWORD_TWO,
    START_RESET_PASSWORD,
    FAIL_RESET_PASSWORD,
    COMPLETE_RESET_PASSWORD,
    CLEAR_RESET_PASSWORD,
} from './actions';

const initialState = {
    uid: null,
    token: null,
    newPasswordOne: '',
    newPasswordTwo: '',
    fetching: false,
    error: false,
    errorMessages: null,
    confirmationResponse: false,
};

export default function resetPasswordPage(
    state = initialState,
    { type, payload },
) {
    switch (type) {
        case SET_UID:
            return Object.assign({}, state, {
                uid: payload,
            });
        case SET_TOKEN:
            return Object.assign({}, state, {
                token: payload,
            });
        case SET_NEW_PASSWORD_ONE:
            return Object.assign({}, state, {
                newPasswordOne: payload,
            });
        case SET_NEW_PASSWORD_TWO:
            return Object.assign({}, state, {
                newPasswordTwo: payload,
            });
        case START_RESET_PASSWORD:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
                errorMessages: null,
            });
        case FAIL_RESET_PASSWORD:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
                errorMessages: payload,
            });
        case COMPLETE_RESET_PASSWORD:
            return Object.assign({}, initialState, {
                confirmationResponse: true,
            });
        case CLEAR_RESET_PASSWORD:
            return initialState;
        default:
            return state;
    }
}
