import { onboardingFormFields } from './constants';

function required(state, field) {
    if (!state[field]) {
        return 'This field is required';
    }
    return null;
}

function makeRequiredWhenTruthyValidator(otherField) {
    return (state, field) => {
        if (
            (state[field] && !state[otherField]) ||
            (!state[field] && state[otherField])
        ) {
            return 'This field is required';
        }
        return null;
    };
}

function makeRequiredEqualityValidator(otherField) {
    return (state, field) => {
        if (state[field] !== state[otherField]) {
            return `This field must match the ${otherField} field`;
        }
        return null;
    };
}

function makeAtLeastOneFirmTypeOptionSelectedValidator({
    [onboardingFormFields.IS_DESIGN_FIRM]: isDesignFirm,
    [onboardingFormFields.IS_CONSTRUCTION_FIRM]: isConstructionFirm,
    [onboardingFormFields.IS_MAINTENANCE_FIRM]: isMaintenanceFirm,
    [onboardingFormFields.IS_MANAGEMENT_FIRM]: isManagementFirm,
}) {
    return [
        isDesignFirm,
        isConstructionFirm,
        isMaintenanceFirm,
        isManagementFirm,
    ].some(Boolean)
        ? null
        : 'At least one selection is required';
}

const validators = {
    [onboardingFormFields.EMAIL]: [required],
    [onboardingFormFields.PASSWORD]: [required],
    [onboardingFormFields.CONFIRM_PASSWORD]: [
        makeRequiredEqualityValidator(onboardingFormFields.PASSWORD),
    ],
    [onboardingFormFields.NAME]: [required],
    [onboardingFormFields.TOS]: [required],
    [onboardingFormFields.COMPANY_NAME]: [required],
    [onboardingFormFields.COMPANY_EMAIL]: [required],
    [onboardingFormFields.COMPANY_PHONE]: [required],
    [onboardingFormFields.EIN]: [required],
    [onboardingFormFields.OTHER_RETROFIT_TEXT]: [
        makeRequiredWhenTruthyValidator(onboardingFormFields.OTHER_RETROFIT),
    ],
    [onboardingFormFields.HAS_SELECTED_AT_LEAST_ONE_FIRM_TYPE]: [
        makeAtLeastOneFirmTypeOptionSelectedValidator,
    ],
};

/* eslint-disable import/prefer-default-export */
/**
   Run the validation functions for a set of onboarding form
   fields. Return the results for each field in a map of the
   field and its errors.

   @param {object} state - the current state of the fields, a
       map of field name and value
   @param {arrayOf(string)} - the fields you want to validate,
       should match keys in the `state` param
   @return
   {
       [onboardingFormFields.FIELD]: ['I am an error', 'I am another error'],
       [onboardingFormFields.OTHER_FIELD]: [] // <-- valid field, no errors
   }
*/
export function validate(state, fields, vendorId) {
    const validationErrors = {};
    fields.forEach(field => {
        const fieldValidators = validators[field];
        const skipForUpdateProfileValidation =
            vendorId &&
            (field === onboardingFormFields.PASSWORD ||
                field === onboardingFormFields.TOS);

        if (!fieldValidators || skipForUpdateProfileValidation) {
            validationErrors[field] = [];
            return;
        }

        validationErrors[field] = fieldValidators
            .map(v => v(state, field))
            .filter(v => v);
    });
    return validationErrors;
}

export function formatFieldsToCamelCase(fields) {
    const camelCaseKeys = Object.keys(fields).map(key => {
        const newKey = onboardingFormFields[key.toUpperCase()];
        return { [newKey]: fields[key] };
    });
    return Object.assign({}, ...camelCaseKeys);
}
