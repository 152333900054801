import { combineReducers } from 'redux';
import { isEmpty } from 'lodash';

import customersApp from '../marketing/reducers';
import adminDashboardPage from './components/admindashboard/reducers';
import onboardingPage from './components/onboarding/reducers';
import geocoderReducer from '../geocoder/reducers';
import resetPasswordPage from './components/resetpassword/reducers';
import aisGeocoder from './components/aisGeocoder/reducers';
import latLngParcelSearch from './components/latLngParcelSearch/reducers';

import {
    errorMessages,
    initialSearchMapCenter,
    initialSearchMapZoom,
    basemapsEnum,
    successfulAISGeocoderSearchZoom,
} from './constants';

import { COMPLETE_AIS_GEOCODER_SEARCH } from './components/aisGeocoder/actions';
import { COMPLETE_RESET_PASSWORD } from './components/resetpassword/actions';

import {
    START_FETCH_CSV,
    FAIL_FETCH_CSV,
    COMPLETE_FETCH_CSV,
    CANCEL_FETCH_SINGLE_PARCEL,
    START_FETCH_SINGLE_PARCEL,
    FAIL_FETCH_SINGLE_PARCEL,
    COMPLETE_FETCH_SINGLE_PARCEL,
    START_FETCH_PARCEL_DATA,
    FAIL_FETCH_PARCEL_DATA,
    COMPLETE_FETCH_PARCEL_DATA,
    CLEAR_PARCEL_DATA,
    COMPLETE_FETCH_SAVED_PARCELS,
    FAIL_FETCH_SAVED_PARCELS,
    COMPLETE_FETCH_INTERESTED_PARCELS,
    FAIL_FETCH_INTERESTED_PARCELS,
    COMPLETE_FETCH_ENROLLED_PARCELS,
    CHANGE_PARCELS_QUERY_BBOX,
    CHANGE_GROUND_MIN,
    CHANGE_GROUND_MAX,
    CHANGE_ROOF_MIN,
    CHANGE_ROOF_MAX,
    CHANGE_TOTAL_MIN,
    CHANGE_TOTAL_MAX,
    CHANGE_NONRES_FILTER,
    CHANGE_CONDOS_FILTER,
    CHANGE_CAP_FILTER,
    CHANGE_GSI_FILTER,
    CHANGE_SAVED_FILTER,
    CHANGE_MAP_EXTENT_FILTER,
    SELECT_INTERESTED_FILTER,
    SELECT_ELIGIBLE_FILTER,
    SELECT_ENROLLED_FILTER,
    HIGHLIGHT_PARCEL,
    UNHIGHLIGHT_PARCEL,
    DESELECT_PARCEL,
    START_SAVE_PARCEL,
    COMPLETE_SAVE_PARCEL,
    FAIL_SAVE_PARCEL,
    START_UNSAVE_PARCEL,
    COMPLETE_UNSAVE_PARCEL,
    FAIL_UNSAVE_PARCEL,
    UPDATE_MAP,
    SHOW_IMPERVIOUS_LAYER,
    HIDE_IMPERVIOUS_LAYER,
    SHOW_SEWER_LAYER,
    HIDE_SEWER_LAYER,
    SHOW_AERIAL_BASEMAP,
    SHOW_CARTO_BASEMAP,
    OPEN_ROOF_FILTER,
    OPEN_GROUND_FILTER,
    OPEN_TOTAL_FILTER,
    OPEN_STATUS_FILTER,
    OPEN_CHARACTERISTICS_FILTER,
    CLOSE_ALL_FILTERS,
    RESET_FILTERS,
    SHOW_SIGN_IN,
    START_DEVELOPER_SIGN_IN,
    FAIL_DEVELOPER_SIGN_IN,
    COMPLETE_DEVELOPER_SIGN_IN,
    START_DEVELOPER_SIGN_OUT,
    FAIL_DEVELOPER_SIGN_OUT,
    COMPLETE_DEVELOPER_SIGN_OUT,
    UPDATE_DEVELOPER_AUTH_EMAIL,
    UPDATE_DEVELOPER_AUTH_PASSWORD,
    START_CONFIRM_EMAIL,
    COMPLETE_CONFIRM_EMAIL,
    FAIL_CONFIRM_EMAIL,
    SHOW_TERMS_OF_USE_MODAL,
    HIDE_TERMS_OF_USE_MODAL,
    SHOW_DELETE_ACCOUNT_MODAL,
    HIDE_DELETE_ACCOUNT_MODAL,
    COMPLETE_DELETE_ACCOUNT,
    FAIL_DELETE_ACCOUNT_STATUS,
    RESET_DELETE_ACCOUNT_STATUS,
    HIDE_ERROR_MODAL,
    RESET_RETROFIT_MAP,
    EXPAND_PROPERTY_SEARCH_MAP,
    CONTRACT_PROPERTY_SEARCH_MAP,
    EXPAND_PROPERTY_DETAILS_MAP,
    CONTRACT_PROPERTY_DETAILS_MAP,
} from './actions';

export const initialMainPageState = {
    parcelFilters: {
        groundMaxFilter: null,
        groundMinFilter: null,
        roofMaxFilter: null,
        roofMinFilter: null,
        savedPropertiesFilter: false,
        interestedFilter: false,
        enrolledFilter: true,
        eligibleFilter: false,
        nonResidentialFilter: true,
        condosFilter: true,
        capFilter: null, // Can be true, false, or null
        gsiFilter: null, // Can be true, false, or null
        bbox: null,
        roofFilterOpen: false,
        groundFilterOpen: false,
        totalFilterOpen: false,
        characteristicsFilterOpen: false,
        statusFilterOpen: false,
        mapExtentFilter: true,
    },
    map: {
        center: initialSearchMapCenter,
        zoom: initialSearchMapZoom,
        expanded: false,
    },
    savedParcels: [],
    interestedParcels: [],
    enrolledParcels: [],
    parcels: null,
    fetching: false,
    error: false,
    errorMessage: null,
    deleteModalErrorMessage: null,
    highlightedParcel: null,
    displayTermsOfUseModal: false,
    displayDeleteAccountModal: false,
    visibleBasemap: basemapsEnum.cityCartoBasemap,
    imperviousLayerVisible: false,
    sewerLayerVisible: false,
    csv: {
        data: null,
        fetching: false,
        error: false,
        fetchInterested: false,
        fetchSaved: false,
    },
};

function mainPage(state = initialMainPageState, { type, payload }) {
    switch (type) {
        case RESET_RETROFIT_MAP:
            return initialMainPageState;
        case EXPAND_PROPERTY_SEARCH_MAP:
            return Object.assign({}, state, {
                map: Object.assign({}, state.map, {
                    expanded: true,
                }),
            });
        case CONTRACT_PROPERTY_SEARCH_MAP:
            return Object.assign({}, state, {
                map: Object.assign({}, state.map, {
                    expanded: false,
                }),
            });
        case START_FETCH_CSV:
            return Object.assign({}, state, {
                csv: Object.assign({}, state.csv, {
                    fetching: true,
                    error: false,
                    fetchInterested: payload.fetchInterested,
                    fetchSaved: payload.fetchSaved,
                }),
            });
        case FAIL_FETCH_CSV:
            return Object.assign({}, state, {
                csv: Object.assign({}, state.csv, {
                    fetching: false,
                    error: true,
                }),
            });
        case COMPLETE_FETCH_CSV:
            return Object.assign({}, state, {
                csv: Object.assign({}, state.csv, {
                    fetching: false,
                    error: false,
                    data: payload,
                }),
            });
        case SHOW_IMPERVIOUS_LAYER:
            return Object.assign({}, state, {
                imperviousLayerVisible: true,
            });
        case HIDE_IMPERVIOUS_LAYER:
            return Object.assign({}, state, {
                imperviousLayerVisible: false,
            });
        case SHOW_SEWER_LAYER:
            return Object.assign({}, state, {
                sewerLayerVisible: true,
            });
        case HIDE_SEWER_LAYER:
            return Object.assign({}, state, {
                sewerLayerVisible: false,
            });
        case SHOW_AERIAL_BASEMAP:
            return Object.assign({}, state, {
                visibleBasemap: basemapsEnum.aerial,
            });
        case SHOW_CARTO_BASEMAP:
            return Object.assign({}, state, {
                visibleBasemap: basemapsEnum.cityCartoBasemap,
            });
        case RESET_FILTERS:
            return Object.assign({}, state, {
                parcelFilters: Object.assign(
                    {},
                    initialMainPageState.parcelFilters,
                    {
                        bbox: state.parcelFilters.bbox,
                    },
                ),
            });
        case COMPLETE_AIS_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    roofFilterOpen: false,
                    groundFilterOpen: false,
                    totalFilterOpen: false,
                    characteristicsFilterOpen: false,
                    statusFilterOpen: false,
                    bbox: null,
                }),
                parcels: initialMainPageState.parcels,
                highlightedParcel: initialMainPageState.highlightedParcel,
                map: Object.assign({}, state.map, {
                    center: payload.isNotIntersection
                        ? state.map.center
                        : payload.coordinates,
                    zoom: payload.isNotIntersection
                        ? state.map.zoom
                        : successfulAISGeocoderSearchZoom,
                }),
            });
        case SHOW_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                displayTermsOfUseModal: true,
            });
        case HIDE_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                displayTermsOfUseModal: false,
            });
        case SHOW_DELETE_ACCOUNT_MODAL:
            return Object.assign({}, state, {
                displayDeleteAccountModal: true,
            });
        case HIDE_DELETE_ACCOUNT_MODAL:
            return Object.assign({}, state, {
                displayDeleteAccountModal: false,
            });
        case COMPLETE_DELETE_ACCOUNT:
            return Object.assign({}, state, {
                displayDeleteAccountModal: false,
            });
        case FAIL_DELETE_ACCOUNT_STATUS:
            return Object.assign({}, state, {
                error: true,
                deleteModalErrorMessage: errorMessages.defaultErrorMessage,
            });
        case RESET_DELETE_ACCOUNT_STATUS:
            return Object.assign({}, state, {
                error: false,
                deleteModalErrorMessage: null,
            });
        case OPEN_GROUND_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    groundFilterOpen: true,
                }),
            });
        case OPEN_ROOF_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    roofFilterOpen: true,
                }),
            });
        case OPEN_TOTAL_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    totalFilterOpen: true,
                }),
            });
        case OPEN_CHARACTERISTICS_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    characteristicsFilterOpen: true,
                }),
            });
        case OPEN_STATUS_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    statusFilterOpen: true,
                }),
            });
        case CLOSE_ALL_FILTERS:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    roofFilterOpen: false,
                    groundFilterOpen: false,
                    totalFilterOpen: false,
                    characteristicsFilterOpen: false,
                    statusFilterOpen: false,
                }),
            });
        case START_SAVE_PARCEL:
            return Object.assign({}, state, {
                savedParcels: [
                    ...new Set(state.savedParcels.concat([payload])),
                ],
                csv: initialMainPageState.csv,
            });
        case COMPLETE_SAVE_PARCEL:
            return Object.assign({}, state, {
                savedParcels: [...new Set(payload)],
            });
        case FAIL_SAVE_PARCEL:
            return Object.assign({}, state, {
                savedParcels: [
                    ...new Set(state.savedParcels.filter(id => payload !== id)),
                ],
                error: true,
                errorMessage: errorMessages.failSaveParcelMessage,
            });
        case START_UNSAVE_PARCEL:
            return Object.assign({}, state, {
                savedParcels: [
                    ...new Set(state.savedParcels.filter(id => payload !== id)),
                ],
                csv: initialMainPageState.csv,
            });
        case COMPLETE_UNSAVE_PARCEL:
            return Object.assign({}, state, {
                savedParcels: [...new Set(payload)],
            });
        case FAIL_UNSAVE_PARCEL:
            return Object.assign({}, state, {
                savedParcels: [
                    ...new Set(state.savedParcels.concat([payload])),
                ],
                error: true,
                errorMessage: errorMessages.failUnsaveParcelMessage,
            });
        case UPDATE_MAP:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    bbox: payload.bounds,
                }),
                map: Object.assign({}, state.map, {
                    center: isEmpty(payload.center)
                        ? state.map.center
                        : payload.center,
                    zoom: payload.zoom ? payload.zoom : state.map.zoom,
                }),
            });
        case CHANGE_PARCELS_QUERY_BBOX:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    bbox: payload,
                }),
            });
        case CHANGE_GROUND_MIN:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    groundMinFilter: payload,
                }),
            });
        case CHANGE_GROUND_MAX:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    groundMaxFilter: payload,
                }),
            });
        case CHANGE_ROOF_MIN:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    roofMinFilter: payload,
                }),
            });
        case CHANGE_ROOF_MAX:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    roofMaxFilter: payload,
                }),
            });
        case CHANGE_TOTAL_MIN:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    totalMinFilter: payload,
                }),
            });
        case CHANGE_TOTAL_MAX:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    totalMaxFilter: payload,
                }),
            });
        case SELECT_ELIGIBLE_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    interestedFilter: false,
                    enrolledFilter: false,
                    eligibleFilter: true,
                }),
            });
        case SELECT_INTERESTED_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    interestedFilter: true,
                    enrolledFilter: false,
                    eligibleFilter: false,
                }),
            });
        case SELECT_ENROLLED_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    enrolledFilter: true,
                    interestedFilter: false,
                    eligibleFilter: false,
                }),
            });
        case CHANGE_SAVED_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    savedPropertiesFilter:
                        !state.parcelFilters.savedPropertiesFilter,
                }),
            });
        case CHANGE_MAP_EXTENT_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    mapExtentFilter: !state.parcelFilters.mapExtentFilter,
                }),
            });
        case CHANGE_NONRES_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    nonResidentialFilter:
                        !state.parcelFilters.nonResidentialFilter,
                }),
            });
        case CHANGE_CONDOS_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    condosFilter: !state.parcelFilters.condosFilter,
                }),
            });
        case CHANGE_CAP_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    capFilter: payload,
                }),
            });
        case CHANGE_GSI_FILTER:
            return Object.assign({}, state, {
                parcelFilters: Object.assign({}, state.parcelFilters, {
                    gsiFilter: payload,
                }),
            });
        case START_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
            });
        case FAIL_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
                errorMessage: errorMessages.failLoadMultipleParcelsMessage,
            });
        case COMPLETE_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                parcels: payload,
                fetching: false,
                error: false,
            });
        case CLEAR_PARCEL_DATA:
            return Object.assign({}, state, {
                parcels: [],
            });
        case COMPLETE_FETCH_SAVED_PARCELS:
            return Object.assign({}, state, {
                savedParcels: payload,
            });
        case COMPLETE_FETCH_INTERESTED_PARCELS:
            return Object.assign({}, state, {
                interestedParcels: payload,
            });
        case COMPLETE_FETCH_ENROLLED_PARCELS:
            return Object.assign({}, state, {
                enrolledParcels: payload,
            });
        case HIGHLIGHT_PARCEL:
            return Object.assign({}, state, {
                highlightedParcel: payload,
            });
        case UNHIGHLIGHT_PARCEL:
            return Object.assign({}, state, {
                highlightedParcel: null,
            });
        case HIDE_ERROR_MODAL:
            return Object.assign({}, state, {
                error: false,
                errorMessage: null,
                csv: Object.assign({}, state.csv, {
                    error: false,
                }),
            });
        case FAIL_FETCH_INTERESTED_PARCELS:
            return Object.assign({}, state, {
                error: true,
                errorMessage: errorMessages.failLoadInterestedParcelsMessage,
            });
        case FAIL_FETCH_SAVED_PARCELS:
            return Object.assign({}, state, {
                error: true,
                errorMessage: errorMessages.failLoadSavedParcelsMessage,
            });
        default:
            return state;
    }
}

const initialDetailsPageState = {
    selectedParcel: null,
    fetching: false,
    error: false,
    errorMessage: null,
    mapExpanded: false,
    parcelIdFromLoginAttempt: null,
};

function detailsPage(state = initialDetailsPageState, { type, payload }) {
    switch (type) {
        case RESET_RETROFIT_MAP:
        case COMPLETE_AIS_GEOCODER_SEARCH:
            return initialDetailsPageState;
        case EXPAND_PROPERTY_DETAILS_MAP:
            return Object.assign({}, state, {
                mapExpanded: true,
            });
        case CONTRACT_PROPERTY_DETAILS_MAP:
            return Object.assign({}, state, {
                mapExpanded: false,
            });
        case START_FETCH_SINGLE_PARCEL:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
            });
        case FAIL_FETCH_SINGLE_PARCEL:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
                errorMessage: errorMessages.failLoadSingleParcelMessage,
            });
        case CANCEL_FETCH_SINGLE_PARCEL:
            return Object.assign({}, state, {
                fetching: false,
            });
        case COMPLETE_FETCH_SINGLE_PARCEL:
            return Object.assign({}, state, {
                fetching: false,
                error: false,
                selectedParcel: payload,
            });
        case DESELECT_PARCEL:
            return Object.assign({}, state, {
                selectedParcel: null,
            });
        case HIDE_ERROR_MODAL:
            return Object.assign({}, state, {
                error: false,
                errorMessage: null,
            });
        case FAIL_DEVELOPER_SIGN_IN:
            return Object.assign({}, state, {
                parcelIdFromLoginAttempt: payload.parcelIdFromLoginAttempt,
            });
        default:
            return state;
    }
}

const initialAuthState = {
    email: '',
    password: '',
    fetching: false,
    signedIn: false,
    id: null,
    error: false,
};

function auth(state = initialAuthState, { type, payload }) {
    switch (type) {
        case RESET_RETROFIT_MAP:
            return initialAuthState;
        case UPDATE_DEVELOPER_AUTH_EMAIL:
            return Object.assign({}, state, {
                email: payload,
            });
        case UPDATE_DEVELOPER_AUTH_PASSWORD:
            return Object.assign({}, state, {
                password: payload,
            });
        case SHOW_SIGN_IN:
            return Object.assign({}, state, {
                error: false,
            });
        case START_DEVELOPER_SIGN_IN:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
                signedIn: false,
            });
        case FAIL_DEVELOPER_SIGN_IN:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
                signedIn: false,
            });
        case COMPLETE_DEVELOPER_SIGN_IN:
            return Object.assign({}, state, {
                fetching: false,
                error: false,
                signedIn: true,
                status: payload.status,
                isStaff: payload.isStaff,
                id:
                    !payload.isStaff &&
                    payload.vendorInfo &&
                    payload.vendorInfo.id,
            });
        case START_DEVELOPER_SIGN_OUT:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
            });
        case FAIL_DEVELOPER_SIGN_OUT:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
            });
        case COMPLETE_DEVELOPER_SIGN_OUT:
            return Object.assign({}, state, {
                fetching: false,
                error: false,
                signedIn: false,
                id: null,
                email: '',
                password: '',
            });
        case COMPLETE_DELETE_ACCOUNT:
            return Object.assign({}, state, {
                status: payload,
            });
        case COMPLETE_RESET_PASSWORD:
            return Object.assign({}, state, {
                password: payload,
            });
        default:
            return state;
    }
}

const initialEmailConfirmationState = {
    fetching: false,
    success: false,
    error: false,
};

function emailConfirmation(state = initialEmailConfirmationState, { type }) {
    switch (type) {
        case RESET_RETROFIT_MAP:
            return initialEmailConfirmationState;
        case START_CONFIRM_EMAIL:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
                success: false,
            });
        case FAIL_CONFIRM_EMAIL:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
                success: false,
            });
        case COMPLETE_CONFIRM_EMAIL:
            return Object.assign({}, state, {
                fetching: false,
                error: false,
                success: true,
            });
        default:
            return state;
    }
}

export default combineReducers({
    mainPage,
    detailsPage,
    onboardingPage,
    auth,
    emailConfirmation,
    adminDashboardPage,
    resetPasswordPage,
    aisGeocoder,
    latLngParcelSearch,
    geocoder: geocoderReducer,
    customers: customersApp,
});
