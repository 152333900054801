import { head, includes, map, noop, round, shuffle, sum, values } from 'lodash';
import { preferredContactTypes } from '../common/constants';

import csrfRequest from '../common/csrfRequest';

import { mapPreferredContact, parcelIsEligible } from '../common/utils';

import { NO_RESULTS_MESSAGE } from '../geocoder/constants';

import {
    benefitFields,
    parcelAPIUrl,
    regulatoryAPIUrl,
    retrofitDevelopersUrl,
    SUBMIT_PROPERTY_MANAGER_FORM_URL,
    SUBSURFACE_STORAGE,
    GREEN_ROOF,
    CISTERN,
    PERMEABLE_PAVEMENT,
    RAIN_GARDEN,
    uiSections,
    defaultParcelImperviousArea,
    apiError,
    makeResendManagerEmailUrl,
    NO_IDEA,
    retrofits as retrofitTypes,
    resendCustomerEmail,
} from './constants';

export const START_FETCH_DEVELOPER_DATA = 'START_FETCH_DEVELOPER_DATA';
export const COMPLETE_FETCH_DEVELOPER_DATA = 'COMPLETE_FETCH_DEVELOPER_DATA';
export const FAIL_FETCH_DEVELOPER_DATA = 'FAIL_FETCH_DEVELOPER_DATA';
export const START_FETCH_PARCEL_DATA = 'START_FETCH_PARCEL_DATA';
export const COMPLETE_FETCH_PARCEL_DATA = 'COMPLETE_FETCH_PARCEL_DATA';
export const FAIL_FETCH_PARCEL_DATA = 'FAIL_FETCH_PARCEL_DATA';
export const START_SUBMIT_CONTACT_FORM = 'START_SUBMIT_CONTACT_FORM';
export const COMPLETE_SUBMIT_CONTACT_FORM = 'COMPLETE_SUBMIT_CONTACT_FORM';
export const FAIL_SUBMIT_CONTACT_FORM = 'FAIL_SUBMIT_CONTACT_FORM';
export const REMOVE_PARCEL = 'REMOVE_PARCEL';
export const TOGGLE_GEOCODER = 'TOGGLE_GEOCODER';
export const UPDATE_SUBMITTING_CONTROL = 'UPDATE_SUBMITTING_CONTROL';
export const UPDATE_SUBMITTED_QUERY = 'UPDATE_SUBMITTED_QUERY';
export const UPDATE_BENEFIT_SLIDER_VALUE = 'UPDATE_BENEFIT_SLIDER_VALUE';
export const UPDATE_BENEFIT_ESTIMATE_VALUE = 'UPDATE_BENEFIT_ESTIMATE_VALUE';
export const START_FETCH_CURRENT_UNIT_CHARGE =
    'START_FETCH_CURRENT_UNIT_CHARGE';
export const FAIL_FETCH_CURRENT_UNIT_CHARGE = 'FAIL_FETCH_CURRENT_UNIT_CHARGE';
export const COMPLETE_FETCH_CURRENT_UNIT_CHARGE =
    'COMPLETE_FETCH_CURRENT_UNIT_CHARGE';
export const SELECT_DEVELOPERS = 'SELECT_DEVELOPERS';
export const DESELECT_DEVELOPERS = 'DESELECT_DEVELOPERS';
export const SHOW_TERMS_OF_USE_MODAL = 'SHOW_TERMS_OF_USE_MODAL';
export const HIDE_TERMS_OF_USE_MODAL = 'HIDE_TERMS_OF_USE_MODAL';
export const START_RESEND_MANAGER_EMAIL = 'START_RESEND_MANAGER_EMAIL';
export const FAIL_RESEND_MANAGER_EMAIL = 'FAIL_RESEND_MANAGER_EMAIL';
export const COMPLETE_RESEND_MANAGER_EMAIL = 'COMPLETE_RESEND_MANAGER_EMAIL';
export const COMPLETE_RESEND_MANAGER_EMAIL_WITHOUT_PARCEL =
    'COMPLETE_RESEND_MANAGER_EMAIL_WITHOUT_PARCEL';
export const AVOID_RESENDING_MANAGER_EMAIL_WITHOUT_PARCEL =
    'AVOID_RESENDING_MANAGER_EMAIL_WITHOUT_PARCEL';
export const SELECT_RETROFITS_FROM_QUERY_STRING =
    'SELECT_RETROFITS_FROM_QUERY_STRING';
export const HIDE_GEOCODER_ON_COMPLETING_PARCEL_SEARCH_FROM_QUERY_STRING =
    'HIDE_GEOCODER_ON_COMPLETING_PARCEL_SEARCH_FROM_QUERY_STRING';
export const CLOSE_CONFIRMATION_PAGE = 'CLOSE_CONFIRMATION_PAGE';
export const SET_SHOW_ALL_DEVELOPERS = 'SET_SHOW_ALL_DEVELOPERS';
export const SET_DEVELOPER_QUERY = 'SET_DEVELOPER_QUERY';

export function closeConfirmationPage() {
    return {
        type: CLOSE_CONFIRMATION_PAGE,
    };
}

function startFetchCurrentUnitCharge() {
    return {
        type: START_FETCH_CURRENT_UNIT_CHARGE,
    };
}

function failFetchCurrentUnitCharge() {
    return {
        type: FAIL_FETCH_CURRENT_UNIT_CHARGE,
    };
}

function completeFetchCurrentUnitCharge(unitCharge) {
    return {
        type: COMPLETE_FETCH_CURRENT_UNIT_CHARGE,
        payload: unitCharge,
    };
}

export function fetchUnitCharge() {
    return dispatch => {
        dispatch(startFetchCurrentUnitCharge());
        csrfRequest
            .get(regulatoryAPIUrl)
            .then(({ data: { ImperviousUnitCharge: unitCharge } }) =>
                dispatch(completeFetchCurrentUnitCharge(unitCharge)),
            )
            .catch(() => dispatch(failFetchCurrentUnitCharge()));
    };
}

function startFetchDeveloperData() {
    return {
        type: START_FETCH_DEVELOPER_DATA,
    };
}

function completeFetchDeveloperData(data) {
    return {
        type: COMPLETE_FETCH_DEVELOPER_DATA,
        payload: data,
    };
}

function failFetchDeveloperData(error) {
    return {
        type: FAIL_FETCH_DEVELOPER_DATA,
        payload: error,
    };
}

function reformatRetrofits(retrofits) {
    return Object.entries(retrofits)
        .filter(([, value]) => value)
        .map(([retrofit]) => retrofit);
}

function formatAPIRetrofitDeveloperData(data) {
    return data.map(
        ({
            id,
            company_name,
            company_website,
            company_email,
            company_phone,
            prefer_email,
            prefer_phone,
            green_roof,
            cistern,
            permeable_pavement,
            rain_garden,
            subsurface_storage,
            has_worked_on_grant,
            has_been_subgrantee,
            other_retrofit,
            is_design_firm,
            is_construction_firm,
            is_maintenance_firm,
            is_management_firm,
        }) => ({
            id,
            name: company_name,
            url: company_website,
            email: company_email,
            phoneNumber: company_phone,
            preferredContact: mapPreferredContact({
                email: prefer_email,
                phone: prefer_phone,
            }),
            hasWorkedOnGrant: has_worked_on_grant,
            hasBeenSubgrantee: has_been_subgrantee,
            retrofits: reformatRetrofits({
                green_roof,
                cistern,
                permeable_pavement,
                rain_garden,
                subsurface_storage,
            }),
            isDesignFirm: is_design_firm,
            isConstructionFirm: is_construction_firm,
            isMaintenanceFirm: is_maintenance_firm,
            isManagementFirm: is_management_firm,
            otherRetrofit: other_retrofit,
        }),
    );
}

export function fetchDeveloperData() {
    return dispatch => {
        dispatch(startFetchDeveloperData());
        csrfRequest
            .get(retrofitDevelopersUrl)
            .then(({ data }) => formatAPIRetrofitDeveloperData(data))
            .then(data => shuffle(data))
            .then(data => dispatch(completeFetchDeveloperData(data)))
            .catch(() => dispatch(failFetchDeveloperData('An error occurred')));
    };
}

function startFetchParcelData() {
    return {
        type: START_FETCH_PARCEL_DATA,
    };
}

function hideGeocoderOnCompletingParcelSearchFromQueryString() {
    return {
        type: HIDE_GEOCODER_ON_COMPLETING_PARCEL_SEARCH_FROM_QUERY_STRING,
    };
}

function completeFetchParcelData(data, fromQueryString) {
    return dispatch => {
        if (fromQueryString) {
            dispatch(hideGeocoderOnCompletingParcelSearchFromQueryString());
        }

        return dispatch({
            type: COMPLETE_FETCH_PARCEL_DATA,
            payload: data,
        });
    };
}

function failFetchParcelData(error) {
    window.console.warn(error);

    return {
        type: FAIL_FETCH_PARCEL_DATA,
        payload: NO_RESULTS_MESSAGE,
    };
}

function calculateAggregatedSavings(annualSavings, timeHorizon) {
    return round(annualSavings * timeHorizon, 0);
}

function calculateWaterCredits(parcels, retrofitScope, unitCharge) {
    const isDefaultParcel = !parcels.length;

    function roundUpToNearestFiveHundredSqFt(area) {
        return Math.ceil(area / 500) * 500;
    }

    function getBillingUnits(roundedArea) {
        return roundedArea / 500;
    }

    function multiplyByUnitCharge(billingUnits) {
        return billingUnits * unitCharge;
    }

    const imperviousArea = isDefaultParcel
        ? defaultParcelImperviousArea
        : sum(map(parcels, parcel => parcel.Parcel.ImpervArea));

    const reducedImperviousArea =
        imperviousArea - imperviousArea * retrofitScope;
    const currentAreaCharges = multiplyByUnitCharge(
        getBillingUnits(roundUpToNearestFiveHundredSqFt(imperviousArea)),
    );
    const adjustedAreaCharges = multiplyByUnitCharge(
        getBillingUnits(roundUpToNearestFiveHundredSqFt(reducedImperviousArea)),
    );

    return (currentAreaCharges - adjustedAreaCharges) * 12;
}

export function updateBenefitEstimateValue(field, value) {
    return {
        type: UPDATE_BENEFIT_ESTIMATE_VALUE,
        payload: { field, value },
    };
}

export function updateBenefitFields() {
    return (dispatch, getState) => {
        const {
            customers: {
                main: {
                    currentUnitCharge,
                    parcel: { data: parcelData },
                    userInput: { benefitEstimateParameters },
                },
            },
        } = getState();

        const waterBillCreditValue = calculateWaterCredits(
            parcelData,
            benefitEstimateParameters[benefitFields.RetrofitScope],
            currentUnitCharge,
        );

        const maintenanceValue = waterBillCreditValue * 0.2;
        const annualSavingsValue = waterBillCreditValue - maintenanceValue;

        const aggregatedSavingsValue = calculateAggregatedSavings(
            annualSavingsValue,
            benefitEstimateParameters[benefitFields.TimeHorizon],
        );

        dispatch(
            updateBenefitEstimateValue(
                benefitFields.Maintenance,
                maintenanceValue,
            ),
        );

        dispatch(
            updateBenefitEstimateValue(
                benefitFields.WaterBillCredits,
                waterBillCreditValue,
            ),
        );

        dispatch(
            updateBenefitEstimateValue(
                benefitFields.AnnualSavings,
                annualSavingsValue,
            ),
        );

        dispatch(
            updateBenefitEstimateValue(
                benefitFields.AggregatedSavings,
                aggregatedSavingsValue,
            ),
        );

        return noop();
    };
}

function eitherGetEligibleParcelOrThrow(data) {
    if (!parcelIsEligible(data)) {
        // throw an error to be caught inside a Promise
        throw new Error('No eligible parcel found');
    }

    return data;
}

export function fetchParcelData(parcelId, fromQueryString = false) {
    return dispatch => {
        dispatch(startFetchParcelData());

        if (fromQueryString) {
            // Immediately fail search id parcel query parameter is not an integer
            if (Number.isNaN(parseInt(parcelId, 10))) {
                return dispatch(
                    failFetchParcelData('Invalid parcel query parameter value'),
                );
            }
        }

        return csrfRequest
            .get(parcelAPIUrl(parcelId))
            .then(({ data }) => eitherGetEligibleParcelOrThrow(data))
            .then(data =>
                dispatch(completeFetchParcelData(data, fromQueryString)),
            )
            .then(() => dispatch(updateBenefitFields()))
            .catch(error => dispatch(failFetchParcelData(error)));
    };
}

function startSubmitContactForm(data) {
    return {
        type: START_SUBMIT_CONTACT_FORM,
        payload: data,
    };
}

function completeSubmitContactForm(payload) {
    return {
        type: COMPLETE_SUBMIT_CONTACT_FORM,
        payload,
    };
}

function failSubmitContactForm({ data = apiError }) {
    return (dispatch, getState) => {
        const {
            customers: {
                main: {
                    formSubmission: { errors },
                },
            },
        } = getState();

        return dispatch({
            type: FAIL_SUBMIT_CONTACT_FORM,
            payload: Object.assign({}, errors, {
                email: data.email || [],
                phone: data.phone || [],
                parcels: data.parcels || [],
                apiError: data.apiError || [],
            }),
        });
    };
}

function formatPropertyManagerFormSubmission(
    {
        name,
        email,
        phone,
        note,
        contactPreference,
        parcels,
        selectedPreferences,
        acceptsContacts,
    },
    developers,
) {
    return {
        name,
        email,
        phone,
        note,
        prefer_email: [
            preferredContactTypes.email,
            preferredContactTypes.both,
        ].includes(contactPreference),
        prefer_phone: [
            preferredContactTypes.telephone,
            preferredContactTypes.both,
        ].includes(contactPreference),
        selected_developers: developers,
        parcels: parcels.map(
            /* eslint-disable camelcase */
            ({
                Parcel: {
                    ParcelID: id,
                    Address: address,
                    BldgType: building_type,
                },
            }) => ({ id, address, building_type }),
            /* eslint-enable camelcase */
        ),
        rain_garden: selectedPreferences.includes(RAIN_GARDEN),
        green_roof: selectedPreferences.includes(GREEN_ROOF),
        subsurface_storage: selectedPreferences.includes(SUBSURFACE_STORAGE),
        cistern: selectedPreferences.includes(CISTERN),
        permeable_pavement: selectedPreferences.includes(PERMEABLE_PAVEMENT),
        accepts_contacts: acceptsContacts,
    };
}

export function submitContactForm(data) {
    return (dispatch, getState) => {
        const {
            customers: {
                main: {
                    userInput: { developers },
                },
            },
        } = getState();

        dispatch(startSubmitContactForm(data));

        csrfRequest
            .post(
                SUBMIT_PROPERTY_MANAGER_FORM_URL,
                formatPropertyManagerFormSubmission(data, developers),
            )
            .then(({ data: { unsubscribe_token: unsubscribeToken } }) =>
                dispatch(completeSubmitContactForm(unsubscribeToken)),
            )
            .catch(({ response = {} }) =>
                dispatch(failSubmitContactForm(response)),
            );
    };
}

export function removeParcel(parcelId) {
    return {
        type: REMOVE_PARCEL,
        payload: parcelId,
    };
}

export function toggleGeocoder(section) {
    return dispatch => {
        if (includes(values(uiSections), section)) {
            return dispatch({
                type: TOGGLE_GEOCODER,
                payload: section,
            });
        }
        return noop();
    };
}

export function updateSubmittingControl(section) {
    return {
        type: UPDATE_SUBMITTING_CONTROL,
        payload: section,
    };
}

export function updateSubmittedQuery(query) {
    return {
        type: UPDATE_SUBMITTED_QUERY,
        payload: query,
    };
}

export function updateBenefitSliderValue(field, value) {
    return dispatch => {
        if (includes(values(benefitFields), field)) {
            dispatch({
                type: UPDATE_BENEFIT_SLIDER_VALUE,
                payload: { field, value },
            });

            dispatch(updateBenefitFields());
        }

        return noop();
    };
}

export function selectDevelopers(payload) {
    return {
        type: SELECT_DEVELOPERS,
        payload,
    };
}

export function deselectDevelopers(payload) {
    return {
        type: DESELECT_DEVELOPERS,
        payload,
    };
}

export function adjustDeveloperSelectionForRetrofitChange(retrofitPreferences) {
    return (dispatch, getState) => {
        if (retrofitPreferences.includes(NO_IDEA)) {
            return null;
        }

        const {
            customers: {
                main: {
                    developers: { data: developerData },
                    userInput: { developers: selectedDevelopers },
                },
            },
        } = getState();

        const newlyDeslectedDevelopers = selectedDevelopers.reduce(
            (accumulator, selectedDeveloperId) => {
                const { retrofits } = developerData.find(
                    ({ id }) => id === selectedDeveloperId,
                );

                return !retrofits.filter(r => retrofitPreferences.includes(r))
                    .length
                    ? accumulator.concat(selectedDeveloperId)
                    : accumulator;
            },
            [],
        );

        return dispatch(deselectDevelopers(newlyDeslectedDevelopers));
    };
}

export function showTermsOfUseModal() {
    return {
        type: SHOW_TERMS_OF_USE_MODAL,
    };
}

export function hideTermsOfUseModal() {
    return {
        type: HIDE_TERMS_OF_USE_MODAL,
    };
}

function startResendManagerEmail() {
    return {
        type: START_RESEND_MANAGER_EMAIL,
    };
}

function failResendManagerEmail(e) {
    window.console.warn(e);

    return {
        type: FAIL_RESEND_MANAGER_EMAIL,
    };
}

function completeResendManagerEmail(payload) {
    return {
        type: COMPLETE_RESEND_MANAGER_EMAIL,
        payload,
    };
}

function completeResendManagerEmailWithoutParcel(payload) {
    return {
        type: COMPLETE_RESEND_MANAGER_EMAIL_WITHOUT_PARCEL,
        payload,
    };
}

export function avoidResendingManagerEmailWithoutParcel() {
    return {
        type: AVOID_RESENDING_MANAGER_EMAIL_WITHOUT_PARCEL,
    };
}

export function resendManagerEmail(parcelId) {
    return dispatch => {
        dispatch(startResendManagerEmail());

        return csrfRequest
            .post(makeResendManagerEmailUrl(parcelId))
            .then(() => dispatch(completeResendManagerEmail(parcelId)))
            .catch(e => dispatch(failResendManagerEmail(e)));
    };
}

export function resendManagerEmailWithoutParcel(email) {
    return dispatch => {
        dispatch(startResendManagerEmail());

        return csrfRequest
            .post(resendCustomerEmail, {
                email,
            })
            .then(() =>
                dispatch(completeResendManagerEmailWithoutParcel(email)),
            )
            .catch(e => dispatch(failResendManagerEmail(e)));
    };
}

export function selectRetrofitsFromQueryString(retrofits) {
    const payload = Object.entries(retrofits)
        .filter(([, value]) => {
            if (Array.isArray(value)) {
                return head(value).toLowerCase() === 'true';
            }

            return value.toLowerCase() === 'true';
        })
        .map(([r]) => r)
        .filter(r => retrofitTypes.map(({ type }) => type).includes(r));

    return {
        type: SELECT_RETROFITS_FROM_QUERY_STRING,
        payload,
    };
}

export function setShowAllDevelopers(value) {
    return {
        type: SET_SHOW_ALL_DEVELOPERS,
        payload: value,
    };
}

export function setDeveloperQuery(value) {
    return {
        type: SET_DEVELOPER_QUERY,
        payload: value,
    };
}
