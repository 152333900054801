import {
    START_GEOCODER_SEARCH,
    RECEIVE_GEOCODER_RESULTS,
    SELECT_GEOCODER_RESULT,
    FAIL_GEOCODER_SEARCH,
    CLEAR_GEOCODER_SEARCH,
} from './actions';

const initialState = {
    selectedResult: null,
    results: null,
    fetching: false,
    error: null,
};

export default function geocoderReducer(
    state = initialState,
    { type, payload },
) {
    switch (type) {
        case START_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                selectedResult: null,
                results: null,
                fetching: true,
                error: null,
            });
        case RECEIVE_GEOCODER_RESULTS:
            return Object.assign({}, state, {
                selectedResult: null,
                results: payload,
                fetching: false,
                error: null,
            });
        case FAIL_GEOCODER_SEARCH: {
            return Object.assign({}, state, {
                selectedResult: null,
                results: null,
                fetching: false,
                error: payload,
            });
        }
        case SELECT_GEOCODER_RESULT:
            return Object.assign({}, state, {
                selectedResult: payload,
                results: null,
                fetching: false,
                error: null,
            });
        case CLEAR_GEOCODER_SEARCH:
            return initialState;
        default:
            return state;
    }
}
