import { combineReducers } from 'redux';

import { concat, uniqBy, without } from 'lodash';

import preferencesReducer from './components/parcel_preferences/reducer';
import geocoderReducer from '../geocoder/reducers';

import {
    START_GEOCODER_SEARCH,
    SELECT_GEOCODER_RESULT,
} from '../geocoder/actions';

import {
    START_FETCH_DEVELOPER_DATA,
    COMPLETE_FETCH_DEVELOPER_DATA,
    FAIL_FETCH_DEVELOPER_DATA,
    START_FETCH_PARCEL_DATA,
    COMPLETE_FETCH_PARCEL_DATA,
    FAIL_FETCH_PARCEL_DATA,
    START_SUBMIT_CONTACT_FORM,
    COMPLETE_SUBMIT_CONTACT_FORM,
    FAIL_SUBMIT_CONTACT_FORM,
    REMOVE_PARCEL,
    TOGGLE_GEOCODER,
    UPDATE_SUBMITTING_CONTROL,
    UPDATE_SUBMITTED_QUERY,
    UPDATE_BENEFIT_SLIDER_VALUE,
    UPDATE_BENEFIT_ESTIMATE_VALUE,
    FAIL_FETCH_CURRENT_UNIT_CHARGE,
    COMPLETE_FETCH_CURRENT_UNIT_CHARGE,
    SELECT_DEVELOPERS,
    DESELECT_DEVELOPERS,
    SHOW_TERMS_OF_USE_MODAL,
    HIDE_TERMS_OF_USE_MODAL,
    START_RESEND_MANAGER_EMAIL,
    FAIL_RESEND_MANAGER_EMAIL,
    COMPLETE_RESEND_MANAGER_EMAIL,
    COMPLETE_RESEND_MANAGER_EMAIL_WITHOUT_PARCEL,
    AVOID_RESENDING_MANAGER_EMAIL_WITHOUT_PARCEL,
    SELECT_RETROFITS_FROM_QUERY_STRING,
    HIDE_GEOCODER_ON_COMPLETING_PARCEL_SEARCH_FROM_QUERY_STRING,
    CLOSE_CONFIRMATION_PAGE,
    SET_SHOW_ALL_DEVELOPERS,
    SET_DEVELOPER_QUERY,
} from './actions';

import { benefitFields, defaultUnitCharge, uiSections } from './constants';

const initialState = {
    currentUnitCharge: defaultUnitCharge,
    parcel: {
        data: [],
        fetching: false,
        error: null,
    },
    developers: {
        data: null,
        fetching: false,
        error: null,
    },
    userInput: {
        retrofitsFromQueryString: [],
        developers: [],
        contactInfo: {
            name: null,
            email: null,
            phone: null,
            preference: null,
        },
        benefitEstimateParameters: {
            [benefitFields.RetrofitScope]: 1.0,
            [benefitFields.TimeHorizon]: 10,
        },
    },
    formSubmission: {
        data: null,
        posting: false,
        errors: {
            email: [],
            phone: [],
            parcels: [],
            apiError: [],
        },
    },
    ui: {
        geocoderQuery: null,
        submittingControl: null,
        [uiSections.Hero]: {
            showGeocoder: true,
        },
        [uiSections.ParcelForm]: {
            showGeocoder: true,
        },
        [uiSections.PreferencesForm]: {
            showGeocoder: false,
        },
        [uiSections.Benefits]: {
            [benefitFields.Maintenance]: 0,
            [benefitFields.WaterBillCredits]: 0,
            [benefitFields.AnnualSavings]: 0,
            [benefitFields.AggregatedSavings]: 0,
        },
        showConfirmationPage: false,
        unsubscribeToken: null,
        displayTermsOfUseModal: false,
        showAllDevelopers: false,
        developersQuery: '',
    },
    resentEmailParcels: [],
    emailExists: false,
    resendingParcelEmail: false,
};

function mainReducer(state = initialState, { type, payload }) {
    switch (type) {
        case COMPLETE_FETCH_CURRENT_UNIT_CHARGE: {
            return Object.assign({}, state, {
                currentUnitCharge: payload,
            });
        }
        case FAIL_FETCH_CURRENT_UNIT_CHARGE: {
            return Object.assign({}, state, {
                currentUnitCharge: defaultUnitCharge,
            });
        }
        case START_FETCH_DEVELOPER_DATA:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    data: null,
                    fetching: true,
                    error: null,
                }),
            });
        case COMPLETE_FETCH_DEVELOPER_DATA:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    data: payload,
                    fetching: false,
                    error: null,
                }),
            });
        case FAIL_FETCH_DEVELOPER_DATA:
            return Object.assign({}, state, {
                developers: Object.assign({}, state.developers, {
                    data: null,
                    fetching: false,
                    error: payload,
                }),
            });
        case START_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                parcel: Object.assign({}, state.parcel, {
                    fetching: true,
                    error: null,
                }),
            });
        case COMPLETE_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                parcel: Object.assign({}, state.parcel, {
                    data: concat(state.parcel.data, payload),
                    fetching: false,
                    error: null,
                }),
            });
        case FAIL_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                parcel: Object.assign({}, state.parcel, {
                    fetching: false,
                    error: payload,
                }),
            });
        case START_SUBMIT_CONTACT_FORM:
            return Object.assign({}, state, {
                formSubmission: Object.assign({}, state.formSubmission, {
                    data: payload,
                    fetching: true,
                    errors: initialState.formSubmission.errors,
                }),
                emailExists: false,
            });
        case COMPLETE_SUBMIT_CONTACT_FORM:
            return Object.assign({}, state, {
                formSubmission: Object.assign({}, state.formSubmission, {
                    fetching: false,
                    errors: initialState.formSubmission.errors,
                }),
                ui: Object.assign({}, state.ui, {
                    showConfirmationPage: true,
                    unsubscribeToken: payload,
                }),
            });
        case CLOSE_CONFIRMATION_PAGE:
            return Object.assign({}, state, {
                parcel: initialState.parcel,
                userInput: initialState.userInput,
                formSubmission: initialState.formSubmission,
                ui: initialState.ui,
            });
        case FAIL_SUBMIT_CONTACT_FORM:
            return Object.assign({}, state, {
                formSubmission: Object.assign({}, state.formSubmission, {
                    fetching: false,
                    errors: payload,
                }),
            });
        case SHOW_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    displayTermsOfUseModal: true,
                }),
            });
        case HIDE_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    displayTermsOfUseModal: false,
                }),
            });
        case SELECT_GEOCODER_RESULT:
            return Object.assign({}, state, {
                parcel: Object.assign({}, state.parcel, {
                    data: uniqBy(
                        concat(state.parcel.data, payload),
                        p => p.Parcel.ParcelID,
                    ),
                    fetching: false,
                    error: null,
                }),
                ui: Object.assign({}, state.ui, {
                    geocoderQuery: null,
                    submittingControl: null,
                    [uiSections.Hero]: Object.assign(
                        {},
                        state.ui[uiSections.Hero],
                        {
                            showGeocoder: false,
                        },
                    ),
                    [uiSections.ParcelForm]: Object.assign(
                        {},
                        state.ui[uiSections.Hero],
                        {
                            showGeocoder: false,
                        },
                    ),
                    [uiSections.ParcelForm]: Object.assign(
                        {},
                        state.ui[uiSections.PreferencesForm],
                        {
                            showGeocoder: false,
                        },
                    ),
                }),
            });
        case REMOVE_PARCEL:
            return Object.assign({}, state, {
                parcel: Object.assign({}, state.parcel, {
                    data: without(state.parcel.data, payload),
                }),
                formSubmission: initialState.formSubmission,
            });
        case TOGGLE_GEOCODER:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    [payload]: Object.assign({}, state.ui[payload], {
                        showGeocoder: !state.ui[payload].showGeocoder,
                    }),
                }),
            });
        case UPDATE_BENEFIT_SLIDER_VALUE:
            return Object.assign({}, state, {
                userInput: Object.assign({}, state.userInput, {
                    benefitEstimateParameters: Object.assign(
                        {},
                        state.userInput.benefitEstimateParameters,
                        {
                            [payload.field]: payload.value,
                        },
                    ),
                }),
            });
        case UPDATE_BENEFIT_ESTIMATE_VALUE:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    [uiSections.Benefits]: Object.assign(
                        {},
                        state.ui[uiSections.Benefits],
                        {
                            [payload.field]: payload.value,
                        },
                    ),
                }),
            });
        case UPDATE_SUBMITTING_CONTROL:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    submittingControl: payload,
                }),
            });
        case UPDATE_SUBMITTED_QUERY:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    geocoderQuery: payload,
                }),
            });
        case SELECT_DEVELOPERS: {
            return Object.assign({}, state, {
                userInput: Object.assign({}, state.userInput, {
                    developers: [
                        ...new Set(state.userInput.developers.concat(payload)),
                    ],
                }),
            });
        }
        case DESELECT_DEVELOPERS:
            return Object.assign({}, state, {
                userInput: Object.assign({}, state.userInput, {
                    developers: [
                        ...new Set(
                            state.userInput.developers.filter(
                                id => !payload.includes(id),
                            ),
                        ),
                    ],
                }),
            });
        case START_RESEND_MANAGER_EMAIL:
            return Object.assign({}, state, {
                resendingParcelEmail: true,
            });
        case FAIL_RESEND_MANAGER_EMAIL:
            return Object.assign({}, state, {
                resendingParcelEmail: false,
            });
        case COMPLETE_RESEND_MANAGER_EMAIL:
            return Object.assign({}, state, {
                resentEmailParcels: state.resentEmailParcels.concat(payload),
                resendingParcelEmail: false,
            });
        case COMPLETE_RESEND_MANAGER_EMAIL_WITHOUT_PARCEL:
            return Object.assign({}, state, {
                emailExists: true,
                resendingParcelEmail: false,
            });
        case AVOID_RESENDING_MANAGER_EMAIL_WITHOUT_PARCEL:
            return Object.assign({}, state, {
                formSubmission: Object.assign({}, state.formSubmission, {
                    errors: Object.assign({}, state.formSubmission.errors, {
                        email: [],
                    }),
                }),
                emailExists: false,
            });
        case SELECT_RETROFITS_FROM_QUERY_STRING:
            return Object.assign({}, state, {
                userInput: Object.assign({}, state.userInput, {
                    retrofitsFromQueryString: payload,
                }),
            });
        case HIDE_GEOCODER_ON_COMPLETING_PARCEL_SEARCH_FROM_QUERY_STRING:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    [uiSections.Hero]: Object.assign(
                        {},
                        state.ui[uiSections.Hero],
                        {
                            showGeocoder: false,
                        },
                    ),
                    [uiSections.ParcelForm]: Object.assign(
                        {},
                        state.ui[uiSections.ParcelForm],
                        {
                            showGeocoder: false,
                        },
                    ),
                    [uiSections.ParcelForm]: Object.assign(
                        {},
                        state.ui[uiSections.PreferencesForm],
                        {
                            showGeocoder: false,
                        },
                    ),
                }),
            });
        case START_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                parcel: Object.assign({}, state.parcel, {
                    error: null,
                }),
            });
        case SET_SHOW_ALL_DEVELOPERS:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    showAllDevelopers: payload,
                }),
            });
        case SET_DEVELOPER_QUERY:
            return Object.assign({}, state, {
                ui: Object.assign({}, state.ui, {
                    developersQuery: payload,
                }),
            });
        default:
            return state;
    }
}

export default combineReducers({
    main: mainReducer,
    preferences: preferencesReducer,
    geocoder: geocoderReducer,
});
