import { isCancel, CancelToken } from 'axios';
import L from 'leaflet';
import { noop } from 'lodash';
import querystring from 'querystring';

import csrfRequest from '../common/csrfRequest';
import selectEligibleParcels from '../common/utils';

import {
    maybeCancelPriorAISGeocoderRequest,
    clearAISGeocoderData,
    updateAISGeocoderFitBounds,
} from './components/aisGeocoder/actions';

import { completeVendorSignIn } from './components/onboarding/actions';

import { resetLatLngParcelSearch } from './components/latLngParcelSearch/actions';

import {
    parcelsAPIUrl,
    parcelDataApiUrl,
    retrofitMapLoginUrl,
    retrofitMapLogoutUrl,
    savedParcelsUrl,
    interestedParcelsUrl,
    enrolledParcelsUrl,
    changeParcelSaveStatusUrl,
    confirmEmailUrl,
    basemapsEnum,
    defaultRequestedParcelsCount,
    ROUTES,
    API_BASE_URL,
    developerStatusTypes,
} from './constants';

import {
    convertGeoJsonToAgsObject,
    calculateRequestedParcelIDs,
    getCityLimitsBbox,
    parcelIdFromHistory,
} from './utils';

import CSVMaker from './CSVMaker';

export const RESET_RETROFIT_MAP = 'RESET_RETROFIT_MAP';
export const CANCEL_FETCH_SINGLE_PARCEL = 'CANCEL_FETCH_SINGLE_PARCEL';
export const CANCEL_FETCH_PARCEL_DATA = 'CANCEL_FETCH_PARCEL_DATA';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';
export const SHOW_TERMS_OF_USE_MODAL = 'SHOW_TERMS_OF_USE_MODAL';
export const HIDE_TERMS_OF_USE_MODAL = 'HIDE_TERMS_OF_USE_MODAL';
export const SHOW_DELETE_ACCOUNT_MODAL = 'SHOW_DELETE_ACCOUNT_MODAL';
export const HIDE_DELETE_ACCOUNT_MODAL = 'HIDE_DELETE_ACCOUNT_MODAL';
export const RESET_DELETE_ACCOUNT_STATUS = 'RESET_DELETE_ACCOUNT_STATUS';
export const COMPLETE_DELETE_ACCOUNT = 'COMPLETE_DELETE_ACCOUNT';
export const FAIL_DELETE_ACCOUNT_STATUS = 'FAIL_DELETE_ACCOUNT_STATUS';
export const START_FETCH_SINGLE_PARCEL = 'START_FETCH_SINGLE_PARCEL';
export const COMPLETE_FETCH_SINGLE_PARCEL = 'COMPLETE_FETCH_SINGLE_PARCEL';
export const FAIL_FETCH_SINGLE_PARCEL = 'FAIL_FETCH_SINGLE_PARCEL';
export const START_FETCH_INTERESTED_PARCELS = 'START_FETCH_INTERESTED_PARCELS';
export const COMPLETE_FETCH_INTERESTED_PARCELS =
    'COMPLETE_FETCH_INTERESTED_PARCELS';
export const FAIL_FETCH_INTERESTED_PARCELS = 'FAIL_FETCH_INTERESTED_PARCELS';
export const START_FETCH_ENROLLED_PARCELS = 'START_FETCH_ENROLLED_PARCELS';
export const COMPLETE_FETCH_ENROLLED_PARCELS =
    'COMPLETE_FETCH_ENROLLED_PARCELS';
export const FAIL_FETCH_ENROLLED_PARCELS = 'FAIL_FETCH_ENROLLED_PARCELS';
export const START_FETCH_PARCEL_DATA = 'START_FETCH_PARCEL_DATA';
export const COMPLETE_FETCH_PARCEL_DATA = 'COMPLETE_FETCH_PARCEL_DATA';
export const CLEAR_PARCEL_DATA = 'CLEAR_PARCEL_DATA';
export const FAIL_FETCH_PARCEL_DATA = 'FAIL_FETCH_PARCEL_DATA';
export const START_FETCH_SAVED_PARCELS = 'START_FETCH_SAVED_PARCELS';
export const COMPLETE_FETCH_SAVED_PARCELS = 'COMPLETE_FETCH_SAVED_PARCELS';
export const FAIL_FETCH_SAVED_PARCELS = 'FAIL_FETCH_SAVED_PARCELS';
export const START_SAVE_PARCEL = 'START_SAVE_PARCEL';
export const COMPLETE_SAVE_PARCEL = 'COMPLETE_SAVE_PARCEL';
export const FAIL_SAVE_PARCEL = 'FAIL_SAVE_PARCEL';
export const START_UNSAVE_PARCEL = 'START_UNSAVE_PARCEL';
export const COMPLETE_UNSAVE_PARCEL = 'COMPLETE_UNSAVE_PARCEL';
export const FAIL_UNSAVE_PARCEL = 'FAIL_UNSAVE_PARCEL';
export const UPDATE_MAP = 'UPDATE_MAP';
export const SELECT_PARCEL = 'SELECT_PARCEL';
export const DESELECT_PARCEL = 'DESELECT_PARCEL';
export const HIGHLIGHT_PARCEL = 'HIGHLIGHT_PARCEL';
export const UNHIGHLIGHT_PARCEL = 'UNHIGHLIGHT_PARCEL';

export const SHOW_IMPERVIOUS_LAYER = 'SHOW_IMPERVIOUS_LAYER';
export const HIDE_IMPERVIOUS_LAYER = 'HIDE_IMPERVIOUS_LAYER';
export const SHOW_SEWER_LAYER = 'SHOW_SEWER_LAYER';
export const HIDE_SEWER_LAYER = 'HIDE_SEWER_LAYER';
export const SHOW_CARTO_BASEMAP = 'SHOW_CARTO_BASEMAP';
export const SHOW_AERIAL_BASEMAP = 'SHOW_AERIAL_BASEMAP';

export const CHANGE_PARCELS_QUERY_BBOX = 'CHANGE_PARCELS_QUERY_BBOX';

export const CHANGE_GROUND_MIN = 'CHANGE_GROUND_MIN';
export const CHANGE_GROUND_MAX = 'CHANGE_GROUND_MAX';
export const CHANGE_ROOF_MIN = 'CHANGE_ROOF_MIN';
export const CHANGE_ROOF_MAX = 'CHANGE_ROOF_MAX';
export const CHANGE_TOTAL_MIN = 'CHANGE_TOTAL_MIN';
export const CHANGE_TOTAL_MAX = 'CHANGE_TOTAL_MAX';

export const CHANGE_NONRES_FILTER = 'CHANGE_NONRES_FILTER';
export const CHANGE_CONDOS_FILTER = 'CHANGE_CONDOS_FILTER';
export const CHANGE_CAP_FILTER = 'CHANGE_CAP_FILTER';
export const CHANGE_GSI_FILTER = 'CHANGE_GSI_FILTER';
export const CHANGE_SAVED_FILTER = 'CHANGE_SAVED_FILTER';
export const CHANGE_MAP_EXTENT_FILTER = 'CHANGE_MAP_EXTENT_FILTER';
export const SELECT_INTERESTED_FILTER = 'SELECT_INTERESTED_FILTER';
export const SELECT_ELIGIBLE_FILTER = 'SELECT_ELIGIBLE_FILTER';
export const SELECT_ENROLLED_FILTER = 'SELECT_ENROLLED_FILTER';

export const TOGGLE_DETAILS = 'TOGGLE_DETAILS';
export const OPEN_ROOF_FILTER = 'OPEN_ROOF_FILTER';
export const OPEN_GROUND_FILTER = 'OPEN_GROUND_FILTER';
export const OPEN_TOTAL_FILTER = 'OPEN_TOTAL_FILTER';
export const OPEN_STATUS_FILTER = 'OPEN_STATUS_FILTER';
export const OPEN_CHARACTERISTICS_FILTER = 'OPEN_CHARACTERISTICS_FILTER';
export const CLOSE_ALL_FILTERS = 'CLOSE_ALL_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const REFETCH_ON_EMPTY_QUERY = 'REFETCH_ON_EMPTY_QUERY';

export const SHOW_SIGN_IN = 'SHOW_SIGN_IN';
export const START_DEVELOPER_SIGN_IN = 'START_DEVELOPER_SIGN_IN';
export const COMPLETE_DEVELOPER_SIGN_IN = 'COMPLETE_DEVELOPER_SIGN_IN';
export const FAIL_DEVELOPER_SIGN_IN = 'FAIL_DEVELOPER_SIGN_IN';
export const START_DEVELOPER_SIGN_OUT = 'START_DEVELOPER_SIGN_OUT';
export const COMPLETE_DEVELOPER_SIGN_OUT = 'COMPLETE_DEVELOPER_SIGN_OUT';
export const FAIL_DEVELOPER_SIGN_OUT = 'FAIL_DEVELOPER_SIGN_OUT';

export const UPDATE_DEVELOPER_AUTH_EMAIL = 'UPDATE_DEVELOPER_AUTH_EMAIL';
export const UPDATE_DEVELOPER_AUTH_PASSWORD = 'UPDATE_DEVELOPER_AUTH_PASSWORD';

export const START_FETCH_CSV = 'START_FETCH_CSV';
export const FAIL_FETCH_CSV = 'FAIL_FETCH_CSV';
export const COMPLETE_FETCH_CSV = 'COMPLETE_FETCH_CSV';

export const START_CONFIRM_EMAIL = 'START_CONFIRM_EMAIL';
export const COMPLETE_CONFIRM_EMAIL = 'COMPLETE_CONFIRM_EMAIL';
export const FAIL_CONFIRM_EMAIL = 'FAIL_CONFIRM_EMAIL';
const REDUX_NO_OP = 'REDUX_NO_OP';

export const EXPAND_PROPERTY_SEARCH_MAP = 'EXPAND_PROPERTY_SEARCH_MAP';
export const CONTRACT_PROPERTY_SEARCH_MAP = 'CONTRACT_PROPERTY_SEARCH_MAP';

export const EXPAND_PROPERTY_DETAILS_MAP = 'EXPAND_PROPERTY_DETAILS_MAP';
export const CONTRACT_PROPERTY_DETAILS_MAP = 'CONTRACT_PROPERTY_DETAILS_MAP';

const CANCEL_REQUEST_MESSAGE = 'API request was canceled';

let cancelAxiosRequest = null;

function cancelPriorRequest() {
    if (cancelAxiosRequest) {
        cancelAxiosRequest(CANCEL_REQUEST_MESSAGE);
        cancelAxiosRequest = null;
    }
}

export function startSaveParcel(parcel) {
    return {
        type: START_SAVE_PARCEL,
        payload: parcel,
    };
}

export function completeSaveParcel(parcels) {
    return {
        type: COMPLETE_SAVE_PARCEL,
        payload: parcels,
    };
}

export function failSaveParcel(parcel) {
    return {
        type: FAIL_SAVE_PARCEL,
        payload: parcel,
    };
}

export function saveParcel(parcel) {
    return dispatch => {
        dispatch(startSaveParcel(parcel));
        csrfRequest
            .post(changeParcelSaveStatusUrl(parcel))
            .then(({ data }) => {
                dispatch(completeSaveParcel(data));
            })
            .catch(() => dispatch(failSaveParcel(parcel)));

        return noop();
    };
}

export function startUnsaveParcel(parcel) {
    return {
        type: START_UNSAVE_PARCEL,
        payload: parcel,
    };
}

export function completeUnsaveParcel(parcelIds) {
    return {
        type: COMPLETE_UNSAVE_PARCEL,
        payload: parcelIds,
    };
}

export function failUnsaveParcel(parcel) {
    return {
        type: FAIL_UNSAVE_PARCEL,
        payload: parcel,
    };
}

export function unsaveParcel(parcel) {
    return dispatch => {
        dispatch(startUnsaveParcel(parcel));
        csrfRequest
            .delete(changeParcelSaveStatusUrl(parcel))
            .then(({ data }) => {
                dispatch(completeUnsaveParcel(data));
            })
            .catch(() => dispatch(failUnsaveParcel(parcel)));

        return noop();
    };
}

export function unhighlightParcel() {
    return {
        type: UNHIGHLIGHT_PARCEL,
    };
}

export function highlightParcel(payload) {
    return {
        type: HIGHLIGHT_PARCEL,
        payload,
    };
}

export function deselectParcel() {
    return {
        type: DESELECT_PARCEL,
    };
}

export function selectParcel(payload) {
    return {
        type: SELECT_PARCEL,
        payload,
    };
}

function startFetchSingleParcel() {
    return {
        type: START_FETCH_SINGLE_PARCEL,
    };
}

function failFetchSingleParcel() {
    return {
        type: FAIL_FETCH_SINGLE_PARCEL,
    };
}

function cancelFetchSingleParcel() {
    return {
        type: CANCEL_FETCH_SINGLE_PARCEL,
    };
}

function completeFetchSingleParcel(payload) {
    return {
        type: COMPLETE_FETCH_SINGLE_PARCEL,
        payload,
    };
}

export function fetchSingleParcel(id) {
    return (dispatch, getState) => {
        cancelPriorRequest();
        dispatch(startFetchSingleParcel());

        const {
            mainPage: { parcels },
        } = getState();

        const parcelFromExistingData = parcels
            ? parcels.find(
                  ({ Parcel: { ParcelID } }) => Number(id) === ParcelID,
              )
            : null;

        return parcelFromExistingData
            ? dispatch(completeFetchSingleParcel(parcelFromExistingData))
            : csrfRequest
                  .get(parcelDataApiUrl(id), {
                      cancelToken: new CancelToken(c => {
                          cancelAxiosRequest = c;
                      }),
                  })
                  .then(({ data }) => selectEligibleParcels([data]))
                  .then(([data]) =>
                      dispatch(
                          data
                              ? completeFetchSingleParcel(data)
                              : failFetchSingleParcel(),
                      ),
                  )
                  .catch(thrown =>
                      dispatch(
                          isCancel(thrown)
                              ? cancelFetchSingleParcel()
                              : failFetchSingleParcel(),
                      ),
                  );
    };
}

function startFetchParcelData() {
    return {
        type: START_FETCH_PARCEL_DATA,
    };
}

function failFetchParcelData() {
    return {
        type: FAIL_FETCH_PARCEL_DATA,
    };
}

function cancelFetchParcelData() {
    return {
        type: CANCEL_FETCH_PARCEL_DATA,
    };
}

function completeFetchParcelData(payload) {
    return dispatch => {
        dispatch(deselectParcel());
        dispatch({
            type: CLEAR_PARCEL_DATA,
        });

        return dispatch({
            type: COMPLETE_FETCH_PARCEL_DATA,
            payload,
        });
    };
}

function startFetchSavedParcels() {
    return {
        type: START_FETCH_SAVED_PARCELS,
    };
}

function failFetchSavedParcels() {
    return {
        type: FAIL_FETCH_SAVED_PARCELS,
    };
}

function completeFetchSavedParcels(data) {
    return {
        type: COMPLETE_FETCH_SAVED_PARCELS,
        payload: data,
    };
}

export function fetchSavedParcels() {
    return dispatch => {
        dispatch(startFetchSavedParcels());
        csrfRequest
            .get(savedParcelsUrl)
            .then(({ data }) => {
                dispatch(completeFetchSavedParcels(data));
            })
            .catch(() => dispatch(failFetchSavedParcels()));
        return noop();
    };
}

function startFetchInterestedParcels() {
    return {
        type: START_FETCH_INTERESTED_PARCELS,
    };
}

function completeFetchInterestedParcels(payload) {
    return {
        type: COMPLETE_FETCH_INTERESTED_PARCELS,
        payload,
    };
}

function failFetchInterestedParcels() {
    return {
        type: FAIL_FETCH_INTERESTED_PARCELS,
    };
}

function formatInterestedParcels(interestedParcels) {
    return interestedParcels.map(
        ({
            address,
            retrofits,
            note,
            contact_email: email,
            contact_name: name,
            contact_phone: phone,
            parcel_id: parcelId,
        }) => ({
            address,
            retrofits,
            note,
            email,
            name,
            phone,
            parcelId,
        }),
    );
}

export function fetchInterestedParcels() {
    return dispatch => {
        dispatch(startFetchInterestedParcels());
        csrfRequest
            .get(interestedParcelsUrl)
            .then(({ data }) => formatInterestedParcels(data))
            .then(data => dispatch(completeFetchInterestedParcels(data)))
            .catch(() => dispatch(failFetchInterestedParcels()));
    };
}

function startFetchEnrolledParcels() {
    return {
        type: START_FETCH_ENROLLED_PARCELS,
    };
}

function failFetchEnrolledParcels() {
    return {
        type: FAIL_FETCH_ENROLLED_PARCELS,
    };
}

function completeFetchEnrolledParcels(payload) {
    return {
        type: COMPLETE_FETCH_ENROLLED_PARCELS,
        payload,
    };
}

function formatEnrolledParcel({
    parcel_id: parcelId,
    accepts_contacts: acceptsContacts,
    address,
    retrofits,
    note,
    contact_email: email,
    contact_name: name,
    contact_phone: phone,
}) {
    return {
        parcelId,
        acceptsContacts,
        address,
        retrofits,
        note,
        email,
        name,
        phone,
    };
}

export function fetchEnrolledParcels() {
    return dispatch => {
        dispatch(startFetchEnrolledParcels());

        csrfRequest
            .get(enrolledParcelsUrl)
            .then(({ data }) => data.map(formatEnrolledParcel))
            .then(data => dispatch(completeFetchEnrolledParcels(data)))
            .catch(() => dispatch(failFetchEnrolledParcels()));
    };
}

export function fetchParcels(newBounds = null) {
    cancelPriorRequest();
    return (dispatch, getState) => {
        dispatch(startFetchParcelData());
        const {
            mainPage: {
                interestedParcels,
                enrolledParcels,
                savedParcels,
                parcelFilters: {
                    bbox,
                    groundMaxFilter,
                    groundMinFilter,
                    roofMaxFilter,
                    roofMinFilter,
                    totalMinFilter,
                    totalMaxFilter,
                    nonResidentialFilter,
                    condosFilter,
                    capFilter,
                    gsiFilter,
                    interestedFilter,
                    enrolledFilter,
                    savedPropertiesFilter,
                    mapExtentFilter,
                },
            },
            aisGeocoder: { data: aisGeocoderResult, boundsPending },
        } = getState();

        if (!bbox && !newBounds) {
            return;
        }

        const useMapExtent =
            (mapExtentFilter &&
                !(boundsPending && aisGeocoderResult.isNotIntersection)) ||
            (boundsPending && !aisGeocoderResult.isNotIntersection);

        const esriJsonBbox = convertGeoJsonToAgsObject(
            L.rectangle(
                useMapExtent ? newBounds || bbox : getCityLimitsBbox(),
            ).toGeoJSON(),
        );

        const baseParams = querystring.stringify({
            imperviousGroundMin: groundMinFilter,
            imperviousGroundMax: groundMaxFilter,
            imperviousRoofMin: roofMinFilter,
            imperviousRoofMax: roofMaxFilter,
            impervAreaMin: totalMinFilter,
            impervAreaMax: totalMaxFilter,
            nonRes: nonResidentialFilter,
            condos: condosFilter,
            cap: capFilter,
            gsi: gsiFilter,
            numParcels: defaultRequestedParcelsCount,
        });

        const requestedParcels = calculateRequestedParcelIDs({
            aisGeocoderResult,
            savedParcels,
            interestedParcels,
            enrolledParcels,
            interestedFilter,
            savedPropertiesFilter,
            enrolledFilter,
        });

        const url = requestedParcels
            ? `${parcelsAPIUrl}?${baseParams}&${querystring.stringify(
                  requestedParcels,
              )}`
            : `${parcelsAPIUrl}?${baseParams}`;

        csrfRequest
            .put(url, esriJsonBbox, {
                cancelToken: new CancelToken(c => {
                    cancelAxiosRequest = c;
                }),
            })
            .then(({ data }) => selectEligibleParcels(data))
            .then(data => dispatch(completeFetchParcelData(data)))
            .catch(thrown =>
                dispatch(
                    isCancel(thrown)
                        ? cancelFetchParcelData()
                        : failFetchParcelData(),
                ),
            );
    };
}

export function updateMap(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_MAP,
            payload: data,
        });
        return dispatch(updateAISGeocoderFitBounds(data.bounds));
    };
}

export function changeParcelsQueryBbox(bbox) {
    return {
        type: bbox ? CHANGE_PARCELS_QUERY_BBOX : REDUX_NO_OP,
        payload: bbox,
    };
}

export function resetFilters() {
    return dispatch => {
        dispatch(clearAISGeocoderData());
        dispatch({
            type: REFETCH_ON_EMPTY_QUERY,
        });
        dispatch({
            type: RESET_FILTERS,
        });

        return dispatch(fetchParcels());
    };
}

export function changeSavedFilter() {
    return dispatch => {
        dispatch({
            type: CHANGE_SAVED_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function changeMapExtentFilter() {
    return dispatch => {
        dispatch({
            type: CHANGE_MAP_EXTENT_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function selectEligibleFilter() {
    return dispatch => {
        dispatch({
            type: SELECT_ELIGIBLE_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function selectInterestedFilter() {
    return dispatch => {
        dispatch({
            type: SELECT_INTERESTED_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function selectEnrolledFilter() {
    return dispatch => {
        dispatch({
            type: SELECT_ENROLLED_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function changeNonResFilter() {
    return dispatch => {
        dispatch({
            type: CHANGE_NONRES_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function changeCondosFilter() {
    return dispatch => {
        dispatch({
            type: CHANGE_CONDOS_FILTER,
        });

        return dispatch(fetchParcels());
    };
}

export function changeCAPFilter(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_CAP_FILTER,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeGSIFilter(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_GSI_FILTER,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeGroundMin(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_GROUND_MIN,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeGroundMax(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_GROUND_MAX,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeRoofMin(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_ROOF_MIN,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeRoofMax(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_ROOF_MAX,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeTotalMin(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_TOTAL_MIN,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

export function changeTotalMax(payload) {
    return dispatch => {
        dispatch({
            type: CHANGE_TOTAL_MAX,
            payload,
        });

        return dispatch(fetchParcels());
    };
}

function startDeveloperSignIn() {
    return {
        type: START_DEVELOPER_SIGN_IN,
    };
}

function failDeveloperSignIn(payload) {
    return {
        type: FAIL_DEVELOPER_SIGN_IN,
        payload,
    };
}

function completeDeveloperSignIn(payload) {
    return dispatch => {
        if (payload.vendorInfo) {
            dispatch(completeVendorSignIn(payload.vendorInfo));
        }

        return dispatch({
            type: COMPLETE_DEVELOPER_SIGN_IN,
            payload,
        });
    };
}

export function signInDeveloper() {
    return (dispatch, getState) => {
        dispatch(startDeveloperSignIn());

        const {
            auth: { email, password },
            detailsPage: { parcelIdFromLoginAttempt },
        } = getState();

        csrfRequest
            .post(retrofitMapLoginUrl, { email, password })
            .then(({ data }) => dispatch(completeDeveloperSignIn(data)))
            .catch(() =>
                dispatch(failDeveloperSignIn({ parcelIdFromLoginAttempt })),
            );
    };
}

export function sessionSignInDeveloper(history) {
    return (dispatch, getState) => {
        const {
            auth: { fetching, error, signedIn },
        } = getState();

        if (signedIn || fetching || error) {
            return;
        }

        const parcelIdFromLoginAttempt = parcelIdFromHistory(history);

        dispatch(startDeveloperSignIn());
        csrfRequest
            .get(retrofitMapLoginUrl)
            .then(({ data }) => dispatch(completeDeveloperSignIn(data)))
            .catch(() => {
                dispatch(failDeveloperSignIn({ parcelIdFromLoginAttempt }));
                history.replace(ROUTES.welcome);
            });
    };
}

export function resetRetrofitMap() {
    return {
        type: RESET_RETROFIT_MAP,
    };
}

function startDeveloperSignOut() {
    return {
        type: START_DEVELOPER_SIGN_OUT,
    };
}

function failDeveloperSignOut() {
    return {
        type: FAIL_DEVELOPER_SIGN_OUT,
    };
}

function completeDeveloperSignOut() {
    return {
        type: COMPLETE_DEVELOPER_SIGN_OUT,
    };
}

export function signOutDeveloper(history) {
    return (dispatch, getState) => {
        dispatch(startDeveloperSignOut());

        const {
            auth: { email, password },
        } = getState();

        cancelPriorRequest();
        maybeCancelPriorAISGeocoderRequest();

        csrfRequest
            .post(retrofitMapLogoutUrl, { email, password })
            .then(() => history.push(ROUTES.welcome))
            .then(() => dispatch(completeDeveloperSignOut()))
            .then(() => dispatch(resetRetrofitMap()))
            .then(() => dispatch(resetLatLngParcelSearch()))
            .catch(() => dispatch(failDeveloperSignOut()));
    };
}

export function setDeveloperSignInEmail(payload) {
    return {
        type: UPDATE_DEVELOPER_AUTH_EMAIL,
        payload,
    };
}

export function setDeveloperSignInPassword(payload) {
    return {
        type: UPDATE_DEVELOPER_AUTH_PASSWORD,
        payload,
    };
}

export function openRoofFilter() {
    return {
        type: OPEN_ROOF_FILTER,
    };
}

export function openGroundFilter() {
    return {
        type: OPEN_GROUND_FILTER,
    };
}

export function openTotalFilter() {
    return {
        type: OPEN_TOTAL_FILTER,
    };
}

export function openStatusFilter() {
    return {
        type: OPEN_STATUS_FILTER,
    };
}

export function openCharacteristicsFilter() {
    return {
        type: OPEN_CHARACTERISTICS_FILTER,
    };
}

export function closeAllFilters() {
    return {
        type: CLOSE_ALL_FILTERS,
    };
}

function startConfirmEmail() {
    return {
        type: START_CONFIRM_EMAIL,
    };
}

function failConfirmEmail() {
    return {
        type: FAIL_CONFIRM_EMAIL,
    };
}

function completeConfirmEmail() {
    return {
        type: COMPLETE_CONFIRM_EMAIL,
    };
}

export function confirmEmail(key) {
    return dispatch => {
        dispatch(startConfirmEmail());
        csrfRequest
            .post(confirmEmailUrl, {
                key,
            })
            .then(() => dispatch(completeConfirmEmail()))
            .catch(() => dispatch(failConfirmEmail()));
    };
}

export function showTermsOfUseModal() {
    return {
        type: SHOW_TERMS_OF_USE_MODAL,
    };
}

export function hideTermsOfUseModal() {
    return {
        type: HIDE_TERMS_OF_USE_MODAL,
    };
}

export function showDeleteAccountModal() {
    return {
        type: SHOW_DELETE_ACCOUNT_MODAL,
    };
}

export function hideDeleteAccountModal() {
    return (dispatch, getState) => {
        const {
            mainPage: { deleteModalErrorMessage },
        } = getState();

        if (deleteModalErrorMessage) {
            dispatch({
                type: RESET_DELETE_ACCOUNT_STATUS,
            });
        }

        return dispatch({
            type: HIDE_DELETE_ACCOUNT_MODAL,
        });
    };
}

export function hideErrorModal() {
    return {
        type: HIDE_ERROR_MODAL,
    };
}

export function toggleImperviousLayer() {
    return (dispatch, getState) => {
        const {
            mainPage: { imperviousLayerVisible },
        } = getState();

        return dispatch({
            type: imperviousLayerVisible
                ? HIDE_IMPERVIOUS_LAYER
                : SHOW_IMPERVIOUS_LAYER,
        });
    };
}

export function toggleSewerLayer() {
    return (dispatch, getState) => {
        const {
            mainPage: { sewerLayerVisible },
        } = getState();

        return dispatch({
            type: sewerLayerVisible ? HIDE_SEWER_LAYER : SHOW_SEWER_LAYER,
        });
    };
}

export function toggleBasemap() {
    return (dispatch, getState) => {
        const {
            mainPage: { visibleBasemap },
        } = getState();

        return dispatch({
            type:
                visibleBasemap === basemapsEnum.cityCartoBasemap
                    ? SHOW_AERIAL_BASEMAP
                    : SHOW_CARTO_BASEMAP,
        });
    };
}

function startFetchCSV(payload) {
    return {
        type: START_FETCH_CSV,
        payload,
    };
}

function failFetchCSV(err) {
    window.console.warn(err);

    return {
        type: FAIL_FETCH_CSV,
    };
}

function completeFetchCSV(payload) {
    CSVMaker.saveCSV(payload);

    return {
        type: COMPLETE_FETCH_CSV,
        payload,
    };
}

export function fetchCSV({ fetchInterested = false, fetchSaved = false } = {}) {
    return (dispatch, getState) => {
        const {
            mainPage: {
                savedParcels,
                interestedParcels,
                csv: {
                    data,
                    fetchInterested: previousFetchInterested,
                    fetchSaved: previousFetchSaved,
                },
            },
        } = getState();

        dispatch(
            startFetchCSV({
                fetchInterested,
                fetchSaved,
            }),
        );

        if (
            data &&
            previousFetchInterested === fetchInterested &&
            previousFetchSaved === fetchSaved
        ) {
            return dispatch(completeFetchCSV(data));
        }

        if (!savedParcels.length && !interestedParcels.length) {
            return dispatch(failFetchCSV());
        }

        const requestedParcels = fetchInterested
            ? interestedParcels.map(({ parcelId }) => parcelId)
            : []
                  .concat(fetchSaved ? savedParcels : [])
                  .reduce(
                      (acc, next) => acc.concat(acc.includes(next) ? [] : next),
                      [],
                  );

        const baseParams = querystring.stringify({
            nonRes: true,
            condos: true,
            apts: true,
            numParcels: requestedParcels.length,
        });

        const phillyBbox = L.latLngBounds(
            L.latLng(40.10538669840983, -74.82856750488283),
            L.latLng(39.7982249632104, -75.4973602294922),
        );

        return csrfRequest
            .put(
                `${parcelsAPIUrl}?${baseParams}&${querystring.stringify({
                    requestedParcels,
                })}`,
                convertGeoJsonToAgsObject(L.rectangle(phillyBbox).toGeoJSON()),
            )
            .then(({ data: parcelData }) =>
                CSVMaker.formatParcelDataWithInterestedData(
                    parcelData,
                    interestedParcels,
                ),
            )
            .then(parcelDataWithInterested =>
                CSVMaker.createCSVStringWithHeaders(parcelDataWithInterested),
            )
            .then(csvData => dispatch(completeFetchCSV(csvData)))
            .catch(e => dispatch(failFetchCSV(e)));
    };
}

export function togglePropertySearchMapWidth() {
    return (dispatch, getState) => {
        const {
            mainPage: {
                map: { expanded },
            },
        } = getState();

        return dispatch({
            type: expanded
                ? CONTRACT_PROPERTY_SEARCH_MAP
                : EXPAND_PROPERTY_SEARCH_MAP,
        });
    };
}

export function togglePropertyDetailsMapWidth() {
    return (dispatch, getState) => {
        const {
            detailsPage: { mapExpanded },
        } = getState();

        return dispatch({
            type: mapExpanded
                ? CONTRACT_PROPERTY_DETAILS_MAP
                : EXPAND_PROPERTY_DETAILS_MAP,
        });
    };
}

export function failDeleteVendorAccount(e) {
    window.console.warn(e);

    return {
        type: FAIL_DELETE_ACCOUNT_STATUS,
    };
}

export function startDeleteVendorAccount() {
    return (dispatch, getState) => {
        const {
            auth: { id },
        } = getState();

        return csrfRequest
            .patch(`${API_BASE_URL}/users/${id}/`, {
                status: developerStatusTypes.deactivated,
            })
            .then(r =>
                dispatch({
                    type: COMPLETE_DELETE_ACCOUNT,
                    payload: r.data.status,
                }),
            )
            .then(() =>
                dispatch({
                    type: COMPLETE_DEVELOPER_SIGN_OUT,
                }),
            )
            .catch(e => dispatch(failDeleteVendorAccount(e)));
    };
}
