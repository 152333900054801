import {
    loginUrl,
    logoutUrl,
    userUrl,
    propertyManagersUrl,
    retrofitDevelopersUrl,
    addRetrofitDeveloperUrl,
    addPropertyManagerUrl,
    developerStatusTypes,
    makePropertyManagerEditUrl,
    makeRetrofitDeveloperEditUrl,
    apiError,
    makeRemoveDeveloperOfInterestUrl,
    makeRemoveParcelUrl,
    makeRemoveInterestedManagerUrl,
    resendEmailUrl,
    dashboardSortOrderEnum,
    addManagerParcelUrl,
    networkError,
    forbiddenError,
    unknownError,
    DASHBOARD_ROUTES,
} from './constants';

import { resetDeveloperPasswordUrl } from '../../constants';

import { fetchSingleParcel } from '../../actions';

import { performGeocoderSearch } from '../../../geocoder/actions';

import { shallowUpdateData } from '../../utils';
import { mapPreferredContact } from '../../../common/utils';

import csrfRequest from '../../../common/csrfRequest';
import { preferredContactTypes } from '../../../common/constants';

export const RESET_ADMIN_DASHBOARD = 'RESET_ADMIN_DASHBOARD';
export const START_SIGN_IN = 'START_SIGN_IN';
export const FAIL_SIGN_IN = 'FAIL_SIGN_IN';
export const COMPLETE_SIGN_IN = 'COMPLETE_SIGN_IN';
export const START_SIGN_OUT = 'START_SIGN_OUT';
export const FAIL_SIGN_OUT = 'FAIL_SIGN_OUT';
export const COMPLETE_SIGN_OUT = 'COMPLETE_SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const START_FETCH_DEVELOPER_DATA = 'START_FETCH_DEVELOPER_DATA';
export const FAIL_FETCH_DEVELOPER_DATA = 'FAIL_FETCH_DEVELOPER_DATA';
export const COMPLETE_FETCH_DEVELOPER_DATA = 'COMPLETE_FETCH_DEVELOPER_DATA';
export const START_FETCH_MANAGER_DATA = 'START_FETCH_MANAGER_DATA';
export const FAIL_FETCH_MANAGER_DATA = 'FAIL_FETCH_MANAGER_DATA';
export const COMPLETE_FETCH_MANAGER_DATA = 'COMPLETE_FETCH_MANAGER_DATA';

export const START_UPDATE_DEVELOPER_STATUS = 'START_UPDATE_DEVELOPER_STATUS';
export const FAIL_UPDATE_DEVELOPER_STATUS = 'FAIL_UPDATE_DEVELOPER_STATUS';
export const COMPLETE_UPDATE_DEVELOPER_STATUS =
    'COMPLETE_UPDATE_DEVELOPER_STATUS';

export const TOGGLE_DEVELOPER_FILTER_OPTION = 'TOGGLE_DEVELOPER_FILTER_OPTION';
export const TOGGLE_MANAGER_FILTER_OPTION = 'TOGGLE_MANAGER_FILTER_OPTION';
export const UPDATE_DEVELOPER_SEARCH_TEXT = 'UPDATE_DEVELOPER_SEARCH_TEXT';
export const UPDATE_MANAGER_SEARCH_TEXT = 'UPDATE_MANAGER_SEARCH_TEXT';

export const START_EDIT_MANAGER = 'BEGIN_EDIT_MANAGER';
export const EDIT_MANAGER_NAME = 'EDIT_MANAGER_NAME';
export const EDIT_MANAGER_EMAIL = 'EDIT_MANAGER_EMAIL';
export const EDIT_MANAGER_PHONE = 'EDIT_MANAGER_PHONE';
export const EDIT_MANAGER_PREFERRED_CONTACT = 'EDIT_MANAGER_PREFERRED_CONTACT';
export const EDIT_MANAGER_NOTE = 'EDIT_MANAGER_NOTE';
export const START_SAVE_MANAGER_EDITS = 'START_SAVE_MANAGER_EDITS';
export const FAIL_SAVE_MANAGER_EDITS = 'FAIL_SAVE_MANAGER_EDITS';
export const COMPLETE_SAVE_MANAGER_EDITS = 'COMPLETE_SAVE_MANAGER_EDITS';
export const STOP_EDIT_MANAGER = 'STOP_EDIT_MANAGER';

export const START_EDIT_DEVELOPER = 'BEGIN_EDIT_DEVELOPER';
export const EDIT_DEVELOPER_COMPANY_NAME = 'EDIT_DEVELOPER_COMPANY_NAME';
export const EDIT_DEVELOPER_EMAIL = 'EDIT_DEVELOPER_EMAIL';
export const EDIT_DEVELOPER_WEBSITE = 'EDIT_DEVELOPER_WEBSITE';
export const EDIT_DEVELOPER_PHONE = 'EDIT_DEVELOPER_PHONE';
export const EDIT_DEVELOPER_PREFERRED_CONTACT =
    'EDIT_DEVELOPER_PREFERRED_CONTACT';
export const EDIT_DEVELOPER_EIN = 'EDIT_DEVELOPER_EIN';
export const EDIT_DEVELOPER_CAL = 'EDIT_DEVELOPER_CAL';
export const EDIT_DEVELOPER_SPECIALTIES = 'EDIT_DEVELOPER_SPECIALTIES';
export const EDIT_DEVELOPER_GRANT_APPROVAL = 'EDIT_DEVELOPER_GRANT_APPROVAL';
export const EDIT_DEVELOPER_SUBGRANT_APPROVAL =
    'EDIT_DEVELOPER_SUBGRANT_APPROVAL';
export const EDIT_DEVELOPER_DESIGN_SERVICES = 'EDIT_DEVELOPER_DESIGN_SERVICES';
export const EDIT_DEVELOPER_CONSTRUCTION_SERVICES =
    'EDIT_DEVELOPER_CONSTRUCTION_SERVICES';
export const EDIT_DEVELOPER_MAINTENANCE_SERVICES =
    'EDIT_DEVELOPER_MAINTENANCE_SERVICES';
export const EDIT_DEVELOPER_MANAGEMENT_SERVICES =
    'EDIT_DEVELOPER_MANAGEMENT_SERVICES';
export const EDIT_DEVELOPER_CONTACT_NAME = 'EDIT_DEVELOPER_CONTACT_NAME';
export const EDIT_DEVELOPER_CONTACT_EMAIL = 'EDIT_DEVELOPER_CONTACT_EMAIL';
export const EDIT_DEVELOPER_CONTACT_PHONE = 'EDIT_DEVELOPER_CONTACT_PHONE';
export const EDIT_DEVELOPER_OTHER_RETROFIT = 'EDIT_DEVELOPER_OTHER_RETROFIT';
export const EDIT_DEVELOPER_REASON_FOR_DENIAL =
    'EDIT_DEVELOPER_REASON_FOR_DENIAL';
export const START_SAVE_DEVELOPER_EDITS = 'START_SAVE_DEVELOPER_EDITS';
export const FAIL_SAVE_DEVELOPER_EDITS = 'FAIL_SAVE_DEVELOPER_EDITS';
export const COMPLETE_SAVE_DEVELOPER_EDITS = 'COMPLETE_SAVE_DEVELOPER_EDITS';
export const STOP_EDIT_DEVELOPER = 'STOP_EDIT_DEVELOPER';

export const START_REMOVE_MANAGER_PARCEL = 'START_REMOVE_MANAGER_PARCEL';
export const FAIL_REMOVE_MANAGER_PARCEL = 'FAIL_REMOVE_MANAGER_PARCEL';
export const COMPLETE_REMOVE_MANAGER_PARCEL = 'COMPLETE_REMOVE_MANAGER_PARCEL';

export const START_REMOVE_DEVELOPER_OF_INTEREST =
    'START_REMOVE_DEVELOPER_OF_INTEREST';
export const FAIL_REMOVE_DEVELOPER_OF_INTEREST =
    'FAIL_REMOVE_DEVELOPER_OF_INTEREST';
export const COMPLETE_REMOVE_DEVELOPER_OF_INTEREST =
    'COMPLETE_REMOVE_DEVELOPER_OF_INTEREST';

export const START_REMOVE_INTERESTED_MANAGER =
    'START_REMOVE_INTERESTED_MANAGER';
export const FAIL_REMOVE_INTERESTED_MANAGER = 'FAIL_REMOVE_INTERESTED_MANAGER';
export const COMPLETE_REMOVE_INTERESTED_MANAGER =
    'COMPLETE_REMOVE_INTERESTED_MANAGER';

export const START_RESEND_EMAIL_VERIFICATION =
    'START_RESEND_EMAIL_VERIFICATION';
export const FAIL_RESEND_EMAIL_VERIFICATION = 'FAIL_RESEND_EMAIL_VERIFICATION';
export const COMPLETE_RESEND_EMAIL_VERIFICATION =
    'COMPLETE_RESEND_EMAIL_VERIFICATION';

export const START_SEND_DEVELOPER_PASSWORD_RESET_EMAIL =
    'START_SEND_DEVELOPER_PASSWORD_RESET_EMAIL';
export const FAIL_SEND_DEVELOPER_PASSWORD_RESET_EMAIL =
    'FAIL_SEND_DEVELOPER_PASSWORD_RESET_EMAIL';
export const COMPLETE_SEND_DEVELOPER_PASSWORD_RESET_EMAIL =
    'COMPLETE_SEND_DEVELOPER_PASSWORD_RESET_EMAIL';

export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

export const SORT_MANAGER_DASHBOARD_NAME = 'SORT_MANAGER_DASHBOARD_NAME';
export const SORT_MANAGER_DASHBOARD_EMAIL = 'SORT_MANAGER_DASHBOARD_EMAIL';
export const SORT_MANAGER_DASHBOARD_SIGNUP_DATE =
    'SORT_MANAGER_DASHBOARD_SIGNUP_DATE';
export const SORT_MANAGER_DASHBOARD_PROPERTY_COUNT =
    'SORT_MANAGER_DASHBOARD_PROPERTY_COUNT';
export const SORT_MANAGER_DASHBOARD_INTERESTED_COUNT =
    'SORT_MANAGER_DASHBOARD_INTERESTED_COUNT';
export const SORT_DEVELOPER_DASHBOARD_COMPANY =
    'SORT_DEVELOPER_DASHBOARD_COMPANY';
export const SORT_DEVELOPER_DASHBOARD_WEBSITE =
    'SORT_DEVELOPER_DASHBOARD_WEBSITE';
export const SORT_DEVELOPER_DASHBOARD_SIGNUP_DATE =
    'SORT_DEVELOPER_DASHBOARD_SIGNUP_DATE';
export const SORT_DEVELOPER_DASHBOARD_INTERESTED_COUNT =
    'SORT_DEVELOPER_DASHBOARD_INTERESTED_COUNT';
export const SORT_DEVELOPER_DASHBOARD_STATUS =
    'SORT_DEVELOPER_DASHBOARD_STATUS';

export const RESET_DEVELOPER_TABLE_FILTERS_AND_SORTS =
    'RESET_DEVELOPER_TABLE_FILTERS_AND_SORTS';
export const RESET_MANAGER_TABLE_FILTERS_AND_SORTS =
    'RESET_MANAGER_TABLE_FILTERS_AND_SORTS';

export const UPDATE_PARCEL_SEARCH_VALUE = 'UPDATE_PARCEL_SEARCH_VALUE';
export const START_SEARCH_PARCEL = 'START_SEARCH_PARCEL';
export const FAIL_SEARCH_PARCEL = 'FAIL_SEARCH_PARCEL';
export const COMPLETE_SEARCH_PARCEL = 'COMPLETE_SEARCH_PARCEL';

export const START_ADD_MANAGER_PARCEL = 'START_ADD_MANAGER_PARCEL';
export const FAIL_ADD_MANAGER_PARCEL = 'FAIL_ADD_MANAGER_PARCEL';
export const COMPLETE_ADD_MANAGER_PARCEL = 'COMPLETE_ADD_MANAGER_PARCEL';

export const SHOW_ADD_PARCEL_MODAL = 'SHOW_ADD_PARCEL_MODAL';
export const HIDE_ADD_PARCEL_MODAL = 'HIDE_ADD_PARCEL_MODAL';
export const UPDATE_GEOCODER_SEARCH_TEXT = 'UPDATE_GEOCODER_SEARCH_TEXT';
export const UPDATE_PARCEL_RETROFITS = 'UPDATE_PARCEL_RETROFITS';
export const UPDATE_PARCEL_DEVELOPERS = 'UPDATE_PARCEL_DEVELOPERS';
export const START_SAVE_ADDED_PARCEL = 'START_SAVE_ADDED_PARCEL';
export const FAIL_SAVE_ADDED_PARCEL = 'FAIL_SAVE_ADDED_PARCEL';
export const COMPLETE_SAVE_ADDED_PARCEL = 'COMPLETE_SAVE_ADDED_PARCEL';

export const START_DELETE_MANAGER = 'START_DELETE_MANAGER';
export const FAIL_DELETE_MANAGER = 'FAIL_DELETE_MANAGER';
export const COMPLETE_DELETE_MANAGER = 'COMPLETE_DELETE_MANAGER';

function startSignIn() {
    return {
        type: START_SIGN_IN,
    };
}

function failSignIn({ message, response: { status } = {} }) {
    const payload = (() => {
        if (status && [400, 403].includes(status)) {
            return forbiddenError;
        }

        if (message && message === networkError) {
            return 'Unable to log in due to an error with the network or our servers';
        }

        return unknownError;
    })();

    return {
        type: FAIL_SIGN_IN,
        payload,
    };
}

function completeSignIn({ key: payload }) {
    return {
        type: COMPLETE_SIGN_IN,
        payload,
    };
}

export function sessionSignIn(history) {
    return dispatch => {
        dispatch(startSignIn());
        return csrfRequest
            .get(loginUrl)
            .then(({ data }) => dispatch(completeSignIn(data)))
            .catch(() => history.push(DASHBOARD_ROUTES.signin));
    };
}

export function signIn(credentials) {
    return dispatch => {
        dispatch(startSignIn());
        return csrfRequest
            .post(loginUrl, credentials)
            .then(({ data }) => dispatch(completeSignIn(data)))
            .catch(response => dispatch(failSignIn(response)));
    };
}

function startSignOut() {
    return {
        type: START_SIGN_OUT,
    };
}

function failSignOut() {
    return {
        type: FAIL_SIGN_OUT,
    };
}

function completeSignOut() {
    return {
        type: COMPLETE_SIGN_OUT,
    };
}

export function signOut() {
    return dispatch => {
        dispatch(startSignOut());
        csrfRequest
            .post(logoutUrl)
            .then(() => dispatch(completeSignOut()))
            .catch(() => dispatch(failSignOut()));
    };
}

function startFetchDeveloperData() {
    return {
        type: START_FETCH_DEVELOPER_DATA,
    };
}

function failFetchDeveloperData() {
    return {
        type: FAIL_FETCH_DEVELOPER_DATA,
    };
}

function completeFetchDeveloperData(payload) {
    return {
        type: COMPLETE_FETCH_DEVELOPER_DATA,
        payload,
    };
}

function mapRetrofits(retrofits) {
    return Object.entries(retrofits)
        .filter(([, value]) => value)
        .map(([retrofit]) => retrofit.replace('_', ' '));
}

function formatDeveloperResult({
    id,
    company_name,
    company_email,
    company_phone,
    created_at,
    prefer_email,
    prefer_phone,
    ein,
    commercial_activity_license,
    name,
    email,
    phone,
    status,
    is_email_verified,
    green_roof,
    cistern,
    subsurface_storage,
    rain_garden,
    permeable_pavement,
    other_retrofit,
    has_worked_on_grant,
    has_been_subgrantee,
    approved_at,
    deactivated_at,
    denied_at,
    reason_for_denial: reasonForDenial,
    has_admin_generated_password,
    company_website: website = '',
    is_design_firm,
    is_construction_firm,
    is_maintenance_firm,
    is_management_firm,
}) {
    return {
        id,
        companyName: company_name,
        website,
        email: company_email,
        phone: company_phone,
        preferredContact: mapPreferredContact({
            email: prefer_email,
            phone: prefer_phone,
        }),
        ein,
        commercialActivityLicense: commercial_activity_license,
        signUpDate: created_at,
        approvedDate: approved_at,
        deactivatedDate: deactivated_at,
        deniedDate: denied_at,
        reasonForDenial,
        status,
        isEmailVerified: is_email_verified,
        retrofits: mapRetrofits({
            green_roof,
            cistern,
            subsurface_storage,
            permeable_pavement,
            rain_garden,
        }),
        otherRetrofit: other_retrofit,
        contactName: name,
        contactEmail: email,
        contactPhone: phone,
        hasWorkedOnGrant: has_worked_on_grant,
        hasBeenSubgrantee: has_been_subgrantee,
        hasAdminGeneratedPassword: has_admin_generated_password,
        isDesignFirm: is_design_firm,
        isConstructionFirm: is_construction_firm,
        isMaintenanceFirm: is_maintenance_firm,
        isManagementFirm: is_management_firm,
    };
}

function formatDeveloperResults(developers) {
    return developers.map(formatDeveloperResult);
}

export function fetchDeveloperData() {
    return dispatch => {
        dispatch(startFetchDeveloperData());
        csrfRequest
            .get(retrofitDevelopersUrl)
            .then(({ data }) => formatDeveloperResults(data))
            .then(data => dispatch(completeFetchDeveloperData(data)))
            .catch(() => dispatch(failFetchDeveloperData()));
    };
}

function startFetchManagerData() {
    return {
        type: START_FETCH_MANAGER_DATA,
    };
}

function failFetchManagerData() {
    return {
        type: FAIL_FETCH_MANAGER_DATA,
    };
}

function completeFetchManagerData(payload) {
    return {
        type: COMPLETE_FETCH_MANAGER_DATA,
        payload,
    };
}

function mapManagerParcel(
    managerId,
    { id, parcel_id, address, building_type, selected_developers },
) {
    return {
        id,
        parcelId: parcel_id,
        managerId,
        address,
        buildingType: building_type,
        selectedDevelopers: selected_developers,
    };
}

function mapManagerParcels(managerId, parcels) {
    if (!parcels) {
        return [];
    }

    return parcels.map(parcel => mapManagerParcel(managerId, parcel));
}

function formatManagerResult({
    id,
    email,
    name,
    phone,
    note,
    prefer_email,
    prefer_phone,
    parcels,
    cistern,
    green_roof,
    permeable_pavement,
    subsurface_storage,
    rain_garden,
    created_at,
    selected_developers: developersOfInterest = [],
}) {
    return {
        id,
        propertyManager: name,
        contactEmail: email,
        contactPhone: phone,
        preferredContact: mapPreferredContact({
            email: prefer_email,
            phone: prefer_phone,
        }),
        noteToDevelopers: note || '',
        parcels: mapManagerParcels(id, parcels),
        developersOfInterest,
        signUpDate: created_at,
        retrofits: mapRetrofits({
            cistern,
            green_roof,
            permeable_pavement,
            subsurface_storage,
            rain_garden,
        }),
    };
}

export function fetchManagerData() {
    return dispatch => {
        dispatch(startFetchManagerData());
        csrfRequest
            .get(propertyManagersUrl)
            .then(({ data }) => data.map(formatManagerResult))
            .then(data => dispatch(completeFetchManagerData(data)))
            .catch(() => dispatch(failFetchManagerData()));
    };
}

export function startUpdateDeveloperStatus() {
    return {
        type: START_UPDATE_DEVELOPER_STATUS,
    };
}

export function failUpdateDeveloperStatus(e) {
    window.console.warn(e);

    return {
        type: FAIL_UPDATE_DEVELOPER_STATUS,
    };
}

export function completeUpdateDeveloperStatus(payload) {
    return {
        type: COMPLETE_UPDATE_DEVELOPER_STATUS,
        payload,
    };
}

export function updateDeveloperStatus(id, oldStatus, status) {
    const isNewStatusAllowed = [
        developerStatusTypes.denied,
        developerStatusTypes.active,
        developerStatusTypes.deactivated,
    ].some(s => s === status);

    if (!isNewStatusAllowed) {
        throw new Error(`Invalid status type for update: ${status}`);
    }

    return (dispatch, getState) => {
        dispatch(startUpdateDeveloperStatus());

        const {
            adminDashboardPage: {
                developerEdit: {
                    data: { reasonForDenial },
                },
            },
        } = getState();

        const newStatus =
            status === developerStatusTypes.denied
                ? {
                      status,
                      reason_for_denial: reasonForDenial,
                  }
                : { status };

        return csrfRequest
            .patch(`${userUrl}${id}/`, newStatus)
            .then(({ data }) => formatDeveloperResult(data))
            .then(data => dispatch(completeUpdateDeveloperStatus(data)))
            .catch(e => dispatch(failUpdateDeveloperStatus(e)));
    };
}

export function startResendEmailVerification() {
    return {
        type: START_RESEND_EMAIL_VERIFICATION,
    };
}

export function failResendEmailVerification() {
    return {
        type: FAIL_RESEND_EMAIL_VERIFICATION,
    };
}

export function completeResendEmailVerification() {
    return {
        type: COMPLETE_RESEND_EMAIL_VERIFICATION,
    };
}

export function resendEmailVerification(id) {
    return dispatch => {
        dispatch(startResendEmailVerification());
        csrfRequest
            .get(`${resendEmailUrl}?user_id=${id}`)
            .then(() => dispatch(completeResendEmailVerification()))
            .catch(() => dispatch(failResendEmailVerification()));
    };
}

export function toggleDeveloperFilterOption(payload) {
    return {
        type: TOGGLE_DEVELOPER_FILTER_OPTION,
        payload,
    };
}

export function toggleManagerFilterOption(payload) {
    return {
        type: TOGGLE_MANAGER_FILTER_OPTION,
        payload,
    };
}

export function updateDeveloperSearchText(payload) {
    return {
        type: UPDATE_DEVELOPER_SEARCH_TEXT,
        payload,
    };
}

export function updateManagerSearchText(payload) {
    return {
        type: UPDATE_MANAGER_SEARCH_TEXT,
        payload,
    };
}

export function startEditManager(managerId) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: { data },
            },
        } = getState();

        const editableManager = data.find(({ id }) => id === managerId);

        if (!editableManager) {
            throw new Error('Invalid manager id');
        }

        return dispatch({
            type: START_EDIT_MANAGER,
            payload: editableManager,
        });
    };
}

function startSaveManagerEdits() {
    return {
        type: START_SAVE_MANAGER_EDITS,
    };
}

function failSaveManagerEdits({ data = apiError }) {
    return {
        type: FAIL_SAVE_MANAGER_EDITS,
        payload: data,
    };
}

function completeSaveManagerEdits(payload) {
    return {
        type: COMPLETE_SAVE_MANAGER_EDITS,
        payload,
    };
}

function mapEditsToManagerData({
    id,
    propertyManager: name,
    contactEmail: email,
    contactPhone: phone,
    preferredContact,
    noteToDevelopers,
}) {
    return {
        id,
        name,
        email,
        phone,
        prefer_email: ['email', 'both'].includes(preferredContact),
        prefer_phone: ['telephone', 'both'].includes(preferredContact),
        note: noteToDevelopers,
    };
}

function mapNewToManagerData(manager) {
    const { parcels, developersOfInterest } = manager;
    return Object.assign({}, mapEditsToManagerData(manager), {
        parcels,
        selected_developers: developersOfInterest,
        cistern: true,
        subsurface_storage: true,
        rain_garden: true,
        permeable_pavement: true,
        green_roof: true,
    });
}

function updateClientManagerData(data, managers) {
    return shallowUpdateData(formatManagerResult, data, managers);
}

/** Save a new manager or manager edits to the server. Merge changes with
 *  state on completion.
 * @param requestPromise -- a function that takes a managerData object
 *                          and returns a csrf request promise that resolves
 *                          with the response
 */
function saveManager(requestPromise, history) {
    return (dispatch, getState) => {
        dispatch(startSaveManagerEdits());

        const {
            adminDashboardPage: {
                managerEdit: { data: editedManagerData },
                managers: { data: managerData },
            },
        } = getState();

        requestPromise(editedManagerData)
            .then(({ data }) => ({
                managers: updateClientManagerData(data, managerData),
                updatedId: data.id,
            }))
            .then(({ managers, updatedId }) => {
                dispatch(completeSaveManagerEdits(managers));
                history.push(`${DASHBOARD_ROUTES.customer}/${updatedId}`);
            })
            .catch(({ response = {} }) =>
                dispatch(failSaveManagerEdits(response)),
            );
    };
}

export function saveManagerEdits(history) {
    const requestPromise = data =>
        csrfRequest.put(
            makePropertyManagerEditUrl(data.id),
            mapEditsToManagerData(data),
        );
    return saveManager(requestPromise, history);
}

export function saveNewManager(history) {
    const requestPromise = data =>
        csrfRequest.post(addPropertyManagerUrl, mapNewToManagerData(data));
    return saveManager(requestPromise, history);
}

export function stopEditManager() {
    return {
        type: STOP_EDIT_MANAGER,
    };
}

export function stopNewManager(history) {
    return dispatch => {
        history.push(DASHBOARD_ROUTES.customers);
        dispatch(stopEditManager());
    };
}

export function editManagerName(payload) {
    return {
        type: EDIT_MANAGER_NAME,
        payload,
    };
}

export function editManagerEmail(payload) {
    return {
        type: EDIT_MANAGER_EMAIL,
        payload,
    };
}

export function editManagerPhone(payload) {
    return {
        type: EDIT_MANAGER_PHONE,
        payload,
    };
}

export function editManagerPreferredContact(payload) {
    return {
        type: EDIT_MANAGER_PREFERRED_CONTACT,
        payload,
    };
}

export function editManagerNote(payload) {
    return {
        type: EDIT_MANAGER_NOTE,
        payload,
    };
}

export function startEditDeveloper(developerId) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developers: { data },
            },
        } = getState();

        const editableDeveloper = data.find(({ id }) => id === developerId);

        if (!editableDeveloper) {
            throw new Error('Invalid developer id');
        }

        return dispatch({
            type: START_EDIT_DEVELOPER,
            payload: editableDeveloper,
        });
    };
}

export function editDeveloperCompanyName(payload) {
    return {
        type: EDIT_DEVELOPER_COMPANY_NAME,
        payload,
    };
}

export function editDeveloperWebsite(payload) {
    return {
        type: EDIT_DEVELOPER_WEBSITE,
        payload,
    };
}

export function editDeveloperEmail(payload) {
    return {
        type: EDIT_DEVELOPER_EMAIL,
        payload,
    };
}

export function editDeveloperPhone(payload) {
    return {
        type: EDIT_DEVELOPER_PHONE,
        payload,
    };
}

export function editDeveloperContactPreference(payload) {
    return {
        type: EDIT_DEVELOPER_PREFERRED_CONTACT,
        payload,
    };
}

export function editDeveloperEIN(payload) {
    return {
        type: EDIT_DEVELOPER_EIN,
        payload,
    };
}

export function editDeveloperCAL(payload) {
    return {
        type: EDIT_DEVELOPER_CAL,
        payload,
    };
}

export function editDeveloperOtherRetrofit(payload) {
    return {
        type: EDIT_DEVELOPER_OTHER_RETROFIT,
        payload,
    };
}

export function editDeveloperSpecialties(retrofit) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developerEdit: {
                    data: { retrofits },
                },
            },
        } = getState();

        const payload = retrofits.includes(retrofit)
            ? retrofits.filter(r => r !== retrofit)
            : retrofits.concat(retrofit);

        return dispatch({
            type: EDIT_DEVELOPER_SPECIALTIES,
            payload,
        });
    };
}

export function editDeveloperGrantApproval() {
    return {
        type: EDIT_DEVELOPER_GRANT_APPROVAL,
    };
}

export function editDeveloperSubgrantApproval() {
    return {
        type: EDIT_DEVELOPER_SUBGRANT_APPROVAL,
    };
}

export function editDeveloperDesignServices() {
    return {
        type: EDIT_DEVELOPER_DESIGN_SERVICES,
    };
}

export function editDeveloperConstructionServices() {
    return {
        type: EDIT_DEVELOPER_CONSTRUCTION_SERVICES,
    };
}

export function editDeveloperMaintenanceServices() {
    return {
        type: EDIT_DEVELOPER_MAINTENANCE_SERVICES,
    };
}

export function editDeveloperManagementServices() {
    return {
        type: EDIT_DEVELOPER_MANAGEMENT_SERVICES,
    };
}

export function editDeveloperContactName(payload) {
    return {
        type: EDIT_DEVELOPER_CONTACT_NAME,
        payload,
    };
}

export function editDeveloperContactEmail(payload) {
    return {
        type: EDIT_DEVELOPER_CONTACT_EMAIL,
        payload,
    };
}

export function editDeveloperContactPhone(payload) {
    return {
        type: EDIT_DEVELOPER_CONTACT_PHONE,
        payload,
    };
}

export function editDeveloperReasonForDenial(payload) {
    return {
        type: EDIT_DEVELOPER_REASON_FOR_DENIAL,
        payload,
    };
}

export function stopEditDeveloper() {
    return {
        type: STOP_EDIT_DEVELOPER,
    };
}
function startSaveDeveloperEdits() {
    return {
        type: START_SAVE_DEVELOPER_EDITS,
    };
}

function failSaveDeveloperEdits({ data = apiError }) {
    return {
        type: FAIL_SAVE_DEVELOPER_EDITS,
        payload: data,
    };
}

function completeSaveDeveloperEdits(payload) {
    return {
        type: COMPLETE_SAVE_DEVELOPER_EDITS,
        payload,
    };
}

export function stopNewDeveloper(history) {
    return dispatch => {
        history.push(DASHBOARD_ROUTES.vendors);
        dispatch(stopEditDeveloper());
    };
}

function mapEditsToDeveloperData({
    id,
    companyName,
    website,
    email,
    phone,
    preferredContact,
    ein,
    commercialActivityLicense,
    hasWorkedOnGrant,
    hasBeenSubgrantee,
    retrofits,
    contactEmail,
    contactPhone,
    contactName,
    otherRetrofit,
    reasonForDenial,
    isDesignFirm,
    isConstructionFirm,
    isMaintenanceFirm,
    isManagementFirm,
}) {
    return {
        id,
        ein,
        company_name: companyName,
        company_email: email,
        company_phone: phone,
        company_website: website,
        prefer_email: preferredContact === preferredContactTypes.email,
        prefer_phone: preferredContact === preferredContactTypes.telephone,
        commercial_activity_license: commercialActivityLicense,
        has_worked_on_grant: hasWorkedOnGrant,
        has_been_subgrantee: hasBeenSubgrantee,
        rain_garden: retrofits.includes('rain garden'),
        subsurface_storage: retrofits.includes('subsurface storage'),
        cistern: retrofits.includes('cistern'),
        green_roof: retrofits.includes('green roof'),
        permeable_pavement: retrofits.includes('permeable pavement'),
        other_retrofit: otherRetrofit,
        email: contactEmail,
        name: contactName,
        phone: contactPhone,
        reason_for_denial: reasonForDenial,
        is_design_firm: isDesignFirm,
        is_construction_firm: isConstructionFirm,
        is_maintenance_firm: isMaintenanceFirm,
        is_management_firm: isManagementFirm,
    };
}

function mapNewToDeveloperData(developer) {
    const { password, status } = developer;
    return Object.assign({}, mapEditsToDeveloperData(developer), {
        password,
        status,
    });
}

function updateClientDeveloperData(data, developers) {
    return shallowUpdateData(formatDeveloperResult, data, developers);
}

/** Save a new developer or developer edits to the server. Merge changes with
 *  state on completion.
 * @param requestPromise -- a function that takes a developerData object
 *                          and returns a csrf request promise that resolves
 *                          with the response
 */
function saveDeveloper(requestPromise, history) {
    return (dispatch, getState) => {
        dispatch(startSaveDeveloperEdits(history));
        const {
            adminDashboardPage: {
                developerEdit: { data: editedDeveloperData },
                developers: { data: developerData },
            },
        } = getState();

        requestPromise(editedDeveloperData)
            .then(({ data }) => ({
                developers: updateClientDeveloperData(data, developerData),
                updatedId: data.id,
            }))
            .then(({ developers, updatedId }) => {
                dispatch(completeSaveDeveloperEdits(developers));
                history.push(`${DASHBOARD_ROUTES.vendor}/${updatedId}`);
            })
            .catch(({ response = {} }) =>
                dispatch(failSaveDeveloperEdits(response)),
            );
    };
}

export function saveDeveloperEdits(history) {
    const requestPromise = developerData =>
        csrfRequest.patch(
            makeRetrofitDeveloperEditUrl(developerData.id),
            mapEditsToDeveloperData(developerData),
        );
    return saveDeveloper(requestPromise, history);
}

export function saveNewDeveloper(history) {
    const requestPromise = developerData =>
        csrfRequest.post(
            addRetrofitDeveloperUrl,
            mapNewToDeveloperData(developerData),
        );
    return saveDeveloper(requestPromise, history);
}

function startRemoveDeveloperOfInterest() {
    return {
        type: START_REMOVE_DEVELOPER_OF_INTEREST,
    };
}

function failRemoveDeveloperOfInterest() {
    return {
        type: FAIL_REMOVE_DEVELOPER_OF_INTEREST,
    };
}

function dropRemovedDevelopers(parcel, manager, developer, managerData) {
    const updatedManagerIndex = managerData.findIndex(
        ({ id }) => id === manager,
    );

    if (updatedManagerIndex === -1) {
        throw new Error('Invalid customer id');
    }

    const parcelData = managerData[updatedManagerIndex].parcels;
    const updatedParcelIndex = parcelData.findIndex(({ id }) => id === parcel);
    const priorSelectedDevelopers =
        parcelData[updatedParcelIndex].selectedDevelopers;

    const numParcelsThisDeveloper = parcelData.filter(
        ({ selectedDevelopers }) => selectedDevelopers.includes(developer),
    ).length;
    const newDevelopersOfInterest = managerData[
        updatedManagerIndex
    ].developersOfInterest.filter(
        devId => devId !== developer || numParcelsThisDeveloper > 1,
    );

    return [
        ...managerData.slice(0, updatedManagerIndex),
        Object.assign({}, managerData[updatedManagerIndex], {
            parcels: [
                ...parcelData.slice(0, updatedParcelIndex),
                Object.assign({}, parcelData[updatedParcelIndex], {
                    selectedDevelopers: priorSelectedDevelopers.filter(
                        id => id !== developer,
                    ),
                }),
                ...parcelData.slice(updatedParcelIndex + 1),
            ],
            developersOfInterest: newDevelopersOfInterest,
        }),
        ...managerData.slice(updatedManagerIndex + 1),
    ];
}

function completeRemoveDeveloperOfInterest(parcel, manager, developer) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: { data },
            },
        } = getState();

        return dispatch({
            type: COMPLETE_REMOVE_INTERESTED_MANAGER,
            payload: dropRemovedDevelopers(parcel, manager, developer, data),
        });
    };
}

export function removeDeveloperOfInterest(parcel, manager, developer) {
    return dispatch => {
        dispatch(startRemoveDeveloperOfInterest());
        csrfRequest
            .delete(
                makeRemoveDeveloperOfInterestUrl(parcel, manager, developer),
            )
            .then(() =>
                dispatch(
                    completeRemoveDeveloperOfInterest(
                        parcel,
                        manager,
                        developer,
                    ),
                ),
            )
            .catch(() => dispatch(failRemoveDeveloperOfInterest()));
    };
}

function startRemoveInterestedManager() {
    return {
        type: START_REMOVE_INTERESTED_MANAGER,
    };
}

function failRemoveInterestedManager() {
    return {
        type: FAIL_REMOVE_INTERESTED_MANAGER,
    };
}

function completeRemoveInterestedManager(developer, manager) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: { data },
            },
        } = getState();

        return dispatch({
            type: COMPLETE_REMOVE_INTERESTED_MANAGER,
            payload: dropRemovedDevelopers(manager, developer, data),
        });
    };
}

export function removeInterestedManager(developer, manager) {
    return dispatch => {
        dispatch(startRemoveInterestedManager());
        csrfRequest
            .delete(makeRemoveInterestedManagerUrl(developer, manager))
            .then(() =>
                dispatch(completeRemoveInterestedManager(developer, manager)),
            )
            .catch(() => dispatch(failRemoveInterestedManager()));
    };
}

function startRemoveManagerParcel() {
    return {
        type: START_REMOVE_MANAGER_PARCEL,
    };
}

function failRemoveManagerParcel() {
    return {
        type: FAIL_REMOVE_MANAGER_PARCEL,
    };
}

function completeRemoveManagerParcel(removedDevelopers, parcel) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: { data },
            },
        } = getState();

        const updatedManagerIndex = data.findIndex(({ parcels }) =>
            parcels.map(({ id }) => id).includes(parcel),
        );

        if (updatedManagerIndex === -1) {
            throw new Error('Invalid customer id');
        }

        return dispatch({
            type: COMPLETE_REMOVE_MANAGER_PARCEL,
            payload: [
                ...data.slice(0, updatedManagerIndex),
                Object.assign({}, data[updatedManagerIndex], {
                    developersOfInterest: data[
                        updatedManagerIndex
                    ].developersOfInterest.filter(
                        id => !removedDevelopers.includes(id),
                    ),
                    parcels: data[updatedManagerIndex].parcels.filter(
                        ({ id }) => id !== parcel,
                    ),
                }),
                ...data.slice(updatedManagerIndex + 1),
            ],
        });
    };
}

export function removeManagerParcel(parcel) {
    return dispatch => {
        dispatch(startRemoveManagerParcel());
        csrfRequest
            .delete(makeRemoveParcelUrl(parcel))
            .then(({ data }) =>
                dispatch(completeRemoveManagerParcel(data, parcel)),
            )
            .catch(() => dispatch(failRemoveManagerParcel()));
    };
}

export function hideErrorModal() {
    return {
        type: HIDE_ERROR_MODAL,
    };
}

function getNextDashboardSortDirection(currentDirection) {
    const { ascending, descending, none } = dashboardSortOrderEnum;

    switch (currentDirection) {
        case none:
        case descending:
            return ascending;
        case ascending:
            return descending;
        default:
            return none;
    }
}

export function sortManagerDashboardName() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: {
                    sortOrder: { name },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_MANAGER_DASHBOARD_NAME,
            payload: getNextDashboardSortDirection(name),
        });
    };
}

export function sortManagerDashboardEmail() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: {
                    sortOrder: { email },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_MANAGER_DASHBOARD_EMAIL,
            payload: getNextDashboardSortDirection(email),
        });
    };
}

export function sortManagerDashboardSignUpDate() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: {
                    sortOrder: { signupDate },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_MANAGER_DASHBOARD_SIGNUP_DATE,
            payload: getNextDashboardSortDirection(signupDate),
        });
    };
}

export function sortManagerDashboardPropertyCount() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: {
                    sortOrder: { propertyCount },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_MANAGER_DASHBOARD_PROPERTY_COUNT,
            payload: getNextDashboardSortDirection(propertyCount),
        });
    };
}

export function sortManagerDashboardInterestedCount() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: {
                    sortOrder: { interestedCount },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_MANAGER_DASHBOARD_INTERESTED_COUNT,
            payload: getNextDashboardSortDirection(interestedCount),
        });
    };
}

export function sortDeveloperDashboardCompany() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developers: {
                    sortOrder: { company },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_DEVELOPER_DASHBOARD_COMPANY,
            payload: getNextDashboardSortDirection(company),
        });
    };
}

export function sortDeveloperDashboardWebsite() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developers: {
                    sortOrder: { website },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_DEVELOPER_DASHBOARD_WEBSITE,
            payload: getNextDashboardSortDirection(website),
        });
    };
}

export function sortDeveloperDashboardSignUpDate() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developers: {
                    sortOrder: { signupDate },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_DEVELOPER_DASHBOARD_SIGNUP_DATE,
            payload: getNextDashboardSortDirection(signupDate),
        });
    };
}

export function sortDeveloperDashboardInterestedCount() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developers: {
                    sortOrder: { interestedCount },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_DEVELOPER_DASHBOARD_INTERESTED_COUNT,
            payload: getNextDashboardSortDirection(interestedCount),
        });
    };
}

export function sortDeveloperDashboardStatus() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                developers: {
                    sortOrder: { status },
                },
            },
        } = getState();

        return dispatch({
            type: SORT_DEVELOPER_DASHBOARD_STATUS,
            payload: getNextDashboardSortDirection(status),
        });
    };
}

export function updateParcelSearchValue(value) {
    return {
        type: UPDATE_PARCEL_SEARCH_VALUE,
        payload: value,
    };
}

export function searchParcel() {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                addParcel: { searchText },
            },
        } = getState();

        if (!searchText) {
            return null;
        }

        const trimmedValue = searchText.trim();

        return /^\d+$/.test(trimmedValue)
            ? dispatch(fetchSingleParcel(trimmedValue))
            : dispatch(performGeocoderSearch(trimmedValue));
    };
}

export function startAddManagerParcel() {
    return {
        type: START_ADD_MANAGER_PARCEL,
    };
}

export function failAddManagerParcel(e) {
    return {
        type: FAIL_ADD_MANAGER_PARCEL,
        payload: e,
    };
}

export function completeAddManagerParcel(managerId, parcelData) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: { data },
            },
        } = getState();

        const updatedManagerIndex = data.findIndex(
            ({ id }) => id === managerId,
        );

        if (updatedManagerIndex === -1) {
            throw new Error('Invalid customer id');
        }

        return dispatch({
            type: COMPLETE_ADD_MANAGER_PARCEL,
            payload: [
                ...data.slice(0, updatedManagerIndex),
                Object.assign({}, data[updatedManagerIndex], {
                    parcels: [
                        ...data[updatedManagerIndex].parcels,
                        mapManagerParcel(managerId, parcelData),
                    ],
                }),
                ...data.slice(updatedManagerIndex + 1),
            ],
        });
    };
}

function formatAddManagerParcelError(e) {
    if (e.response && e.response.data && e.response.data.parcel_id) {
        return 'This property is already associated with a customer.';
    }
    return 'An unepected error occurred while attempting to add the property.';
}

export function addManagerParcel(managerId, { Parcel: parcel }) {
    return dispatch => {
        dispatch(startAddManagerParcel());
        const parcelData = {
            parcel_id: parcel.ParcelID,
            property_manager: managerId,
            address: parcel.Address,
            building_type: parcel.BldgType,
        };
        csrfRequest
            .post(addManagerParcelUrl, parcelData)
            .then(({ data }) => {
                dispatch(completeAddManagerParcel(managerId, data));
            })
            .catch(e =>
                dispatch(failAddManagerParcel(formatAddManagerParcelError(e))),
            );
    };
}

function startSendDeveloperPasswordResetEmail() {
    return {
        type: START_SEND_DEVELOPER_PASSWORD_RESET_EMAIL,
    };
}

function failSendDeveloperPasswordResetEmail() {
    return {
        type: FAIL_SEND_DEVELOPER_PASSWORD_RESET_EMAIL,
    };
}

function completeSendDeveloperPasswordResetEmail() {
    return {
        type: COMPLETE_SEND_DEVELOPER_PASSWORD_RESET_EMAIL,
    };
}

export function sendDeveloperPasswordResetEmail(id) {
    return (dispatch, getState) => {
        dispatch(startSendDeveloperPasswordResetEmail());

        const {
            adminDashboardPage: {
                developers: { data },
            },
        } = getState();

        const { email } = data.find(
            ({ id: developerId }) => developerId === id,
        );

        return csrfRequest
            .post(resetDeveloperPasswordUrl, {
                email,
            })
            .then(() => dispatch(completeSendDeveloperPasswordResetEmail()))
            .catch(() => dispatch(failSendDeveloperPasswordResetEmail()));
    };
}

export function resetManagerTableFiltersAndSorts() {
    return {
        type: RESET_MANAGER_TABLE_FILTERS_AND_SORTS,
    };
}

export function resetDeveloperTableFiltersAndSorts() {
    return {
        type: RESET_DEVELOPER_TABLE_FILTERS_AND_SORTS,
    };
}

export function showAddParcelModal() {
    return {
        type: SHOW_ADD_PARCEL_MODAL,
    };
}

export function hideAddParcelModal() {
    return {
        type: HIDE_ADD_PARCEL_MODAL,
    };
}

export function updateGeocoderSearchText(payload) {
    return {
        type: UPDATE_GEOCODER_SEARCH_TEXT,
        payload,
    };
}

export function updateParcelRetrofits(retrofit) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                addParcel: { retrofits, developers },
                developers: { data: developerData },
            },
        } = getState();

        const updatedRetrofits = retrofits.includes(retrofit)
            ? retrofits.filter(r => r !== retrofit)
            : retrofits.concat(retrofit);

        const updatedDevelopers = developerData
            .filter(
                ({ id, retrofits: devRetrofits }) =>
                    developers.includes(id) &&
                    retrofits.some(r => devRetrofits.includes(r)),
            )
            .map(({ id }) => id);

        return dispatch({
            type: UPDATE_PARCEL_RETROFITS,
            payload: {
                updatedRetrofits,
                updatedDevelopers,
            },
        });
    };
}

export function handleSelectAllDevelopers(payload) {
    return {
        type: UPDATE_PARCEL_DEVELOPERS,
        payload,
    };
}

export function handleUnselectAllDevelopers() {
    return {
        type: UPDATE_PARCEL_DEVELOPERS,
        payload: [],
    };
}

export function updateParcelDevelopers(id) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                addParcel: { developers },
            },
        } = getState();

        return dispatch({
            type: UPDATE_PARCEL_DEVELOPERS,
            payload: developers.includes(id)
                ? developers.filter(developer => developer !== id)
                : developers.concat(id),
        });
    };
}

function startSaveAddedParcel() {
    return {
        type: START_SAVE_ADDED_PARCEL,
    };
}

function failSaveAddedParcel(e) {
    window.console.warn(e);

    return {
        type: FAIL_SAVE_ADDED_PARCEL,
    };
}

function completeSaveAddedParcel(parcel) {
    return (dispatch, getState) => {
        const {
            adminDashboardPage: {
                managers: { data },
            },
        } = getState();

        const updatedManagerIndex = data.findIndex(
            ({ id }) => id === parcel.property_manager,
        );

        if (updatedManagerIndex < 0) {
            throw new Error('Invalid customer id');
        }

        const payload = [
            ...data.slice(0, updatedManagerIndex),
            Object.assign({}, data[updatedManagerIndex], {
                parcels: [
                    ...data[updatedManagerIndex].parcels,
                    mapManagerParcel(parcel.property_manager, parcel),
                ],
                developersOfInterest: data[
                    updatedManagerIndex
                ].developersOfInterest
                    .concat(parcel.selected_developers)
                    .reduce(
                        (acc, next) =>
                            acc.concat(acc.includes(next) ? [] : next),
                        [],
                    ),
            }),
            ...data.slice(updatedManagerIndex + 1),
        ];

        return dispatch({
            type: COMPLETE_SAVE_ADDED_PARCEL,
            payload,
        });
    };
}

export function saveAddedParcel(managerId) {
    return (dispatch, getState) => {
        dispatch(startSaveAddedParcel());

        const {
            adminDashboardPage: {
                addParcel: {
                    data: {
                        Parcel: { Address, ParcelID, BldgType },
                    },
                    developers,
                    retrofits,
                },
            },
        } = getState();

        return csrfRequest
            .post(addManagerParcelUrl, {
                address: Address,
                parcel_id: ParcelID,
                building_type: BldgType,
                property_manager: managerId,
                selected_developers: developers,
                cistern: retrofits.includes('cistern'),
                green_roof: retrofits.includes('green roof'),
                permeable_pavement: retrofits.includes('permeable pavement'),
                subsurface_storage: retrofits.includes('subsurface storage'),
                rain_garden: retrofits.includes('rain garden'),
            })
            .then(({ data }) => dispatch(completeSaveAddedParcel(data)))
            .catch(e => dispatch(failSaveAddedParcel(e)));
    };
}

export function resetAdminDashboard() {
    return {
        type: RESET_ADMIN_DASHBOARD,
    };
}

function startDeleteManager() {
    return {
        type: START_DELETE_MANAGER,
    };
}

function failDeleteManager(e) {
    window.console.warn(e);

    return {
        type: FAIL_DELETE_MANAGER,
    };
}

function completeDeleteManager(history) {
    return dispatch => {
        history.push(DASHBOARD_ROUTES.customers);

        return dispatch({
            type: COMPLETE_DELETE_MANAGER,
        });
    };
}

export function deleteManager(deletedManagerID, history) {
    return dispatch => {
        dispatch(startDeleteManager());

        return csrfRequest
            .delete(makePropertyManagerEditUrl(deletedManagerID))
            .then(() => dispatch(completeDeleteManager(history)))
            .catch(e => dispatch(failDeleteManager(e)));
    };
}
