import csrfRequest from '../../../common/csrfRequest';

import { parcelDataApiUrl } from '../../constants';

import { makeParcelLatLngSearchAPIUrl } from './constants';

export const RESET_LAT_LNG_PARCEL_SEARCH = 'RESET_LAT_LNG_PARCEL_SEARCH';
export const START_LAT_LNG_PARCEL_SEARCH = 'START_LAT_LNG_PARCEL_SEARCH';
export const FAIL_LAT_LNG_PARCEL_SEARCH = 'FAIL_LAT_LNG_PARCEL_SEARCH';
export const COMPLETE_LAT_LNG_PARCEL_SEARCH = 'COMPLETE_LAT_LNG_PARCEL_SEARCH';

function startLatLngParcelSearch(payload) {
    return {
        type: START_LAT_LNG_PARCEL_SEARCH,
        payload,
    };
}

function failLatLngParcelSearch(e) {
    window.console.warn(e);

    return {
        type: FAIL_LAT_LNG_PARCEL_SEARCH,
    };
}

function completeLatLngParcelSearch(payload = null) {
    return {
        type: COMPLETE_LAT_LNG_PARCEL_SEARCH,
        payload,
    };
}

function maybeGetGSIDetails([parcel]) {
    return dispatch => {
        if (!parcel) {
            return dispatch(completeLatLngParcelSearch());
        }

        return csrfRequest
            .get(parcelDataApiUrl(parcel.Parcel.ParcelID))
            .then(({ data }) => dispatch(completeLatLngParcelSearch(data)))
            .catch(e => dispatch(failLatLngParcelSearch(e)));
    };
}

export function makeLatLngParcelSearch(latLng) {
    return dispatch => {
        dispatch(startLatLngParcelSearch(latLng));

        return csrfRequest
            .get(makeParcelLatLngSearchAPIUrl(latLng))
            .then(({ data }) => dispatch(maybeGetGSIDetails(data)))
            .catch(e => dispatch(failLatLngParcelSearch(e)));
    };
}

export function resetLatLngParcelSearch() {
    return {
        type: RESET_LAT_LNG_PARCEL_SEARCH,
    };
}
