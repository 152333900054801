import { formatOwner } from './utils';

const baseRetrofitObject = {
    cistern: null,
    green_roof: null,
    permeable_pavement: null,
    subsurface_storage: null,
    rain_garden: null,
};

export default class CSVMaker {
    static makeParcelMapForRow(
        parcel,
        {
            email = null,
            name = null,
            phone = null,
            note = null,
            retrofits = baseRetrofitObject,
        } = {},
    ) {
        return new Map([
            ['parcel_id', parcel.Parcel.ParcelID],
            ['address', parcel.Parcel.Address],
            ['bldg_type', parcel.Parcel.BldgType],
            ['owner', formatOwner(parcel.Parcel.Owner1, parcel.Parcel.Owner2)],
            ['monthly_stormwater_charge', parcel.Parcel.ParcelCharge],
            ['brt_account', parcel.Parcel.BRTAcct],
            ['gross_area', parcel.Parcel.FinalGross],
            ['impervious_area', parcel.Parcel.FinalImperv],
            ['impervious_ground_area', parcel.ImperviousGroundArea],
            ['impervious_roof_area', parcel.ImperviousRoofArea],
            ['existing_gsi', parcel.HasExistingGsi],
            ['cap_approved', parcel.IsCapApproved],
            ['interested', !!email],
            ['interested_name', name],
            ['interested_email', email],
            ['interested_phone', phone],
            ['interested_note', note],
            ['interested_cistern', retrofits.cistern],
            ['interested_green_roof', retrofits.green_roof],
            ['interested_permeable_pavement', retrofits.permeable_pavement],
            ['interested_rain_garden', retrofits.rain_garden],
            ['interested_subsurface_storage', retrofits.subsurface_storage],
        ]);
    }

    static formatParcelDataWithInterestedData(parcelData, interestedData) {
        return parcelData.map(parcel =>
            this.makeParcelMapForRow(
                parcel,
                interestedData.find(
                    ({ parcelId }) => parcelId === parcel.Parcel.ParcelID,
                ),
            ),
        );
    }

    static createCSVStringWithHeaders(parcelDataWithInterested) {
        const csvHeaders = [...parcelDataWithInterested[0].keys()];
        const csvData = parcelDataWithInterested
            .map(row => [...row.values()])
            .slice()
            .sort(([first], [second]) => first - second);

        return [csvHeaders, ...csvData].map(row => row.join(',')).join('\n');
    }

    static saveCSV(data) {
        const fileName = `retrofitmap-data-${new Date(
            Date.now(),
        ).toISOString()}.csv`;
        const blob = new Blob([data]);

        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName);
            return null;
        }

        const type = { type: 'text/csv' };
        const a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(blob, type);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return null;
    }
}
