import axios, { CancelToken } from 'axios';

import selectEligibleParcels from '../common/utils';

import {
    NO_RESULTS_MESSAGE,
    NETWORK_ERROR_MESSAGE,
    CANCEL_REQUEST_MESSAGE,
} from './constants';

import makeGeocoderUrl from './utils';

import { maybeGet } from '../marketing/utils';

import {
    updateSubmittingControl,
    updateSubmittedQuery,
} from '../marketing/actions';

let cancelAxiosRequest = null;

export const START_GEOCODER_SEARCH = 'START_GEOCODER_SEARCH';
export const RECEIVE_GEOCODER_RESULTS = 'RECEIVE_GEOCODER_RESULTS';
export const SELECT_GEOCODER_RESULT = 'SELECT_GEOCODER_RESULT';
export const FAIL_GEOCODER_SEARCH = 'FAIL_GEOCODER_SEARCH';
export const CLEAR_GEOCODER_SEARCH = 'CLEAR_GEOCODER_SEARCH';

function cancelPriorRequest() {
    if (cancelAxiosRequest) {
        cancelAxiosRequest(CANCEL_REQUEST_MESSAGE);
        cancelAxiosRequest = null;
    }
}

function startGeocoderSearch(payload) {
    return dispatch => {
        const section = maybeGet(payload, 'uiSection');
        const query = maybeGet(payload, 'query');
        if (section) {
            dispatch(updateSubmittingControl(section));
        }

        if (query) {
            dispatch(updateSubmittedQuery(query));
        }

        return dispatch({
            type: START_GEOCODER_SEARCH,
        });
    };
}

function receiveGeocoderResults(payload) {
    return {
        type: RECEIVE_GEOCODER_RESULTS,
        payload,
    };
}

export function selectGeocoderResult(payload) {
    return {
        type: SELECT_GEOCODER_RESULT,
        payload,
    };
}

function failGeocoderSearch(payload) {
    return dispatch => {
        dispatch(updateSubmittedQuery(null));

        return dispatch({
            type: FAIL_GEOCODER_SEARCH,
            payload,
        });
    };
}

function parseGeocoderResults([first, ...rest]) {
    return dispatch => {
        if (!first) {
            return dispatch(failGeocoderSearch(NO_RESULTS_MESSAGE));
        }
        if (first && rest.length) {
            return dispatch(receiveGeocoderResults([first, ...rest]));
        }

        return dispatch(selectGeocoderResult(first));
    };
}

function clearGeocoder() {
    return {
        type: CLEAR_GEOCODER_SEARCH,
    };
}

export function clearGeocoderSearch() {
    cancelPriorRequest();
    return (dispatch, getState) => {
        const {
            geocoder: { selectedResult, results },
        } = getState();

        if (selectedResult || results) {
            dispatch(clearGeocoder());
        }
    };
}

export function performGeocoderSearch(query, params) {
    cancelPriorRequest();
    return dispatch => {
        dispatch(startGeocoderSearch(params));
        axios
            .get(makeGeocoderUrl(query), {
                cancelToken: new CancelToken(c => {
                    cancelAxiosRequest = c;
                }),
            })
            .then(({ data }) => data.filter(({ Parcel }) => Parcel))
            .then(data => selectEligibleParcels(data))
            .then(results => dispatch(parseGeocoderResults(results)))
            .catch(() => dispatch(failGeocoderSearch(NETWORK_ERROR_MESSAGE)));
    };
}
