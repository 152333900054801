import csrfRequest from '../../../common/csrfRequest';

import { apiError, resetPasswordUrl, changePasswordUrl } from './constants';

export const SET_UID = 'SET_UID';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_NEW_PASSWORD_ONE = 'SET_NEW_PASSWORD_ONE';
export const SET_NEW_PASSWORD_TWO = 'SET_NEW_PASSWORD_TWO';
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const FAIL_RESET_PASSWORD = 'FAIL_RESET_PASSWORD';
export const COMPLETE_RESET_PASSWORD = 'COMPLETE_RESET_PASSWORD';
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD';

export function setUID(payload) {
    return {
        type: SET_UID,
        payload,
    };
}

export function setToken(payload) {
    return {
        type: SET_TOKEN,
        payload,
    };
}

export function setNewPasswordOne(payload) {
    return {
        type: SET_NEW_PASSWORD_ONE,
        payload,
    };
}

export function setNewPasswordTwo(payload) {
    return {
        type: SET_NEW_PASSWORD_TWO,
        payload,
    };
}

function startResetPassword() {
    return {
        type: START_RESET_PASSWORD,
    };
}

function failResetPassword(payload = apiError) {
    return {
        type: FAIL_RESET_PASSWORD,
        payload,
    };
}

function completeResetPassword(payload) {
    return {
        type: COMPLETE_RESET_PASSWORD,
        payload,
    };
}

export function resetPassword() {
    return (dispatch, getState) => {
        dispatch(startResetPassword);

        const {
            auth: { password, signedIn, id: vendorId },
            resetPasswordPage: { newPasswordOne, newPasswordTwo, uid, token },
        } = getState();

        const resetFromSettingsPage = signedIn && vendorId && password;

        const changePasswordRequest = resetFromSettingsPage
            ? csrfRequest.post(changePasswordUrl, {
                  old_password: password,
                  new_password1: newPasswordOne,
                  new_password2: newPasswordTwo,
              })
            : csrfRequest.post(resetPasswordUrl, {
                  uid,
                  token,
                  new_password1: newPasswordOne,
                  new_password2: newPasswordTwo,
              });

        return changePasswordRequest
            .then(() => dispatch(completeResetPassword(newPasswordOne)))
            .catch(({ response: { data: err } }) =>
                dispatch(failResetPassword(err)),
            );
    };
}

export function clearResetPassword() {
    return {
        type: CLEAR_RESET_PASSWORD,
    };
}
