import { combineReducers } from 'redux';

import {
    START_DEVELOPER_SIGN_IN,
    FAIL_DEVELOPER_SIGN_IN,
    COMPLETE_DEVELOPER_SIGN_IN,
    COMPLETE_DEVELOPER_SIGN_OUT,
    RESET_RETROFIT_MAP,
} from '../../actions';

import {
    SHOW_SIGN_IN,
    SHOW_SIGN_UP,
    SHOW_FORGOT_PASSWORD,
    START_SUBMITTING_ONBOARDING_FORM,
    FAIL_SUBMITTING_ONBOARDING_FORM,
    COMPLETE_SUBMITTING_ONBOARDING_FORM,
    UPDATE_ONBOARDING_FORM_FIELD,
    SET_ONBOARDING_FORM_ERRORS,
    SET_ONBOARDING_SIGN_UP_FORM_ERRORS,
    TOGGLE_ONBOARDING_FORM_FIELD,
    TOGGLE_SHOW_PASSWORD,
    TOGGLE_SHOW_CONFIRM_PASSWORD,
    SHOW_TERMS_OF_USE_MODAL,
    HIDE_TERMS_OF_USE_MODAL,
    UPDATE_DEVELOPER_AUTH_EMAIL,
    UPDATE_DEVELOPER_AUTH_PASSWORD,
    START_SEND_RESET_PASSWORD_EMAIL,
    FAIL_SEND_RESET_PASSWORD_EMAIL,
    COMPLETE_SEND_RESET_PASSWORD_EMAIL,
    UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS,
    DISPLAY_FORGOT_PASSWORD_EMAIL_VALIDATION_ERROR,
    SET_TOS_ERROR,
    COMPLETE_VENDOR_SIGN_IN,
    CREATE_UPDATE_FIELDS_SNAPSHOT,
    RESET_FIELDS_TO_FIELDS_SNAPSHOT,
    RESET_UPDATE_PROFILE,
} from './actions';

import {
    onboardingFormFields,
    signInError,
    signInBoxStatesEnum,
} from './constants';

const initialPageState = {
    submitting: false,
    error: null,
    showPassword: false,
    showConfirmPassword: false,
    displayTermsOfUseModal: false,
    signInBoxState: signInBoxStatesEnum.showSignUp,
    resetPassword: {
        validationError: false,
        fetching: false,
        error: false,
        success: false,
        email: '',
    },
};

const initialFieldsState = {
    [onboardingFormFields.EMAIL]: '',
    [onboardingFormFields.PASSWORD]: '',
    [onboardingFormFields.CONFIRM_PASSWORD]: '',
    [onboardingFormFields.TOS]: false,
    [onboardingFormFields.NAME]: '',
    [onboardingFormFields.PHONE]: '',
    [onboardingFormFields.USE_COMPANY_CONTACT]: true,
    [onboardingFormFields.COMPANY_NAME]: '',
    [onboardingFormFields.COMPANY_EMAIL]: '',
    [onboardingFormFields.COMPANY_PHONE]: '',
    [onboardingFormFields.COMPANY_WEBSITE]: '',
    [onboardingFormFields.RAIN_GARDEN]: false,
    [onboardingFormFields.SUBSURFACE_STORAGE]: false,
    [onboardingFormFields.GREEN_ROOF]: false,
    [onboardingFormFields.PERMEABLE_PAVEMENT]: false,
    [onboardingFormFields.CISTERN]: false,
    [onboardingFormFields.OTHER_RETROFIT]: false,
    [onboardingFormFields.OTHER_RETROFIT_TEXT]: '',
    [onboardingFormFields.EIN]: '',
    [onboardingFormFields.COMMERCIAL_ACTIVITY_LICENSE]: '',
    [onboardingFormFields.SHOULD_EMAIL_PARCEL_UPDATES]: false,
    [onboardingFormFields.HAS_WORKED_ON_GRANT]: false,
    [onboardingFormFields.HAS_BEEN_SUBGRANTEE]: false,
    [onboardingFormFields.IS_DESIGN_FIRM]: false,
    [onboardingFormFields.IS_CONSTRUCTION_FIRM]: false,
    [onboardingFormFields.IS_MAINTENANCE_FIRM]: false,
    [onboardingFormFields.IS_MANAGEMENT_FIRM]: false,
    [onboardingFormFields.HAS_READ_TOS]: false,
};

const initialValidationState = {
    [onboardingFormFields.EMAIL]: [],
    [onboardingFormFields.PASSWORD]: [],
    [onboardingFormFields.CONFIRM_PASSWORD]: [],
    [onboardingFormFields.NAME]: [],
    [onboardingFormFields.TOS]: [],
    [onboardingFormFields.COMPANY_NAME]: [],
    [onboardingFormFields.COMPANY_EMAIL]: [],
    [onboardingFormFields.COMPANY_PHONE]: [],
    [onboardingFormFields.EIN]: [],
    [onboardingFormFields.COMPANY_WEBSITE]: [],
    [onboardingFormFields.HAS_SELECTED_AT_LEAST_ONE_FIRM_TYPE]: [],
    [onboardingFormFields.HAS_READ_TOS]: [],
};

const initialUpdateProfileState = {
    unsavedChanges: false,
    fieldsSnapshot: null,
};

function page(state = initialPageState, { type, payload }) {
    switch (type) {
        case RESET_RETROFIT_MAP:
            return initialPageState;
        case SHOW_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                displayTermsOfUseModal: true,
            });
        case HIDE_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                displayTermsOfUseModal: false,
            });
        case SHOW_SIGN_IN:
            return Object.assign({}, state, {
                error: null,
                signInBoxState: signInBoxStatesEnum.showSignIn,
            });
        case SHOW_SIGN_UP:
            return Object.assign({}, state, {
                signInBoxState: signInBoxStatesEnum.showSignUp,
            });
        case SHOW_FORGOT_PASSWORD:
            return Object.assign({}, state, {
                signInBoxState: signInBoxStatesEnum.showForgotPassword,
            });
        case DISPLAY_FORGOT_PASSWORD_EMAIL_VALIDATION_ERROR:
            return Object.assign({}, state, {
                resetPassword: Object.assign({}, state.resetPassword, {
                    validationError: true,
                    fetching: false,
                }),
            });
        case UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS:
            return Object.assign({}, state, {
                resetPassword: Object.assign({}, state.resetPassword, {
                    validationError: false,
                    email: payload,
                }),
            });
        case START_SEND_RESET_PASSWORD_EMAIL:
            return Object.assign({}, state, {
                resetPassword: Object.assign({}, state.resetPassword, {
                    fetching: true,
                    error: false,
                    success: false,
                }),
            });
        case FAIL_SEND_RESET_PASSWORD_EMAIL:
            return Object.assign({}, state, {
                resetPassword: Object.assign({}, state.resetPassword, {
                    fetching: false,
                    error: true,
                    email: '',
                }),
            });
        case COMPLETE_SEND_RESET_PASSWORD_EMAIL:
            return Object.assign({}, state, {
                resetPassword: Object.assign({}, state.resetPassword, {
                    fetching: false,
                    error: false,
                    success: true,
                    email: '',
                }),
            });
        case START_SUBMITTING_ONBOARDING_FORM:
            return Object.assign({}, state, {
                submitting: true,
                error: null,
            });
        case FAIL_SUBMITTING_ONBOARDING_FORM:
            return Object.assign({}, state, {
                submitting: false,
                error: payload,
            });
        case COMPLETE_SUBMITTING_ONBOARDING_FORM:
            return Object.assign({}, state, {
                submitting: false,
            });
        case TOGGLE_SHOW_PASSWORD:
            return Object.assign({}, state, {
                showPassword: !state.showPassword,
            });
        case TOGGLE_SHOW_CONFIRM_PASSWORD:
            return Object.assign({}, state, {
                showConfirmPassword: !state.showConfirmPassword,
            });
        case START_DEVELOPER_SIGN_IN:
            return Object.assign({}, state, {
                submitting: true,
                error: null,
            });
        case FAIL_DEVELOPER_SIGN_IN:
            return Object.assign({}, state, {
                submitting: false,
                error: signInError,
            });
        case COMPLETE_DEVELOPER_SIGN_IN:
        case UPDATE_DEVELOPER_AUTH_EMAIL:
        case UPDATE_DEVELOPER_AUTH_PASSWORD:
            return Object.assign({}, state, {
                submitting: false,
                error: null,
            });
        default:
            return state;
    }
}

function fields(state = initialFieldsState, { type, payload }) {
    switch (type) {
        case UPDATE_ONBOARDING_FORM_FIELD:
            return Object.assign({}, state, {
                [payload.field]: payload.value,
            });
        case TOGGLE_ONBOARDING_FORM_FIELD:
            return Object.assign({}, state, {
                [payload.field]: !state[payload.field],
            });
        case SHOW_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                [onboardingFormFields.HAS_READ_TOS]: true,
            });
        case RESET_FIELDS_TO_FIELDS_SNAPSHOT:
        case COMPLETE_VENDOR_SIGN_IN:
            return Object.assign({}, state, payload);
        case COMPLETE_DEVELOPER_SIGN_OUT:
            return initialFieldsState;
        default:
            return state;
    }
}

function updateProfile(state = initialUpdateProfileState, { type, payload }) {
    switch (type) {
        case TOGGLE_ONBOARDING_FORM_FIELD:
        case UPDATE_ONBOARDING_FORM_FIELD:
            return Object.assign({}, state, { unsavedChanges: true });
        case CREATE_UPDATE_FIELDS_SNAPSHOT:
            return Object.assign({}, state, { fieldsSnapshot: payload });
        case RESET_FIELDS_TO_FIELDS_SNAPSHOT:
            return Object.assign({}, state, { unsavedChanges: false });
        case COMPLETE_SUBMITTING_ONBOARDING_FORM:
            return Object.assign({}, state, {
                unsavedChanges: false,
                fieldsSnapshot: payload,
            });
        case COMPLETE_DEVELOPER_SIGN_OUT:
        case RESET_UPDATE_PROFILE:
            return initialUpdateProfileState;
        default:
            return state;
    }
}

function validation(state = initialValidationState, { type, payload }) {
    switch (type) {
        case START_SUBMITTING_ONBOARDING_FORM:
            return initialValidationState;
        case SET_ONBOARDING_FORM_ERRORS:
        case SET_ONBOARDING_SIGN_UP_FORM_ERRORS:
        case FAIL_SUBMITTING_ONBOARDING_FORM:
            return Object.assign({}, state, payload);
        case SHOW_TERMS_OF_USE_MODAL:
            return Object.assign({}, state, {
                [onboardingFormFields.HAS_READ_TOS]: [],
            });
        case SET_TOS_ERROR:
            return Object.assign({}, state, {
                [onboardingFormFields.HAS_READ_TOS]: [
                    'Terms of use must be read to proceed with signing up for Stormwater Connect.',
                ],
            });
        case RESET_FIELDS_TO_FIELDS_SNAPSHOT:
            return initialValidationState;
        default:
            return state;
    }
}

export default combineReducers({
    page,
    fields,
    updateProfile,
    validation,
});
