import { arrayOf, number, shape, string } from 'prop-types';

import { retrofits } from '../../constants';

import { API_BASE_URL } from '../../../developer/constants';

export function makePreferencesUrl(uuid) {
    return `${API_BASE_URL}/customers/preferences/?token=${uuid}`;
}

export const unsubscribeParcelPropType = arrayOf(
    shape({
        parcel_id: number,
        address: string,
        property_manager: number,
    }),
);

export const allRetrofitsUnselected = Object.entries(
    retrofits.map(({ type }) => type),
).reduce(
    (acc, [, key]) =>
        Object.assign({}, acc, {
            [key]: false,
        }),
    {},
);

export const successToastMessages = {
    remove: 'Successfully removed the property',
    edit: 'Successfully edited the property',
};
