export const initialMapCenter = [39.961352, -75.154334];
export const initialMapZoom = 13;

export const BASE_URL = '/stormwater/connect';
export const API_BASE_URL = `${BASE_URL}/api`;

export const retrofitMapLoginUrl = `${API_BASE_URL}/vendor-login/`;
export const retrofitMapLogoutUrl = `${API_BASE_URL}/vendor-logout/`;

export const confirmEmailUrl = `${API_BASE_URL}/rest-auth/registration/verify-email/`;
export const resetDeveloperPasswordUrl = `${API_BASE_URL}/rest-auth/password/reset/`;

// `/api/pwd` is the path to the Django proxy endpoint.
// `pwd/parcels` is the PWD API endpoint for searching parcels.
export const parcelsAPIUrl = `${API_BASE_URL}/pwd/pwd/parcels/`;

export function parcelDataApiUrl(id) {
    return `${API_BASE_URL}/pwd/parcel/${id}/gsi-details`;
}

export const defaultRequestedParcelsCount = 200;

export const savedParcelsUrl = `${API_BASE_URL}/parcels/saved/`;
export const interestedParcelsUrl = `${API_BASE_URL}/parcels/interested/`;
export const enrolledParcelsUrl = `${API_BASE_URL}/parcels/enrolled/`;

export function changeParcelSaveStatusUrl(id) {
    return `${API_BASE_URL}/parcels/${id}/save/`;
}

export const parcelsAndImperviousLayersURL =
    'https://stormwater.phila.gov/arcgis/rest/services/parcel_viewer/pv_data_WM/MapServer';

export const parcelsAndImperviousMinimumZoomLevel = 17;

export const combinedSewerOverflowLayerURL =
    'https://stormwater.phila.gov/arcgis/rest/services/parcel_viewer/pv_cso_WM/MapServer/0';

export const combinedSewerOverflowMinimumZoomLevel = 6;

export const cityCartoBasemap =
    'https://tiles.arcgis.com/tiles/fLeGjb7u4uXqeF9q/arcgis/rest/services/CityBasemap/MapServer';

export const cityCartoLabelsBasemap =
    'https://tiles.arcgis.com/tiles/fLeGjb7u4uXqeF9q/arcgis/rest/services/CityBasemap_Labels/MapServer';

export const basemapsEnum = {
    cityCartoBasemap: 'cityCartoBasemap',
    aerial: 'aerial',
};

export const searchMapCityLimitsExtent = [
    [-75.2803067548921945, 39.8674718597080968],
    [-74.9557485615157049, 40.137934841110301],
];

export const initialSearchMapZoom = 13;
export const initialSearchMapCenter = {
    lat: 39.9524,
    lng: -75.1636,
};
export const minimumSearchMapPolygonZoomLevel = 16;
export const circleMarkerRadius = 6;
export const parcelVectorColor = '#0078c8';
export const highlightedParcelVectorColor = '#f26c51';
export const whiteVectorColor = '#ffffff';

export const latLngSearchCircleMarkerVectorStyle = {
    color: '#f37720',
    radius: 10,
};

export const successfulAISGeocoderSearchZoom = 17;

export const NONRES = 'nonres';
export const CONDOS = 'condos';
export const CAP = 'cap';
export const GSI = 'gsi';

export const developerStatusTypes = {
    pending: 0,
    denied: 1,
    active: 2,
    deactivated: 3,
};

export const termsOfUseModalStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(62, 69, 77, 0.65)',
        zIndex: 11000,
    },
};

export const refinementHintEmphasisThresholdMin = 3;
export const refinementHintEmphasisThresholdMax = 40;

export const errorMessages = {
    failLoadSingleParcelMessage: 'Unable to load that parcel',
    failLoadMultipleParcelsMessage: `Unable to load parcel data, due to a
        problem with our server or the network. Try again later.`,
    failLoadSavedParcelsMessage: `Unable to load saved parcels, due to a
        problem with our server or the network. Try again later.`,
    failLoadInterestedParcelsMessage: `Unable to load interested parcels, due to a problem
        with our server or the network. Try again later.`,
    failSaveParcelMessage: `Unable to save that parcel, due to a problem
        with our server or the network. Try again later.`,
    failUnsaveParcelMessage: `Unable to un-save that parcel, due to a problem
        with our server or the network. Try again later.`,
    failAISGeocoderSearch:
        'Unable to find an address or intersection for that search.',
    failGenerateCSV: 'Unable to export a CSV',
    defaultErrorMessage: 'An unknown error occurred',
};

export const VENDOR_BASE_URL = `${BASE_URL}/vendor`;
export const DASHBOARD_BASE_URL = `${BASE_URL}/dashboard`;
export const CUSTOMER_BASE_URL = `${BASE_URL}/customer`;

export const VENDOR_PROPERTY_URL = `${VENDOR_BASE_URL}/property`;
export const VENDOR_SETTINGS_URL = `${VENDOR_BASE_URL}/settings`;

export const ROUTES = {
    welcome: `${VENDOR_BASE_URL}/welcome`,
    staytuned: `${VENDOR_BASE_URL}/welcome/staytuned`,
    profile: `${VENDOR_BASE_URL}/welcome/profile`,
    enter: `${VENDOR_BASE_URL}/welcome#enter`,
    denied: `${VENDOR_BASE_URL}/welcome/denied`,
    deactivated: `${VENDOR_BASE_URL}/welcome/deactivated`,
};
