import axios, { CancelToken } from 'axios';
import makeAISSearchURL from './utils';
import { fetchParcels } from '../../actions';
import { VENDOR_BASE_URL } from '../../constants';

export const START_AIS_GEOCODER_SEARCH = 'START_AIS_GEOCODER_SEARCH';
export const FAIL_AIS_GEOCODER_SEARCH = 'FAIL_AIS_GEOCODER_SEARCH';
export const COMPLETE_AIS_GEOCODER_SEARCH = 'COMPLETE_AIS_GEOCODER_SEARCH';
export const CLEAR_AIS_GEOCODER_DATA = 'CLEAR_AIS_GEOCODER_DATA';
export const REFETCH_ON_EMPTY_QUERY = 'REFETCH_ON_EMPTY_QUERY';
export const CHANGE_AIS_GEOCODER_SEARCH_QUERY =
    'CHANGE_AIS_GEOCODER_SEARCH_QUERY';
export const UPDATE_AIS_GEOCODER_FIT_BOUNDS = 'UPDATE_AIS_GEOCODER_FIT_BOUNDS';
export const COMPLETE_FETCH_PARCEL_DATA = 'COMPLETE_FETCH_PARCEL_DATA';

let cancelPriorAISGeocoderRequest = null;

export function maybeCancelPriorAISGeocoderRequest() {
    if (!cancelPriorAISGeocoderRequest) {
        return null;
    }

    cancelPriorAISGeocoderRequest('Canceling prior AIS geocoder request');
    cancelPriorAISGeocoderRequest = null;

    return null;
}

function startAISGeocoderSearch() {
    return {
        type: START_AIS_GEOCODER_SEARCH,
    };
}

function failAISGeocoderSearch() {
    return {
        type: FAIL_AIS_GEOCODER_SEARCH,
    };
}

function completeAISGeocoderSearch(payload, history) {
    if (history && history.location !== VENDOR_BASE_URL) {
        history.push(VENDOR_BASE_URL);
    }
    return {
        type: COMPLETE_AIS_GEOCODER_SEARCH,
        payload,
    };
}

export function updateAISGeocoderFitBounds(payload) {
    return {
        type: UPDATE_AIS_GEOCODER_FIT_BOUNDS,
        payload,
    };
}

function formatCoordinatesFromAISGeocoderResult({
    search_type: searchType,
    features: [
        {
            geometry: {
                coordinates: [lng, lat],
            },
            properties: {
                li_parcel_id: liParcelID,
                pwd_parcel_id: pwdParcelID,
            },
        },
    ],
}) {
    let parcels = [liParcelID, pwdParcelID].filter(p => !!p);
    if (!parcels.length) {
        parcels = null;
    }
    return {
        isNotIntersection: searchType !== 'intersection',
        coordinates: { lng, lat },
        parcels,
    };
}

export function clearAISGeocoderData() {
    return {
        type: CLEAR_AIS_GEOCODER_DATA,
    };
}

export function performAISGeocoderSearch(history) {
    return (dispatch, getState) => {
        maybeCancelPriorAISGeocoderRequest();
        dispatch(startAISGeocoderSearch());

        const {
            aisGeocoder: { query },
        } = getState();

        if (!query || !query.trim().length) {
            dispatch(clearAISGeocoderData());
            return dispatch(fetchParcels());
        }

        return axios
            .get(makeAISSearchURL(query), {
                cancelToken: new CancelToken(c => {
                    cancelPriorAISGeocoderRequest = c;
                }),
            })
            .then(({ data }) => formatCoordinatesFromAISGeocoderResult(data))
            .then(data => dispatch(completeAISGeocoderSearch(data, history)))
            .catch(() => dispatch(failAISGeocoderSearch()));
    };
}

export function refetchOnEmptyQuery() {
    return dispatch => {
        dispatch(performAISGeocoderSearch());
        return dispatch({
            type: REFETCH_ON_EMPTY_QUERY,
        });
    };
}

export function changeAISGeocoderSearchQuery(payload) {
    return (dispatch, getState) => {
        const {
            aisGeocoder: { data },
        } = getState();
        if (data && !payload) {
            dispatch(clearAISGeocoderData());
        }
        return dispatch({
            type: CHANGE_AIS_GEOCODER_SEARCH_QUERY,
            payload,
        });
    };
}
