import csrfRequest from '../../../common/csrfRequest';
import { clearGeocoderSearch } from '../../../geocoder/actions';
import { toggleGeocoder } from '../../actions';
import { uiSections } from '../../constants';

import { makePreferencesUrl, allRetrofitsUnselected } from './constants';

export const START_FETCH_PREFERENCES_DATA = 'START_FETCH_PREFERENCES_DATA';
export const COMPLETE_FETCH_PREFERENCES_DATA =
    'COMPLETE_FETCH_PREFERENCES_DATA';
export const FAIL_FETCH_PREFERENCES_DATA = 'FAIL_FETCH_PREFERENCES_DATA';
export const STORE_UUID = 'STORE_UUID';
export const START_UNSUBSCRIBE_PARCEL = 'START_UNSUBSCRIBE_PARCEL';
export const COMPLETE_UNSUBSCRIBE_PARCEL = 'COMPLETE_UNSUBSCRIBE_PARCEL';
export const FAIL_UNSUBSCRIBE_PARCEL = 'FAIL_UNSUBSCRIBE_PARCEL';
export const START_UPDATE_EDITED_PARCEL = 'START_UPDATE_EDITED_PARCEL';
export const COMPLETE_UPDATE_EDITED_PARCEL = 'COMPLETE_UPDATE_EDITED_PARCEL';
export const FAIL_UPDATE_EDITED_PARCEL = 'FAIL_UPDATE_EDITED_PARCEL';

export const SELECT_GEOCODER_RESULT = 'SELECT_GEOCODER_RESULT';
export const SHOW_EDIT_PREFERENCES_MODAL = 'SHOW_EDIT_PREFERENCES_MODAL';
export const HIDE_EDIT_PREFERENCES_MODAL = 'HIDE_EDIT_PREFERENCES_MODAL';
export const SHOW_CONFIRM_UNSUBSCRIBE_MODAL = 'SHOW_CONFIRM_UNSUBSCRIBE_MODAL';
export const HIDE_CONFIRM_UNSUBSCRIBE_MODAL = 'HIDE_CONFIRM_UNSUBSCRIBE_MODAL';

export const TOGGLE_RETROFIT_PREFERENCE = 'TOGGLE_RETROFIT_PREFERENCE';
export const TOGGLE_RETROFIT_PREFERENCE_AND_UPDATE_DEVELOPERS =
    'TOGGLE_RETROFIT_PREFERENCE_AND_UPDATE_DEVELOPERS';
export const CHANGE_SELECTED_DEVELOPERS = 'CHANGE_SELECTED_DEVELOPERS';
export const TOGGLE_ACCEPTS_CONTACTS = 'TOGGLE_ACCEPTS_CONTACTS';

export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const HIDE_SUCCESS_TOAST = 'HIDE_SUCCESS_TOAST';

function startFetchPreferencesData() {
    return {
        type: START_FETCH_PREFERENCES_DATA,
    };
}

function completeFetchPreferencesData(payload) {
    return {
        type: COMPLETE_FETCH_PREFERENCES_DATA,
        payload,
    };
}

function failFetchPreferencesData() {
    return {
        type: FAIL_FETCH_PREFERENCES_DATA,
    };
}

function storeUUID(payload) {
    return {
        type: STORE_UUID,
        payload,
    };
}

export function fetchPreferencesData(uuid) {
    return dispatch => {
        dispatch(storeUUID(uuid));
        dispatch(startFetchPreferencesData());

        return csrfRequest
            .get(makePreferencesUrl(uuid))
            .then(({ data }) => dispatch(completeFetchPreferencesData(data)))
            .catch(() => dispatch(failFetchPreferencesData()));
    };
}

function startUnsubscribeParcel() {
    return {
        type: START_UNSUBSCRIBE_PARCEL,
    };
}

function completeUnsubscribeParcel({ parcel_id: parcelId }) {
    return (dispatch, getState) => {
        const {
            customers: {
                preferences: {
                    parcels: { data },
                },
            },
            geocoder: { selectedResult },
        } = getState();

        const payload = data.filter(({ parcel_id: id }) => id !== parcelId);

        if (selectedResult) {
            dispatch(clearGeocoderSearch());
            dispatch(toggleGeocoder(uiSections.PreferencesForm));
        }

        return dispatch({
            type: COMPLETE_UNSUBSCRIBE_PARCEL,
            payload,
        });
    };
}

function failUnsubscribeParcel() {
    return {
        type: FAIL_UNSUBSCRIBE_PARCEL,
    };
}

export function unsubscribeParcel(parcelId) {
    return (dispatch, getState) => {
        dispatch(startUnsubscribeParcel());
        const {
            customers: {
                preferences: {
                    uuid,
                    parcels: { data },
                },
            },
        } = getState();

        const unsubscribedParcel = data.find(
            ({ parcel_id: id }) => parcelId === id,
        );

        csrfRequest
            .delete(makePreferencesUrl(uuid), {
                data: {
                    parcel_ids: [Number(parcelId)],
                },
            })
            .then(() => dispatch(completeUnsubscribeParcel(unsubscribedParcel)))
            .catch(() => dispatch(failUnsubscribeParcel()));
    };
}

export function showConfirmUnsubscribeModal(payload) {
    return {
        type: SHOW_CONFIRM_UNSUBSCRIBE_MODAL,
        payload,
    };
}

export function hideConfirmUnsubscribeModal() {
    return {
        type: HIDE_CONFIRM_UNSUBSCRIBE_MODAL,
    };
}

export function showEditPreferencesModal(parcelId) {
    return (dispatch, getState) => {
        const {
            customers: {
                preferences: {
                    parcels: { data },
                },
            },
        } = getState();

        return dispatch({
            type: SHOW_EDIT_PREFERENCES_MODAL,
            payload: data.find(({ parcel_id: id }) => parcelId === id),
        });
    };
}

export function hideEditPreferencesModal() {
    return {
        type: HIDE_EDIT_PREFERENCES_MODAL,
    };
}

function mapRetrofitPreferencesToList({
    cistern,
    green_roof,
    subsurface_storage,
    permeable_pavement,
    rain_garden,
}) {
    return Object.entries({
        cistern,
        green_roof,
        subsurface_storage,
        permeable_pavement,
        rain_garden,
    }).reduce((acc, [key, val]) => acc.concat(val ? key : []), []);
}

export function toggleRetrofitPreference(sender = null) {
    return (dispatch, getState) => {
        const {
            customers: {
                preferences: {
                    editPreferencesModal: { editableParcel },
                },
                main: {
                    developers: { data: developerData },
                },
            },
        } = getState();

        if (!sender) {
            return dispatch({
                type: TOGGLE_RETROFIT_PREFERENCE,
                payload: Object.assign(
                    {},
                    ...[editableParcel, allRetrofitsUnselected],
                ),
            });
        }

        const parcelWithUpdatedRetrofits = Object.assign({}, editableParcel, {
            [sender]: !editableParcel[sender],
        });

        const updatedRetrofits = mapRetrofitPreferencesToList(
            parcelWithUpdatedRetrofits,
        );

        const updatedDevelopers = editableParcel.selected_developers.filter(
            developerId => {
                if (!updatedRetrofits.length) {
                    return true;
                }

                const developer = developerData.find(
                    ({ id }) => id === developerId,
                );

                return developer
                    ? updatedRetrofits.some(r =>
                          developer.retrofits.includes(r),
                      )
                    : false;
            },
        );

        const payload = Object.assign({}, parcelWithUpdatedRetrofits, {
            selected_developers: updatedDevelopers,
        });

        return dispatch({
            type: TOGGLE_RETROFIT_PREFERENCE_AND_UPDATE_DEVELOPERS,
            payload,
        });
    };
}

export function changeSelectedDevelopers(id = null) {
    return (dispatch, getState) => {
        const {
            customers: {
                preferences: {
                    editPreferencesModal: { editableParcel },
                },
            },
        } = getState();

        const payload = Object.assign({}, editableParcel, {
            selected_developers: editableParcel.selected_developers.includes(id)
                ? editableParcel.selected_developers.filter(
                      developer => developer !== id,
                  )
                : editableParcel.selected_developers.concat(id),
        });

        return dispatch({
            type: CHANGE_SELECTED_DEVELOPERS,
            payload,
        });
    };
}

export function toggleAcceptsContacts() {
    return (dispatch, getState) => {
        const {
            customers: {
                preferences: {
                    editPreferencesModal: { editableParcel },
                },
            },
        } = getState();

        const payload = Object.assign({}, editableParcel, {
            accepts_contacts: !editableParcel.accepts_contacts,
        });

        return dispatch({
            type: TOGGLE_ACCEPTS_CONTACTS,
            payload,
        });
    };
}

function startUpdateEditedParcel() {
    return {
        type: START_UPDATE_EDITED_PARCEL,
    };
}

function failUpdateEditedParcel(e) {
    window.console.warn(e);

    return {
        type: FAIL_UPDATE_EDITED_PARCEL,
        payload: e,
    };
}

function completeUpdateEditedParcel(editedParcel) {
    return (dispatch, getState) => {
        const {
            customers: {
                preferences: {
                    parcels: { data: parcelData },
                },
            },
            geocoder: { selectedResult },
        } = getState();

        const updatedParcelIndex = parcelData.findIndex(
            ({ parcel_id: parcelId }) => parcelId === editedParcel.parcel_id,
        );

        const payload = [
            ...parcelData.slice(0, updatedParcelIndex),
            editedParcel,
            ...parcelData.slice(updatedParcelIndex + 1),
        ];

        if (selectedResult) {
            dispatch(clearGeocoderSearch());
            dispatch(toggleGeocoder(uiSections.PreferencesForm));
        }

        return dispatch({
            type: COMPLETE_UPDATE_EDITED_PARCEL,
            payload,
        });
    };
}

export function updateEditedParcel() {
    return (dispatch, getState) => {
        dispatch(startUpdateEditedParcel());
        const {
            customers: {
                preferences: {
                    uuid,
                    editPreferencesModal: { editableParcel },
                },
            },
        } = getState();

        return csrfRequest
            .put(makePreferencesUrl(uuid), editableParcel)
            .then(({ data }) => dispatch(completeUpdateEditedParcel(data)))
            .catch(e => dispatch(failUpdateEditedParcel(e)));
    };
}

export function hideSuccessToast() {
    return {
        type: HIDE_SUCCESS_TOAST,
    };
}
