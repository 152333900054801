import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import Modal from 'react-modal';

import '../../../img/favicon.ico';
import '../../../node_modules/leaflet/dist/leaflet.css';
import '../../../sass/marketing/main.scss';

import createStoreWithMiddleware from '../common/store';
import reducers from './reducers';
import {
    BASE_URL,
    CUSTOMER_BASE_URL,
    DASHBOARD_BASE_URL,
    VENDOR_BASE_URL,
    VENDOR_SETTINGS_URL,
} from './constants';

import NotFound from './components/NotFound';

const MarketingApp = lazy(() => import('../marketing/MarketingApp'));
const PreferencesPage = lazy(() =>
    import('../marketing/components/parcel_preferences/index'),
);
const DeveloperApp = lazy(() => import('./DeveloperApp'));
const EmailConfirmation = lazy(() => import('./EmailConfirmation'));
const ResetPassword = lazy(() => import('./components/resetpassword/index'));

const OnboardingContainer = lazy(() =>
    import('./components/onboarding/OnboardingContainer'),
);
const Welcome = lazy(() => import('./components/onboarding/Welcome'));
const DeveloperProfile = lazy(() =>
    import('./components/onboarding/DeveloperProfile'),
);
const StayTuned = lazy(() => import('./components/onboarding/StayTuned'));
const Denied = lazy(() => import('./components/onboarding/Denied'));
const Deactivated = lazy(() => import('./components/onboarding/Deactivated'));

const AdminDashboard = lazy(() => import('./components/admindashboard/index'));

const store = createStoreWithMiddleware(reducers);

Modal.setAppElement('body');

const makeLoadableComponent = LazilyLoadedComponent => props =>
    (
        <Suspense fallback={null}>
            <LazilyLoadedComponent {...props} />
        </Suspense>
    );

const LoadableMarketingApp = makeLoadableComponent(MarketingApp);
const LoadablePreferencesPage = makeLoadableComponent(PreferencesPage);
const LoadableDeveloperApp = makeLoadableComponent(DeveloperApp);
const LoadableEmailConfirmation = makeLoadableComponent(EmailConfirmation);
const LoadableOnboardingContainer = makeLoadableComponent(OnboardingContainer);
const LoadableWelcome = makeLoadableComponent(Welcome);
const LoadableStayTuned = makeLoadableComponent(StayTuned);
const LoadableDenied = makeLoadableComponent(Denied);
const LoadableDeactivated = makeLoadableComponent(Deactivated);
const LoadableDeveloperProfile = makeLoadableComponent(DeveloperProfile);
const LoadableAdminDashboard = makeLoadableComponent(AdminDashboard);
const LoadableResetPassword = makeLoadableComponent(ResetPassword);

render(
    <Provider store={store}>
        <BrowserRouter>
            <React.Fragment>
                <Switch>
                    {/* Vendor routes */}
                    <Route
                        path={`${VENDOR_BASE_URL}/property/:parcelId`}
                        component={LoadableDeveloperApp}
                    />
                    <Route
                        path={`${VENDOR_BASE_URL}/email-confirmation/:emailConfirmationKey`}
                        component={LoadableEmailConfirmation}
                    />
                    <Route
                        path={VENDOR_SETTINGS_URL}
                        component={LoadableDeveloperApp}
                    />
                    <Route
                        path={`${VENDOR_BASE_URL}/resetpassword/:uid/:token`}
                        component={LoadableResetPassword}
                    />
                    {/* see further welcome/* routes in the next Switch */}
                    <Route
                        path={`${VENDOR_BASE_URL}/welcome`}
                        component={LoadableOnboardingContainer}
                    />
                    <Route
                        path={VENDOR_BASE_URL}
                        component={LoadableDeveloperApp}
                    />

                    {/* Dashboard route */}
                    <Route
                        path={DASHBOARD_BASE_URL}
                        component={LoadableAdminDashboard}
                    />

                    {/* Customer routes */}
                    <Route
                        path={`${CUSTOMER_BASE_URL}/preferences`}
                        component={LoadablePreferencesPage}
                    />
                    <Route
                        path={CUSTOMER_BASE_URL}
                        component={LoadableMarketingApp}
                    />
                    <Route exact path="/">
                        <Redirect from="/" to={`${VENDOR_BASE_URL}/welcome`} />
                    </Route>
                    <Route exact path={BASE_URL}>
                        <Redirect
                            from={BASE_URL}
                            to={`${VENDOR_BASE_URL}/welcome`}
                        />
                    </Route>
                    <Route path="*" component={NotFound} />
                </Switch>

                {/* Welcome routes */}
                <Switch>
                    <Route
                        path={`${VENDOR_BASE_URL}/welcome/staytuned`}
                        component={LoadableStayTuned}
                    />
                    <Route
                        path={`${VENDOR_BASE_URL}/welcome/denied`}
                        component={LoadableDenied}
                    />
                    <Route
                        path={`${VENDOR_BASE_URL}/welcome/deactivated`}
                        component={LoadableDeactivated}
                    />
                    <Route
                        path={`${VENDOR_BASE_URL}/welcome/profile`}
                        component={LoadableDeveloperProfile}
                    />
                    <Route
                        path={`${VENDOR_BASE_URL}/welcome`}
                        component={LoadableWelcome}
                    />
                </Switch>
            </React.Fragment>
        </BrowserRouter>
    </Provider>,
    document.getElementById('mount'),
);
