import { preferredContactTypes } from './constants';

const eligibleBldgTypes = ['NonRes', 'Condo', 'Apt'];

export function parcelIsEligible({ Parcel }) {
    return (
        Parcel && Parcel.BldgType && eligibleBldgTypes.includes(Parcel.BldgType)
    );
}

export default function selectEligibleParcels(data) {
    return data.filter(parcelIsEligible);
}

export function mapPreferredContact({ email, phone }) {
    if (email && phone) {
        return preferredContactTypes.both;
    }
    if (phone) {
        return preferredContactTypes.telephone;
    }
    if (email) {
        return preferredContactTypes.email;
    }
    // db columns default to False, so false and false should represent both/either
    return preferredContactTypes.both;
}
