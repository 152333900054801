import {
    RESET_LAT_LNG_PARCEL_SEARCH,
    START_LAT_LNG_PARCEL_SEARCH,
    FAIL_LAT_LNG_PARCEL_SEARCH,
    COMPLETE_LAT_LNG_PARCEL_SEARCH,
} from './actions';

const initialState = {
    fetching: false,
    error: false,
    data: null,
    latlng: null,
};

export default function latLngParcelSearchReducer(
    state = initialState,
    { type, payload },
) {
    switch (type) {
        case RESET_LAT_LNG_PARCEL_SEARCH:
            return initialState;
        case START_LAT_LNG_PARCEL_SEARCH:
            return Object.assign({}, state, {
                fetching: true,
                error: false,
                data: null,
                latlng: payload,
            });
        case FAIL_LAT_LNG_PARCEL_SEARCH:
            return Object.assign({}, state, {
                fetching: false,
                error: true,
            });
        case COMPLETE_LAT_LNG_PARCEL_SEARCH:
            return Object.assign({}, state, {
                fetching: false,
                error: false,
                data: payload,
            });
        default:
            return state;
    }
}
