import { HIDE_ERROR_MODAL } from '../../actions';

import {
    CHANGE_AIS_GEOCODER_SEARCH_QUERY,
    START_AIS_GEOCODER_SEARCH,
    FAIL_AIS_GEOCODER_SEARCH,
    COMPLETE_AIS_GEOCODER_SEARCH,
    UPDATE_AIS_GEOCODER_FIT_BOUNDS,
    COMPLETE_FETCH_PARCEL_DATA,
    REFETCH_ON_EMPTY_QUERY,
    CLEAR_AIS_GEOCODER_DATA,
} from './actions';

const initialState = {
    bounds: null,
    boundsPending: false,
    parcelsPending: false,
    data: null,
    fetching: false,
    error: false,
    query: '',
    userInitiated: false,
};

export default function aisGeocoder(state = initialState, { type, payload }) {
    switch (type) {
        case HIDE_ERROR_MODAL:
            return Object.assign({}, state, {
                error: false,
            });
        case CHANGE_AIS_GEOCODER_SEARCH_QUERY:
            return Object.assign({}, state, {
                query: payload,
            });
        case START_AIS_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                boundsPending: true,
                parcelsPending: true,
                data: null,
                fetching: true,
                error: false,
                userInitiated: true,
            });
        case FAIL_AIS_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                bounds: initialState.bounds,
                boundsPending: false,
                parcelsPending: false,
                fetching: false,
                error: true,
                data: initialState.data,
            });
        case COMPLETE_AIS_GEOCODER_SEARCH:
            return Object.assign({}, state, {
                fetching: false,
                data: payload,
            });
        case UPDATE_AIS_GEOCODER_FIT_BOUNDS:
            return Object.assign({}, state, {
                boundsPending: state.boundsPending && state.parcelsPending,
                bounds:
                    state.boundsPending || state.parcelsPending
                        ? payload
                        : state.bounds,
            });
        case COMPLETE_FETCH_PARCEL_DATA:
            return Object.assign({}, state, {
                parcelsPending: false,
            });
        case REFETCH_ON_EMPTY_QUERY:
            return Object.assign({}, state, {
                userInitiated: false,
            });
        case CLEAR_AIS_GEOCODER_DATA:
            return Object.assign({}, initialState, {
                userInitiated: state.userInitiated,
            });
        default:
            return state;
    }
}
